import { createSelector } from "reselect";

const selectPackageReducer = (state) => state.packages;

export const selectPackages = createSelector(
    [selectPackageReducer],
    (packageSlice) => {
        return packageSlice.packages;
    }
)

export const selectPackagesIsLoading = createSelector(
    [selectPackageReducer],
    (packageSlice) => packageSlice.isLoading
)