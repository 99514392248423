import {
  collection,
  addDoc,
  doc,
  query,
  getDocs,
  getDoc,
  where,
  updateDoc,
  orderBy,
} from "firebase/firestore";
import { db } from "../config/firebase.config";
import { removeUndefinedNull } from "../utils/helper.utils";

export const createDonationPurposeService = async (docData) => {
  docData = removeUndefinedNull(docData);
  const res = await addDoc(collection(db, "DonationPurposes"), docData);
  if (res.id) {
    return {
      id: res.id,
      ...docData,
    };
  }
};

export const updateDonationPurposeService = async (docData, Id) => {
  docData = removeUndefinedNull(docData);
  const { id, ...rest } = docData; 
  const updatedDoc = { ...rest, Updated_at: new Date() };
  await updateDoc(doc(db, "DonationPurposes", Id), updatedDoc);

  return await {
    ...updatedDoc,
    id: Id,
  };
};

export const deleteDonationPurposeService = async (Id) => {
  await updateDoc(doc(db, "DonationPurposes", Id), {
    Status: 0,
    Updated_at: new Date(),
  });
};
export const getAllDonationPurposes = async () => {
  const donationPurposesRef = collection(db, "DonationPurposes");
  const q = query(
    donationPurposesRef,
    where("Status", "==", 1),
    orderBy("Created_at", "desc")
  );
  const querySnapshot = await getDocs(q);

  return querySnapshot.docs.map((docSnapshot) => ({
    id: docSnapshot.id,
    ...docSnapshot.data(),
  }));
};

export const getDonationPurposeUsers = async (donationPurposeId) => {
  const donationRef = collection(db, "Donations");
  const q = query(
    donationRef,
    where("donatePurposeId", "==", donationPurposeId)
  );
  const querySnapshot = await getDocs(q);

  return await Promise.all(
    querySnapshot.docs.map(async (docSnapshot) => {
      console.log("Doc Snapshot", docSnapshot.data());
      const user = await getDoc(doc(db, "Users", docSnapshot.data().userId));
      const { email, name, id, profile } = user.data();
      return {
        id: docSnapshot.id,
        email,
        profile,
        name,
        userId: id,
        ...docSnapshot.data(),
      };
    })
  );
};
