import { CONCERTS_ACTION_TYPES } from "./concerts.types";

const CONCERTS_INITIAL_STATE = {
  concerts: [],
  tableGrades: [],
  lastConcertsDoc: null,
};

export const concertsReducer = (state = CONCERTS_INITIAL_STATE, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case CONCERTS_ACTION_TYPES.FETCH_CONCERTS_START:
      return { ...state, isLoading: true }
    case CONCERTS_ACTION_TYPES.FETCH_CONCERTS_SUCCESS:
      return { ...state, concerts: payload, isLoading: false }
    case CONCERTS_ACTION_TYPES.FETCH_TABLE_GRADE_SUCCESS:
      return { ...state, tableGrades: payload, isLoading: false }
    case CONCERTS_ACTION_TYPES.FETCH_CONCERTS_FAILED:
      return { ...state, error: payload, isLoading: false }
    default:
      return state;
  }
};