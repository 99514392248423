import React, { useEffect } from "react";
import Breadcrumb from "../../layout/breadcrumb";
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Spinner,
} from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import "./packages.styles.scss";
import { useState } from "react";
import CustomModal from "../custom-modal/custom-modal.component";
import { fetchPackagesAsync } from "../../store/packages/package.action";
import PackageForm from "./package-form/package-form.component";
import {
  selectPackages,
  selectPackagesIsLoading,
} from "../../store/packages/package.selector";
import PackageList from "./package-list/package-list.component";
import CustomBackButton from "../custom-back-button/custom-back-button.component";

const Packages = () => {
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);

  const dispatch = useDispatch();

  const isLoading = useSelector(selectPackagesIsLoading);
  const allPackages = useSelector(selectPackages);

  const [packageObj, setPackageObj] = useState({});
  const setPackageFormData = (packObj) => setPackageObj(packObj);

  useEffect(() => {
    if (allPackages.length === 0) dispatch(fetchPackagesAsync());
  }, []);

  return (
    <>
      <Breadcrumb parent="Dashboard" title="Package" />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card className="main-content-card">
              <CardHeader className="bg-transparent d-flex justify-content-between">
                <h5 className="d-flex gap-1"> <CustomBackButton /> List Of Packages </h5>
                <h5> Total Package: {allPackages && allPackages.length > 0 ? allPackages.length: 0} </h5>
              </CardHeader>
              <CardBody className="main-content-card-body">
                <Row>
                  {isLoading ? (
                    <div className="text-center">
                      <Spinner />
                    </div>
                  ) : (
                    <>
                    
                    <PackageList toggle={toggle} isLoading={isLoading} allPackages={allPackages} setPackageFormData={setPackageFormData}/> 
                      <CustomModal
                        toggle={toggle}
                        modal={modal}
                        modalTitle={"Save Package"}
                      >
                        <PackageForm toggle={toggle} packageObj={packageObj} />
                      </CustomModal>
                    </>
                  )}
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Packages;
