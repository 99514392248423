import { USER_ACTION_TYPES } from "./user.types";


const USER_INITIAL_STATE = {
  users: []
};

export const userReducer = (state = USER_INITIAL_STATE, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case USER_ACTION_TYPES.FETCH_USERS_START:
      return { ...state, isLoading: true}
    case USER_ACTION_TYPES.FETCH_USERS_SUCCESS:
        return { ...state, users: payload, isLoading: false }
    case USER_ACTION_TYPES.FETCH_USERS_FAILED:
        return { ...state, error: payload, isLoading: false }
    default:
        return state;
  }
};