import {
    collection,
    addDoc,
    doc,
    query,
    getDocs,
    where,
    updateDoc,
    orderBy,
    limit,
    startAfter,
    getCountFromServer
} from "firebase/firestore";
import { db } from "../config/firebase.config";
import { removeUndefinedNull } from "../utils/helper.utils";

export const createCollection = async (docData) => {
    docData = removeUndefinedNull(docData);
    const res = await addDoc(collection(db, "Collections"), {...docData, Title: docData.Title.toLowerCase()});
    if (res.id) {
        return {
            id: res.id,
            ...docData
        }
    }
}

export const updateCollectionService = async (docData, collectionId) => {
    docData = removeUndefinedNull(docData);
    const { id, ...rest } = docData; 
    const updatedDoc = { ...rest, Title: docData.Title.toLowerCase(),  Updated_at: new Date() };
    await updateDoc(doc(db, "Collections", collectionId), updatedDoc)

    return await {
        ...updatedDoc,
        id: collectionId
    }
}

export const deleteCollectionService = async (collectionId) => {
    await updateDoc(doc(db, "Collections", collectionId), { Status: 0, Updated_at: new Date() });
    
}
export const getAllCollections = async (lastDoc = null, searchString = '') => {
    const collectionsRef = collection(db, 'Collections');

    if(lastDoc == null) {
        // Query the first page of docs
          const q = query(collectionsRef,  
            where('Title', '>=', searchString.toLocaleLowerCase()),
            where('Title', '<=', searchString.toLocaleLowerCase() + '\uf8ff'),
            orderBy("Title"),
            orderBy("Created_at", "desc"),
            where("Status", "==", 1),
          limit(10));
          const querySnapshot = await getDocs(q);

          const collections = querySnapshot.docs.map((docSnapshot) => (
            {
                id: docSnapshot.id,
            ...docSnapshot.data()
            }

            ))
            const lastDoc = querySnapshot.docs[querySnapshot.docs.length-1];
            return { collections, lastDoc }

    } else {

        // Get the last visible document
        const nextQuery = query(collectionsRef, 
            where("Status", "==", 1),
            orderBy("Title"),
            orderBy("Created_at", "desc"),
        startAfter(lastDoc),
        limit(10));
        const querySnapshot2 = await getDocs(nextQuery);

        const collections = querySnapshot2.docs.map((docSnapshot) => (
        {
            id: docSnapshot.id,
        ...docSnapshot.data()
        }

        ))
        const lastDoc1 = querySnapshot2.docs[querySnapshot2.docs.length-1];
        return { collections, lastDoc: lastDoc1 }
    }
   
}

export const getTotalCollections = async () => {
    const collectionsRef = query(collection(db, 'Collections'), where("Status", "==", 1));
    const snapCollection = await getCountFromServer(collectionsRef);
    return snapCollection.data().count;
}