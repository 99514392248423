import { createSelector } from "reselect";

const selectReservationReducer = (state) => {
    return state.reservations;
};

export const selectConcertReservationsMap = createSelector(
    [selectReservationReducer],
    (reservationSlice) => reservationSlice.reservations
)

export const selectConcertReservationIsLoading = createSelector(
    [selectReservationReducer],
    (concertReservationsSlice) => concertReservationsSlice.isLoading
)