import React, { useEffect } from "react";
import Breadcrumb from "../../layout/breadcrumb";
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Spinner,
} from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import "./donation-purpose.styles.scss";
import AddCollectionAlbumBTN from "../add-collection-album-btn/add-collection-album-btn.component";
import { useState } from "react";
import CustomModal from "../custom-modal/custom-modal.component";

import { selectCurrentAdmin } from "../../store/admin/admin.selector";
import CustomBackButton from "../custom-back-button/custom-back-button.component";
import CustomSearchBar from "../custom-search-bar/custom-search-bar.component";
import { selectDonationPurposes, selectDonationPurposesIsLoading } from "../../store/donation-purposes/donation-purpose.selector";
import { fetchDonationPurposesAsync } from "../../store/donation-purposes/donation-purpose.action";
import DonationPurposeForm from "./donation-purpose-form/donation-purpose-form.component";
import DonationPurposeCard from "./donation-purpose-card/donation-purpose-card.component";

const DonationPurpose = () => {
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);

  const dispatch = useDispatch();

  const isLoading = useSelector(selectDonationPurposesIsLoading);
  const allDonationPurposes = useSelector(selectDonationPurposes);

  const [donationPurposeObj, setDonationPurposeObj] = useState({});
  const setDonationPurposeFormData = (donationPurposeObj) => setDonationPurposeObj(donationPurposeObj);

  const currentAdmin = useSelector(selectCurrentAdmin);

  const [query, setQuery] = useState("")

  useEffect(() => {
    if (allDonationPurposes.length === 0) dispatch(fetchDonationPurposesAsync());
  }, []);

  return (
    <>
      <Breadcrumb parent="Dashboard" title="Donation Purposes" />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card className="main-content-card">
              <CardHeader className="bg-transparent  d-flex justify-content-between">
                <h5 className="d-flex gap-1"> <CustomBackButton /> List Of Donation Purposes </h5>
                <h5 >Total Donation Purposes: {allDonationPurposes && allDonationPurposes.length > 0 ? allDonationPurposes.length : 0} </h5>
              </CardHeader>
              <CardBody className="main-content-card-body">
                <Row>
                  {isLoading ? (
                    <div className="text-center">
                      <Spinner />
                    </div>
                  ) : (
                    <>
                      <CustomSearchBar placeholder="Search your donation purpose name here..." setQuery={setQuery} />
                      {(currentAdmin.Role === "Editor" ||
                        currentAdmin.Role === "Admin") && (
                        <AddCollectionAlbumBTN
                          title={"Add Donation Purpose"}
                          toggle={() => {
                            setDonationPurposeFormData({});
                            toggle();
                          }}
                          className={
                            allDonationPurposes.length === 0
                              ? "empty-list main-list-hover"
                              : "main-list-hover"
                          }
                          setDonationPurposeFormData={setDonationPurposeFormData}
                        />
                      )}

                      
                      <CustomModal
                        toggle={toggle}
                        modal={modal}
                        modalTitle={"Save Donation Purposes"}
                      >
                        <DonationPurposeForm toggle={toggle} donationPurposeObj={donationPurposeObj} />
                      </CustomModal>

                      {
                      allDonationPurposes?.filter(donationPurpose => {
                      if (query === '') {
                        return donationPurpose;
                      } else if (donationPurpose.Title.toLowerCase().includes(query.toLowerCase())) {
                        return donationPurpose;
                      }
                    }).map((donationPurpose) => (
                        <DonationPurposeCard
                          key={donationPurpose.id}
                          donationPurpose={donationPurpose}
                          setDonationPurposeFormData={setDonationPurposeFormData}
                          toggle={toggle}
                        />
                      ))}
                    </>
                  )}
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default DonationPurpose;
