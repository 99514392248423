import { createSelector } from "reselect";

const selectTagReducer = (state) => state.tags;

export const selectTags = createSelector(
    [selectTagReducer],
    (tagSlice) => {
        return tagSlice.tags;
    }
)

export const selectTagsIsLoading = createSelector(
    [selectTagReducer],
    (tagsSlice) => tagsSlice.isLoading
)