import { COLLECTION_ACTION_TYPES } from "./collection.types";

const COLLECTION_INITIAL_STATE = {
  collections: [],
  lastCollectionDoc: null,
};

export const collectionReducer = (state = COLLECTION_INITIAL_STATE, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case COLLECTION_ACTION_TYPES.FETCH_COLLECTIONS_START:
      return { ...state, isLoading: true}
    case COLLECTION_ACTION_TYPES.FETCH_COLLECTIONS_SUCCESS:
        return { ...state, collections: payload, isLoading: false }
    case COLLECTION_ACTION_TYPES.FETCH_COLLECTIONS_FAILED:
        return { ...state, error: payload, isLoading: false }
    case COLLECTION_ACTION_TYPES.SET_LAST_COLLECTION_DOC:
      return { ...state, lastCollectionDoc: payload, isLoading: false }
    default:
        return state;
  }
};