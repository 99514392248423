import Breadcrumbs from "../../layout/breadcrumb";
import {  Spinner, Container, Row, Col } from 'reactstrap';
import "./dashboard.styles.scss";
import { useSelector, useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { selectDashboardIsLoading, selectDashboardObj } from "../../store/dashboard/dashboard.selector";
import { fetchTotalColAlbVidAudAsync } from "../../store/dashboard/dashboard.action";

import { Chart as ChartJS, RadialLinearScale, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import { Pie } from 'react-chartjs-2';
import { PolarArea } from 'react-chartjs-2';
import { Layers, Music, Package, Video, Award, Grid } from "react-feather";


ChartJS.register(RadialLinearScale, ArcElement, Tooltip, Legend);


const Dashboard = () => {
    const dispatch = useDispatch();
    const dashboardObj = useSelector(selectDashboardObj);
    const isLoading = useSelector(selectDashboardIsLoading)
    useEffect(() => {
        dispatch(fetchTotalColAlbVidAudAsync());
    }, []);

    const polarData = {
        labels: ['Subscription', 'Donation'],
        datasets: [
            {
                label: 'Total',
                data: [dashboardObj.totalSubscription, dashboardObj.totalDonation],
                backgroundColor: [
                    'rgba(75, 192, 192, 0.5)',
                    'rgba(255, 159, 64, 0.5)',
                ],
                borderWidth: 1,
            },
        ],
    };

    const pieData = {
        labels: ['Collection', 'Album', 'Products'],
        datasets: [
            {
                label: 'Total',
                data: [dashboardObj.totalCollection, dashboardObj.totalAlbum, (dashboardObj.totalVideo + dashboardObj.totalAudio)],
                backgroundColor: [
                    'rgba(255, 99, 132, 0.2)',
                    'rgba(54, 162, 235, 0.2)',
                    'rgba(255, 206, 86, 0.2)',
                ],
                borderColor: [
                    'rgba(255, 99, 132, 1)',
                    'rgba(54, 162, 235, 1)',
                    'rgba(255, 206, 86, 1)',
                ],
                borderWidth: 1,
            },
        ],
    };

    const data = {
        labels: ['Total Video', 'Total Audio'],
        datasets: [
            {
                label: 'Total',
                data: [dashboardObj.totalVideo, dashboardObj.totalAudio],
                backgroundColor: [
                    'rgba(255, 159, 64, 0.2)',
                    'rgba(153, 102, 255, 0.2)',
                ],
                borderColor: [
                    'rgba(255, 159, 64, 1)',
                    'rgba(153, 102, 255, 1)',
                ],
                borderWidth: 1,
            },
        ],
    };
    return (
        <>
            <Breadcrumbs parent="Dashboard" title="Dashboard" />
            <Container fluid={true}>
            <Row>
                <Col sm="2" className="mb-4">
                        <div className="d-flex flex-column total-card primary-color">
                            <div>
                            <Award width={40} height={40} />
                            </div>
                            <div className="total-card-text">
                                <h6>Total Donations</h6>
                                { isLoading ? <Spinner /> : <p>{ dashboardObj.totalDonation }</p>}
                            </div>
                        </div>
                    </Col>
                    <Col sm="2" className="mb-4">
                        <div className="d-flex flex-column total-card primary-color">
                            <div>
                            <Grid width={40} height={40} />
                            </div>
                            <div className="total-card-text">
                                <h6>Total Subscription</h6>
                                { isLoading ? <Spinner /> : <p>{dashboardObj.totalSubscription}</p>}
                            </div>
                        </div>
                    </Col> 
                    <Col sm="2" className="mb-4">
                        <div className="d-flex flex-column total-card primary-color">
                            <div>
                            <Package width={40} height={40} />
                            </div>
                            <div className="total-card-text">
                                <h6>Total Collection</h6>
                                { isLoading ? <Spinner /> : <p>{dashboardObj.totalCollection}</p>}
                            </div>
                        </div>
                    </Col>
                    <Col sm="2" className="mb-4">
                        <div className="d-flex flex-column total-card primary-color">
                            <div>
                            <Layers width={40} height={40} />
                            </div>
                            <div className="total-card-text">
                                <h6>Total Albums</h6>
                                { isLoading ? <Spinner /> : <p>{dashboardObj.totalAlbum}</p>}
                            </div>
                        </div>
                    </Col>
                    <Col sm="2" className="mb-4">
                        <div className="d-flex flex-column total-card primary-color">
                            <div>
                            <Video width={40} height={40} />
                            </div>
                            <div className="total-card-text">
                                <h6>Total Videos</h6>
                                { isLoading ? <Spinner /> : <p>{dashboardObj.totalVideo}</p>}
                            </div>
                        </div>
                    </Col>
                    <Col sm="2" className="mb-4">
                        <div className="d-flex flex-column total-card primary-color">
                            <div>
                            <Music width={40} height={40} />
                            </div>
                            <div className="total-card-text">
                                <h6>Total Audios</h6>
                                { isLoading ? <Spinner /> : <p>{dashboardObj.totalAudio}</p>}
                            </div>
                        </div>
                    </Col> 
                </Row>
                <hr />
                <Row className="p-4">
                    <div style={{ display: "flex" }}>
                        <div style={{ flex: 1 }}>
                            <Doughnut data={data} />
                        </div>
                        <div style={{ flex: 1 }}>
                            <Pie data={pieData} />
                        </div>
                        <div style={{ flex: 1 }}>
                            <PolarArea data={polarData} />
                        </div>
                    </div>
                </Row>
                <hr />
                
            </Container>
        </>
    );
};

export default Dashboard;
