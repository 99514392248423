import { createConcerts, deleteConcertsService, getAllConcerts, getAllTableGrades, searchConcerts, updateConcertsService } from "../../services/concerts-firebase.services";
import { createAction } from "../../utils/reducer.utils";
import { CONCERTS_ACTION_TYPES } from "./concerts.types";

export const fetchConcertsStart = () =>
    createAction(CONCERTS_ACTION_TYPES.FETCH_CONCERTS_START);

export const fetchConcertsSuccess = (concertsArray) =>
    createAction(CONCERTS_ACTION_TYPES.FETCH_CONCERTS_SUCCESS, concertsArray);

export const fetchTableGradeSuccess = (tableGradeArray) =>
    createAction(CONCERTS_ACTION_TYPES.FETCH_TABLE_GRADE_SUCCESS, tableGradeArray);


export const fetchConcertsFailed = (error) =>
    createAction(CONCERTS_ACTION_TYPES.FETCH_CONCERTS_FAILED, error);

export const fetchConcertsAsync = () => async (dispatch) => {
    dispatch(fetchConcertsStart());
    try {
        const result = await getAllConcerts();
        dispatch(fetchConcertsSuccess(result));
    } catch (error) {
        dispatch(fetchConcertsFailed(error))
    }
}

export const fetchTableGrades = () => async (dispatch) => {
    dispatch(fetchConcertsStart());
    try {
        const result = await getAllTableGrades();
        dispatch(fetchTableGradeSuccess(result));
    } catch (error) {
        dispatch(fetchConcertsFailed(error))
    }
}


export const searchConcertsAsync = (title = '') => async (dispatch) => {
    try {
        const result = await searchConcerts(title);
        dispatch(fetchConcertsSuccess(result));
    } catch (error) {
        dispatch(fetchConcertsFailed(error))
    }
};


export const addConcerts = async (concerts, concertsArray) => {
    try {
        const res = await createConcerts(concerts);
        createOrUpdateConcerts(res, concertsArray);
    } catch (error) {
        console.log(error);
    }
}

const createOrUpdateConcerts = async (concerts, concertsArray) => {
    const checkConcertsExists = concertsArray.find(collec => concerts.id === collec.id)
    if (checkConcertsExists) {
        const index = concertsArray.findIndex(collect => collect.id === concerts.id)
        concertsArray[index] = concerts;
    } else {
        return concertsArray.unshift(concerts);
    }
}


// Update Concerts 
export const updateConcerts = async (concerts, concertsArray, collectionId) => {
    try {
        const res = await updateConcertsService(concerts, collectionId);
        createOrUpdateConcerts(res, concertsArray);
    } catch (error) {
        console.log(error);
    }
}

// Delete Concerts
export const deleteConcerts = async (collectionId) => {
    try {
        await deleteConcertsService(collectionId)
    } catch (error) {
        console.log(error);
    }
}