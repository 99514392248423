
import { getSubscribedUsers } from "../../../services/packages-firebase.services";
import { formatTimestap } from "../../../utils/helper.utils";
import CustomDataLoader from "../../custom-data-loader/custom-data-loader.component";
import { useEffect, useState } from 'react';

const SubscribedUsers = ({subscriptionId}) => {

    const [isDataLoading, setIsDataLoading] = useState(true);
    const [subscribedUsers, setSubscribedUsers] = useState([]);
    let totalSubscriptions = 0;
    const dateRegex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{6}$/;

    useEffect(() => {
        const getUsersSubscribed = async (subscriptionId) => {
            await getSubscribedUsers(subscriptionId).then((data)=> {
                setSubscribedUsers(data);
                setIsDataLoading(false)
            }, (err) => {
                setIsDataLoading(false)
            })
        }

        getUsersSubscribed(subscriptionId)
    }, []);

    const formatDate = (date) => {
        // Using Intl.DateTimeFormat()
        const formatter = new Intl.DateTimeFormat('en-US', {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            second: 'numeric'
        });
        const formattedDate = formatter.format(new Date(date));

        return formattedDate;
    }
    return(
        isDataLoading ? <CustomDataLoader /> : <table className="table align-baseline">
            <thead>
                <tr>
                    <th>#</th>
                    <th>Name</th>
                    <th>Email</th>
                    <th>Price</th>
                    <th>Expiration Date</th>
                </tr>
            </thead>
            <tbody>
                {
                    subscribedUsers.length > 0 ? (
                        subscribedUsers.map((subscription, index) => {
                            totalSubscriptions += parseFloat(subscription.price);
                            return (
                                <tr key={index}>
                                    <td>{index + 1}</td>
                                    <td>{subscription.name}</td>
                                    <td>{subscription.email}</td>
                                    <td>{subscription.price}</td>
                                    <td>{
                                    

                                    (dateRegex.test(subscription.expiredAt)) ? 
                                        formatDate(subscription.expiredAt) :
                                        formatTimestap(subscription.expiredAt, true)

                                    }</td>
                                </tr>
                            )
                        }) 
                        
                    ): <tr className="text-center mt-2">
                            <td colSpan={5}>
                            <p className="primary-color">Doesn't have subscriptions yet!!</p>
                            </td>
                        </tr>
                }

                {
                   subscribedUsers.length > 0 && (<tr >
                        <td colSpan={3}>
                        <p className="primary-color"><strong>Total Subscriptions</strong></p>
                        </td>
                        <td>
                            <p className="primary-color">{totalSubscriptions}</p>
                        </td>
                    </tr>
                    )
                }
                
            </tbody>
        </table>
    )
}

export default SubscribedUsers;