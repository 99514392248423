import './collection-card.styles.scss';

import photoPlaceholder from '../../../assets/images/lightgallry/01.jpg';
import { Link } from 'react-router-dom';
import { ShoppingBag } from 'react-feather';
import { FIREBASE_STORAGE_BASE_URL } from '../../../constant/constant';
import { useSelector } from 'react-redux';
import { selectCurrentAdmin } from '../../../store/admin/admin.selector';

const CollectionCard =({collection, setCollectionFormData, toggle}) => {
    const { Title, Description, Photo, id } = collection;
    const currentAdmin = useSelector(selectCurrentAdmin);

    const handleOpenCollectionFormModal = () => {
        if((currentAdmin.Role === 'Editor' || currentAdmin.Role === 'Admin')) {
            setCollectionFormData(collection);
            toggle()

        }    
    }
    
    return (
        <>
            <div className='col-3 mb-3'>
                <div className="collection-content h-100 position-relative main-list-hover" onClick={handleOpenCollectionFormModal}>
                    <div className="collection-box">
                        <div className="collection-img">
                            <img src={Photo ?  
                                (
                                    Photo.includes(FIREBASE_STORAGE_BASE_URL) ? Photo : FIREBASE_STORAGE_BASE_URL + Photo
                                ) 
                                 : photoPlaceholder} alt={Title} crossOrigin='anonymous'/>
                        </div>
                        <span className="collection-title mt-10">{Title}</span>
                        <p className="collection-description"><strong>Description:</strong> {Description}</p>
                    </div>
                    <div className="link-container">
                        <Link to={`/collections/${id}/${Title.replace(new RegExp("[;\\\\/:*?$#\"<>|&']"), ' ')}/albums`} onClick={(e) => e.stopPropagation()}><ShoppingBag /> Albums</Link>
                    </div>
                </div>
            </div>
        </>
    )
}

export default CollectionCard;