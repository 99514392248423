import "./seat-row.styles.scss";
import { useSelector } from "react-redux";

import { PenTool } from "react-feather";

import { selectCurrentAdmin } from "../../../store/admin/admin.selector";

const SeatRow = (props) => {
  const { seat, toggle, setSeatFormData } = props;

  const currentAdmin = useSelector(selectCurrentAdmin);

  const handleOpenEditForm = () => {
    if (currentAdmin.Role === "Editor" || currentAdmin.Role === "Admin") {
      setSeatFormData(seat);
      toggle();
    }
  };

  return (
    <>
      <div className="product-list-item position-relative row align-items-center main-list-hover">
        <div className="col-md-8 overflow-hidden" onClick={handleOpenEditForm}>
          <span className="product-list-table-head-text">
            {seat?.Seat_Number || 0}
          </span>
        </div>
        <div className="col-md-2 overflow-hidden" onClick={handleOpenEditForm}>
          <span className="product-list-table-head-text">
            {seat?.Status == 0 ? "Deactive" : "Active"}
          </span>
        </div>
        <div className="col-md-2 overflow-hidden">
          <div className="d-flex gap-2">
            <div className="product-list-edit">
              <button title="Edit" onClick={handleOpenEditForm}>
                <PenTool />
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SeatRow;
