import React, { Fragment, useState, useEffect, useLayoutEffect } from "react";
import man from "../../assets/images/dashboard/profile.jpg";
import { LogIn, Sidebar } from "react-feather";
import { Row } from "reactstrap";
import { NavLink, useNavigate } from "react-router-dom";
import { signOutUser } from "../../services/auth-firebase.services";
import { selectCurrentAdmin } from "../../store/admin/admin.selector";
import { useDispatch, useSelector } from "react-redux";
import { setCurrentAdmin } from "../../store/admin/admin.action";
import { FIREBASE_STORAGE_BASE_URL } from "../../constant/constant";
import photoPlaceholder from "../../assets/images/lightgallry/01.jpg";

const Header = (props) => {
  const [moonlight, setMoonlight] = useState(false);
  const [sidebartoggle, setSidebartoggle] = useState(true);
  const navigate = useNavigate();
  const currentAdmin = useSelector(selectCurrentAdmin);
  const dispatch = useDispatch();

  const width = useWindowSize();

  function useWindowSize() {
    const [size, setSize] = useState([0, 0]);
    useLayoutEffect(() => {
      function updateSize() {
        setSize(window.innerWidth);
      }
      window.addEventListener("resize", updateSize);
      updateSize();
      return () => window.removeEventListener("resize", updateSize);
    }, []);
    return size;
  }

  useEffect(() => {
    var ignoreClick_On_Out_side_Element =
      document.getElementById("out_side_click");
    var ignoreClick_On_Main_Nav_Element =
      document.getElementById("sidebar-menu");
    document.addEventListener("click", function (event) {
      var isClickOutSideElement = ignoreClick_On_Out_side_Element.contains(
        event.target
      );
      var isClickMainNavElement = ignoreClick_On_Main_Nav_Element.contains(
        event.target
      );
      if (
        window.innerWidth <= 991 &&
        !isClickOutSideElement &&
        !isClickMainNavElement
      ) {
        //Do something click is outside specified element
        document.querySelector(".page-header").className =
          "page-header close_icon";
        document.querySelector(".sidebar-wrapper").className =
          "sidebar-wrapper close_icon ";
      }
    });
    if (window.innerWidth <= 991) {
      //Do something click is outside specified element
      document.querySelector(".page-header").className =
        "page-header close_icon";
      document.querySelector(".sidebar-wrapper").className =
        "sidebar-wrapper close_icon ";
      setSidebartoggle(true);
    } else {
      document.querySelector(".page-header").className = "page-header ";
      document.querySelector(".sidebar-wrapper").className = "sidebar-wrapper ";
      setSidebartoggle(false);
    }
  }, [width]);

  useEffect(() => {
    if (localStorage.getItem("layout_version") === "dark-only") {
      setMoonlight(true);
    }
  }, []);

  const MoonlightToggle = (light) => {
    if (light) {
      setMoonlight(!light);
      document.body.className = "light";
      localStorage.setItem("layout_version", "light");
    } else {
      setMoonlight(!light);
      document.body.className = "dark-only";
      localStorage.setItem("layout_version", "dark-only");
    }
  };

  const responsive_openCloseSidebar = (toggle) => {
    if (width <= 991) {
      document.querySelector(".page-header").className = "page-header";
      document.querySelector(".sidebar-wrapper").className = "sidebar-wrapper ";
    } else {
      if (toggle) {
        setSidebartoggle(!toggle);
        document.querySelector(".page-header").className =
          "page-header close_icon";
        document.querySelector(".sidebar-wrapper").className =
          "sidebar-wrapper close_icon ";
        document
          .querySelector(".mega-menu-container")
          .classList.remove("d-block");
      } else {
        setSidebartoggle(!toggle);
        document.querySelector(".page-header").className = "page-header";
        document.querySelector(".sidebar-wrapper").className =
          "sidebar-wrapper ";
      }
    }
  };

  const signOutHandler = async () => {
    const res = await signOutUser();
    // if (await signOutUser()) {
    //   // redirect to login page
    dispatch(setCurrentAdmin({}));
    navigate("/authentication/login", { replace: true });
    // }
  };

  return (
    <Fragment>
      <div className="page-header">
        <Row className="header-wrapper m-0">
          <div className="header-logo-wrapper" id="out_side_click">
            <div
              className="toggle-sidebar"
              onClick={() => responsive_openCloseSidebar(sidebartoggle)}
              style={
                window.innerWidth <= 991
                  ? { display: "block" }
                  : { display: "none" }
              }
            >
              <Sidebar
                className="status_toggle middle sidebar-toggle"
                id="sidebar-toggle"
              />
            </div>
          </div>
          <div className="nav-right col-12 pull-right right-header p-0">
            <ul className="nav-menus">
              <li>
                <div
                  className="mode"
                  onClick={() => MoonlightToggle(moonlight)}
                >
                  <i
                    className={`fa ${
                      moonlight ? "fa-lightbulb-o" : "fa-moon-o"
                    }`}
                  ></i>
                </div>
              </li>
              <li className="profile-nav onhover-dropdown p-0">
                <div className="media profile-media d-flex">
                  <img
                    src={
                      currentAdmin?.photoURL
                        ? currentAdmin?.photoURL.includes(
                            FIREBASE_STORAGE_BASE_URL
                          )
                          ? currentAdmin?.photoURL
                          : FIREBASE_STORAGE_BASE_URL + currentAdmin?.photoURL
                        : photoPlaceholder
                    }
                    alt={"user-image"}
                    crossOrigin="anonymous"
                  />
                  <div className="media-body">
                    <span>{currentAdmin?.Name}</span>
                    <p className="mb-0 font-roboto">
                      {currentAdmin?.Role}{" "}
                      <i className="middle fa fa-angle-down"></i>
                    </p>
                  </div>
                </div>
                <ul className="profile-dropdown onhover-show-div">
                  <li>
                    <NavLink as="span" onClick={signOutHandler}>
                      <LogIn />
                      <span>LogOut</span>
                    </NavLink>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </Row>
      </div>
    </Fragment>
  );
};
export default Header;
