import {
    collection,
    addDoc,
    doc,
    query,
    getDocs,
    where,
    updateDoc,
    orderBy
} from "firebase/firestore";
import { db } from "../config/firebase.config";
import { removeUndefinedNull } from "../utils/helper.utils";

export const createSeat = async (docData) => {
    docData = removeUndefinedNull(docData);
    const res = await addDoc(collection(db, "Seats"), docData);
    if (res.id) {
        return {
            id: res.id,
            ...docData
        }
    }
}

export const updateSeatService = async (docData, seatId) => {
    docData = removeUndefinedNull(docData);
    const { id, ...rest } = docData; 
    const updatedDoc = { ...rest, Updated_at: new Date() };
    await updateDoc(doc(db, "Seats", seatId), updatedDoc)

    return await {
        ...updatedDoc,
        id: seatId
    }
}

export const deleteSeatService = async (seatId) => {
    await updateDoc(doc(db, "Seats", seatId), { Status: 0, Updated_at: new Date() });
    
}
export const getAllSeats = async (tableGradeId) => {
    const seatRef = collection(db, 'Seats');
    const q = query(seatRef, where('Grade_ID', '==', tableGradeId), orderBy("Created_at", "desc"));
    const querySnapshot = await getDocs(q);
    console.log("query snapshot", querySnapshot)
    return querySnapshot.docs.map((docSnapshot) =>  ({
        id: docSnapshot.id,
        ...docSnapshot.data()
    }))
   
}

