import { useParams } from "react-router-dom";
import { Card, CardBody,CardHeader, Row, Col, Container } from 'reactstrap';
import Breadcrumbs from "../../layout/breadcrumb";
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CustomBackButton from "../custom-back-button/custom-back-button.component";
import { selectConcertReservationIsLoading, selectConcertReservationsMap } from "../../store/reserve-concert/reserve-concert.selector";
import { fetchConcertReservationsAsync } from "../../store/reserve-concert/reserve-concert.action";
import ReserveConcertList from "./reserve-concert-list/reserve-concert-list.component";
import { selectUsers } from "../../store/users/user.selector";
import { fetchUsersAsync } from "../../store/users/user.action";
import { selectHalls } from "../../store/hall/hall.selector";
import { fetchHallsAsync } from "../../store/hall/hall.action";
import { selectSeatsMap } from "../../store/seat/seat.selector";
import { fetchSeatsAsync } from "../../store/seat/seat.action";

const ReserveConcert = () => {
    const { concert, concertTitle, tableGrade, tableGradeTitle } = useParams();

  const dispatch = useDispatch();

  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);

  const isLoading = useSelector(selectConcertReservationIsLoading);
  const allConcertReservations = useSelector(selectConcertReservationsMap);
  const allUsers = useSelector(selectUsers);
//   const allHalls = useSelector(selectHalls);
//   const allSeats = useSelector(selectSeatsMap);

//   useEffect(() => {
//     if (
//       !allSeats[tableGrade] ||
//       allSeats[tableGrade].length === 0
//     )
//       dispatch(fetchSeatsAsync(tableGrade, allSeats));
//   }, [allSeats, tableGrade]);

//   const allHalls = useSelector(selectHalls);

    const concertTableGradeIds = concert+tableGrade
  useEffect(() => {
    if (
      !allConcertReservations[concertTableGradeIds] ||
      allConcertReservations[concertTableGradeIds].length === 0
    )
      dispatch(fetchConcertReservationsAsync(concert, allConcertReservations, tableGrade));
  }, []);

  useEffect(() => {
    if (
      !allUsers ||
      allUsers.length === 0
    )
      dispatch(fetchUsersAsync());
  }, [allUsers, dispatch]);

//   useEffect(() => {
//     if (
//       !allHalls ||
//       allHalls.length === 0
//     )
//       dispatch(fetchHallsAsync());
//   }, [allHalls, dispatch]);

  
    return (
        <>
        <Breadcrumbs parent={concertTitle + " Grade - " + tableGradeTitle } title="Concerts"  />
            <Container fluid={true}>
                <Row>
                <Col sm="12">
                    <Card className="main-content-card">
                    <CardHeader className="bg-transparent d-flex justify-content-between">
                        <h5 className="d-flex gap-1"> <CustomBackButton /> {concertTitle ? concertTitle + ' > ' : ''} { " ( Grade - "+ tableGradeTitle + " ) "} Reservation </h5>
                        <h5>Total Reservations : { allConcertReservations[concertTableGradeIds] && allConcertReservations[concertTableGradeIds].length > 0 ? allConcertReservations[concertTableGradeIds].length : 0 }</h5>
                    </CardHeader>
                    <CardBody className="main-content-card-body">
                        <Row>
                            <ReserveConcertList toggle={toggle} modal={modal} concertId={concert} gradeId={tableGrade} allReservations={allConcertReservations[concertTableGradeIds]} isLoading={isLoading} />
                        </Row>
                    </CardBody>
                    </Card>
                </Col>
                </Row>
            </Container>
            
        </>
    )
}

export default ReserveConcert;