
import './custom-search-bar.styles.scss';


const CustomSearchBar = ({ setQuery, placeholder, keyUpFunc }) => {
  return (
    <div className="row mt-2 p-2 custom-search-bar-container shadow-sm mb-4">
      <div className="input-group">
        <input
          type="text"
          placeholder={placeholder}
          aria-describedby="button-addon1"
          className="form-control bg-transparent border-0"
          
          onChange={(event) => setQuery(event.target.value)}
          onKeyUp={keyUpFunc}
        />
        <div className="input-group-append">
          <button
            id="button-addon1"
            type="button"
            className="btn btn-link text-primary"
          >
            <i className="fa fa-search"></i>
          </button>
        </div>
      </div>
    </div>
  );
};

export default CustomSearchBar;