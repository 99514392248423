import "./reserve-concert-row.styles.scss";
import { useSelector } from "react-redux";

import { PenTool } from "react-feather";

import "react-h5-audio-player/lib/styles.css";
import { selectCurrentAdmin } from "../../../store/admin/admin.selector";
import { FIREBASE_STORAGE_BASE_URL } from "../../../constant/constant";
import photoPlaceholder from "../../../assets/images/lightgallry/01.jpg";

import ReservationTicketPDF from "../../reservation-ticket-pdf/reservation-ticket-pdf.component";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { selectHalls } from "../../../store/hall/hall.selector";
import { selectUsers } from "../../../store/users/user.selector";
import { selectSeatsMap } from "../../../store/seat/seat.selector";
import { formatTimestap } from "../../../utils/helper.utils";

const ReserveConcertRow = (props) => {
  let { reservation, toggle, setReservationFormData, currentConcert } = props;
  const { tableGradeTitle, tableNumber, tableGrade, concertTitle } = useParams();

  const currentAdmin = useSelector(selectCurrentAdmin);
  // const allHalls = useSelector(selectHalls);
  const allUsers = useSelector(selectUsers);
  const [currentUser, setCurrentUser] = useState(null);

  // const allSeats = useSelector(selectSeatsMap);
  const [currentSeat, setCurrentSeat] = useState(null);
  const [makeDownloadable, setMakeDownloadable] = useState(false);

  useEffect(() => {
    if (allUsers && allUsers.length > 0 && reservation && reservation.User_ID) {
      setCurrentUser(allUsers.find((user) => user.id === reservation.User_ID));
    }
  }, [reservation, allUsers]);

  // useEffect(() => {
  //   if (Object.keys(reservation).length > 0) {
  //     const seatObj = allSeats[tableGrade].find(
  //       (seat) => seat.id === reservation.Seat_ID
  //     );
  //     setCurrentSeat(seatObj);
  //   }
  // }, [reservation, allSeats]);

  const handleOpenEditForm = () => {
    if (currentAdmin.Role === "Editor" || currentAdmin.Role === "Admin") {
      setReservationFormData(reservation);
      toggle();
    }
  };

  // const getHallNameFromHalls = (hallId) => {
  //   const currentHall = allHalls.find((hall) => hall.id === hallId);
  //   if (currentHall && currentHall !== undefined) {
  //     return currentHall.Hall_name;
  //   } else {
  //     return "";
  //   }
  // };

  const dateForPrint = {
    userName: reservation?.Reserve_For || currentUser?.name,
    concertPhoto:
      (currentConcert?.Photo?.includes("https://") ||
      currentConcert?.Photo?.includes("http://"))
        ? currentConcert?.Photo
        : FIREBASE_STORAGE_BASE_URL + currentConcert?.Photo,
    concertTitle:  currentConcert?.Title,
    address: currentConcert?.Description,
    hallName: currentConcert?.Table_Grade[0]?.Hall_Name,
    gradeName: tableGradeTitle,
    tableNumber: tableNumber,
    concertStartDate: currentConcert?.C_S_Datetime && formatTimestap(currentConcert?.C_S_Datetime, true),
    concertEndDate: currentConcert?.C_E_Datetime && formatTimestap(currentConcert?.C_E_Datetime, true),
  };

  return (
    <>
      <div className="product-list-item position-relative row align-items-center main-list-hover">
        <div className="col-md-2 overflow-hidden" onClick={handleOpenEditForm}>
          <div className="d-flex align-items-center gap-2 position-relative">
            <div className="product-list-img flex-shrink-0">
              <img
                src={
                  reservation &&
                  currentUser &&
                  currentUser.profile &&
                  currentUser.profile !== ""
                    ? currentUser.profile.includes(FIREBASE_STORAGE_BASE_URL) ||
                      currentUser.profile.includes("https://") ||
                      currentUser.profile.includes("http://")
                      ? currentUser.profile
                      : FIREBASE_STORAGE_BASE_URL + currentUser.profile
                    : photoPlaceholder
                }
                alt=""
                crossOrigin="anonymous"
              />
            </div>
            <h5 className="product-list-name m-0">{currentUser?.name || ""}</h5>
            <div className="product-list-color"></div>
          </div>
        </div>
        <div className="col-md-2 overflow-hidden" onClick={handleOpenEditForm}>
          <div className="product-list-address">{ reservation?.Reserve_For || currentUser?.name }</div>
        </div>
        <div className="col-md-2 overflow-hidden" onClick={handleOpenEditForm}>
          <div className="product-list-address">{tableGradeTitle || ""}</div>
        </div>
        <div className="col-md-2 overflow-hidden" onClick={handleOpenEditForm}>
          <span className="product-list-table-head-text">
            {tableNumber}
          </span>
        </div>
        <div className="col-md-2 overflow-hidden" onClick={handleOpenEditForm}>
          <span className="product-list-table-head-text">
            {reservation?.Price || 0}
          </span>
        </div>
        <div className="col-md-2 overflow-hidden">
          <div className="d-flex gap-2">
            <div className="product-list-edit d-flex flex-column">
              <button title="Edit" onClick={handleOpenEditForm}>
                <PenTool />
              </button>

              {makeDownloadable &&
                currentConcert &&
                Object.keys(currentConcert).length > 0 &&
                reservation &&
                Object.keys(reservation).length > 0 && (
                  <PDFDownloadLink
                    document={
                      <ReservationTicketPDF dateForPrint={dateForPrint} />
                    }
                    fileName={`${currentUser?.name}_reservation-.pdf`}
                  >
                    {({ blob, url, loading, error }) =>
                      loading ? "Loading document..." : "Download now!"
                    }
                  </PDFDownloadLink>
                )}

              {!makeDownloadable && (
                <button
                  title="Enable Reservation Download Link"
                  onClick={() => setMakeDownloadable(true)}
                >
                  Enable Download Link
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ReserveConcertRow;
