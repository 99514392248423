import { createSelector } from "reselect";

const selectDashboardReducer = (state) => state.dashboard;

export const selectDashboardObj = createSelector(
    [selectDashboardReducer],
    (dashboardSlice) => {
        return dashboardSlice.dashboard;
    }
)

export const selectDashboardIsLoading = createSelector(
    [selectDashboardReducer],
    (dashboardSlice) => dashboardSlice.isLoading
)