import photoPlaceholder from "../../assets/images/lightgallry/01.jpg";
import {
  Award,
  BookOpen,
  Eye,
  Headphones,
  List,
  Monitor,
  PlayCircle,
  Rss,
  Smartphone,
} from "react-feather";
import { Spinner } from "reactstrap";
import "./users.styles.scss";
import CustomModal from "../custom-modal/custom-modal.component";
import { useEffect, useState } from "react";
import UserLibraries from "./user-libraries/user-libraries.component";
import UserDevices from "./user-devices/user-devices.component";
import UserDonations from "./user-donations/user-donations.component";
import UserRecentlyPlayed from "./user-recently-played/user-recently-played.component";
import UserMostViewed from "./user-most-viewed/user-most-viewed.component";
import UserSubscriptions from "./user-subscriptions/user-subscription.component";
import { getAllUsers, getTotalUserCount } from "../../services/users-firebase.services";
import InfiniteScroll from "react-infinite-scroll-component";
import CustomSearchBar from "../custom-search-bar/custom-search-bar.component";
import UserPlaylist from "./user-play-list/user-play-list.component";

const UsersList = ({ setTotalCountOfUser }) => {
  const [modalTitle, setModalTitle] = useState("");
  const [userId, setUserId] = useState("");
  const [user, setUser] = useState("");
  //   Device Modal state
  const [deviceModal, setDeviceModal] = useState(false);
  const toggleDeviceModal = (user) => {
    setUser(user);
    setUserId(user.id ? user.id : null);
    setModalTitle(user.name ? user.name + "'s Devices" : "User Devices");
    setDeviceModal(!deviceModal);
  };

  //   Library Modal state
  const [libraryModal, setLibraryModal] = useState(false);
  const toggleLibraryModal = (user) => {
    setUserId(user.id ? user.id : null);
    setModalTitle(user.name ? user.name + "'s Libraries" : "User Libraries");
    setLibraryModal(!libraryModal);
  };

  // Donation Modal state
  const [donationModal, setDonationModal] = useState(false);
  const toggleDonationModal = (user) => {
    setUserId(user.id ? user.id : null);
    setModalTitle(user.name ? user.name + "'s Donations" : "User Donations");
    setDonationModal(!donationModal);
  };

  // Recently Played Modal state
  const [recentlyPlayedModal, setRecentlyPlayedModal] = useState(false);
  const toggleRecentlyPlayedModal = (user) => {
    setUserId(user.id ? user.id : null);
    setModalTitle(user.name ? user.name + "'s Recently Played Media" : "User Recently Played Media");

    setRecentlyPlayedModal(!recentlyPlayedModal);
  };

  // Playlist Modal state
  const [playlistModal, setPlaylistModal] = useState(false);
  const togglePlaylistModal = (user) => {
    setUserId(user.id ? user.id : null);
    setModalTitle(user.name ? user.name + "'s Playlist" : "User Playlist");

    setPlaylistModal(!playlistModal);
  };

  // Most Viewed state
  const [mostViewedModal, setMostViewedModal] = useState(false);
  const toggleMostViewedModal = (user) => {
    setUserId(user.id ? user.id : null);
    setModalTitle(user.name ? user.name + "'s Most Viewed Media" : "User Most Viewed Media");
    setMostViewedModal(!mostViewedModal);
  };

  // Subscription modal state
  const [subscriptionModal, setSubscriptionModal] = useState(false);
  const toggleSubscriptionModal = (user) => {
    setUserId(user.id ? user.id : null);
    setModalTitle(user.name ? user.name + "'s Subscriptions" : "User Subscriptions");
    setSubscriptionModal(!subscriptionModal);
  };

  const [users, setUsers] = useState([]);
  const [totalUserCount, setTotalUserCount] = useState(0);
  const [lastUserDoc, setLastUserDoc] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [query, setQuery] = useState("")

  useEffect(() => {
    const allUsers = async () =>
      await getAllUsers().then((result) => {
        setUsers(result.users);
        setLastUserDoc(result.lastDoc)
        setIsLoading(false);
      });

    allUsers();

  }, []);


  useEffect(() => {
    const totalUser = async () => {
      const totalCountUser = await getTotalUserCount();

      setTotalUserCount(totalCountUser);
      setTotalCountOfUser(totalCountUser);
    }

    totalUser();
  }, [])

  const loadMoreUsers = async () => {
    await getAllUsers(lastUserDoc).then((result) => {
      setUsers([...users, ...result.users])
    });
  }

  const searchUser = async () => {
    
    await getAllUsers(null, query).then((result) => {
      setUsers([...result.users])
    });
  }

  return (
    <>
      <div className="table-responsive" >
      <CustomSearchBar placeholder="Search your name here..." setQuery={setQuery} keyUpFunc={searchUser} />
      <InfiniteScroll
              dataLength={users.length}
              next={loadMoreUsers}
              hasMore={users.length > 0 && totalUserCount > users.length && query === ''}
              loader={<div className="primary-color text-center"> loading... </div>}
              scrollableTarget="scrollableDiv"
              endMessage={
                <p className="text-center primary-color">
                  <b>Yay! You have seen it all</b>
                </p>
              }
            >
        <table className="table align-baseline">
          <thead>
            <tr>
              <th>#</th>
              <th>Profile</th>
              <th>Name</th>
              <th>Email</th>
              <th>Devices</th>
              <th>Libraries</th>
              <th>Donation</th>
              <th>Recently Played</th>
              <th>Playlist</th>
              <th>Most Viewed</th>
              <th>Subscription</th>
            </tr>
          </thead>
          <tbody>
            {isLoading ? (
              <tr className="text-center">
                <td colSpan={10}>
                  <Spinner />
                </td>
              </tr>
            ) : 
              
            users.length > 0 ? (
                users.map((user, index) => (
                  <tr key={user.id + "" + Math.floor(Math.random() * 10000) + " " + new Date().getTime() }>
                    <td>{index + 1}</td>
                    <td>
                      <img
                        className="user-profile"
                        src={user.profile ? user.profile : photoPlaceholder}
                        crossOrigin="anonymous"
                      />
                    </td>
                    <td>{user.name}</td>
                    <td>{user.email}</td>
                    <td title="Devices">
                      <div
                        className="modal-action-button"
                        onClick={() => toggleDeviceModal(user)}
                      >
                        <Smartphone />
                        <Monitor />
                      </div>
                    </td>
                    <td title="Libraries">
                      <div
                        className="modal-action-button"
                        onClick={() => toggleLibraryModal(user)}
                      >
                        <BookOpen />
                      </div>
                    </td>
                    <td title="Donation">
                      <div
                        className="modal-action-button"
                        onClick={() => toggleDonationModal(user)}
                      >
                        <Award />
                      </div>
                    </td>
                    <td title="Recently Played">
                      <div
                        className="modal-action-button"
                        onClick={() => toggleRecentlyPlayedModal(user)}
                      >
                        <PlayCircle />
                      </div>
                    </td>
                    <td title="Playlist">
                      <div
                        className="modal-action-button"
                        onClick={() => togglePlaylistModal(user)}
                      >
                        <div className="position-relative">
                        <Headphones />
                        <List />
                        </div>
                      </div>
                    </td>
                    <td title="Most Viewed">
                      <div
                        className="modal-action-button"
                        onClick={() => toggleMostViewedModal(user)}
                      >
                        <Eye />
                      </div>
                    </td>
                    <td title="Subscription">
                      <div
                        className="modal-action-button"
                        onClick={() => toggleSubscriptionModal(user)}
                      >
                        <Rss />
                      </div>
                    </td>
                  </tr>
                ))
                
            ) : (
              <tr className="text-center">
                <td colSpan={10}>
                  <p className="primary">Doesn't have users!</p>
                </td>
              </tr>
            )}
          </tbody>
        </table>
        </InfiniteScroll>
      </div>

      {/* User Device Modal */}
      <CustomModal
        toggle={toggleDeviceModal}
        modal={deviceModal}
        modalTitle={modalTitle}
      >
        <UserDevices userId={userId} user={user}/>
      </CustomModal>

      {/* User Donation Modal */}
      <CustomModal
        toggle={toggleDonationModal}
        modal={donationModal}
        modalTitle={modalTitle}
      >
        <UserDonations userId={userId} />
      </CustomModal>

      {/* User Library Modal */}
      <CustomModal
        toggle={toggleLibraryModal}
        modal={libraryModal}
        modalTitle={modalTitle}
      >
        <UserLibraries userId={userId} />
      </CustomModal>

      {/* User Recently Played Modal */}
      <CustomModal
        toggle={toggleRecentlyPlayedModal}
        modal={recentlyPlayedModal}
        modalTitle={modalTitle}
      >
        <UserRecentlyPlayed userId={userId} />
      </CustomModal>

      {/* User Playlist Modal */}
      <CustomModal
        toggle={togglePlaylistModal}
        modal={playlistModal}
        modalTitle={modalTitle}
      >
        <UserPlaylist userId={userId} />
      </CustomModal>

      {/* User Most Viewed Modal */}
      <CustomModal
        toggle={toggleMostViewedModal}
        modal={mostViewedModal}
        modalTitle={modalTitle}
      >
        <UserMostViewed userId={userId} />
      </CustomModal>

      {/* User Most Viewed Modal */}
      <CustomModal
        toggle={toggleSubscriptionModal}
        modal={subscriptionModal}
        modalTitle={modalTitle}
      >
        <UserSubscriptions userId={userId} />
      </CustomModal>
    </>
  );
};

export default UsersList;
