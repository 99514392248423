import { createFFmpeg, fetchFile } from "@ffmpeg/ffmpeg";
import { firebaseStoreUploadFile } from "../../utils/helper.utils";
import { useEffect, useState } from "react";
import "./video-compression.styles.scss";
import CustomPercentageLoader from "../custom-percentage-loader/custom-percentage-loader.component";
import {
  Button,
  Col,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";
import { toast } from "react-toastify";
import SweetAlert from "sweetalert2";

const VideoCompression = ({ fileSrc, toggle, setProductFile }) => {
  const [videoComprssionPercent720, setVideoComprssionPercent720] = useState(0);
  const [videoComprssionPercent480, setVideoComprssionPercent480] = useState(0);
  const [videoComprssionPercent360, setVideoComprssionPercent360] = useState(0);
  const [fileUploadPercent, setFileUploadPercent] = useState(0);
  const [videoUploadPercent720, setVideoUploadPercent720] = useState(0);
  const [videoUploadPercent480, setVideoUploadPercent480] = useState(0);
  const [videoUploadPercent360, setVideoUploadPercent360] = useState(0);
  const [activeTab, setActiveTab] = useState("0");
  
  const [isConvertingOrUploading, setIsConvertingOrUploading] = useState(false);

  const [uploadedFileArr, setUploadedFileArr] = useState([]);


  const [videoDimension, setVideoDimension] = useState({ width: 0, height: 0 });

  const ffmpeg = createFFmpeg({
    log: true,
    progress: true,
  });

  useEffect(() => {
    if ( fileSrc.type.includes('video') ) {
        var video = document.createElement("video");
    
        video.onloadedmetadata = () => {
          setVideoDimension({ width: video.videoWidth, height: video.videoHeight });
        };
        video.src = URL.createObjectURL(fileSrc);
    }

  }, [])

  const videoCompression = async (
    dimensions = "640x480",
    outputName = "test-480.mp4",
    selectedFile = fileSrc,
    setVideoComprssionPercent,
    setVideoUploadPercent
  ) => {

    SweetAlert.fire({
        title: "Are you sure?",
        text: "If Start Converting, it will be taking a long time and you can't cancel it.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Ok",
        cancelButtonText: "cancel",
        reverseButtons: true,
      }).then(async (result) => {
        if (result.value) {
            setIsConvertingOrUploading(true);
            await ffmpeg.load();
            ffmpeg.setProgress(({ ratio, time, duration }) => {
              setVideoComprssionPercent(
                ratio * 100 > 100 ? (ratio * 100).toFixed(2) : (ratio * 100).toFixed(0)
              );
            });
        
            ffmpeg.FS("writeFile", selectedFile.name, await fetchFile(selectedFile));
        
            await ffmpeg.run("-i", selectedFile.name, "-s", dimensions, outputName);
            const data = ffmpeg.FS("readFile", outputName);
            firebaseStoreUploadFile(
              new Blob([data], { type: "video/mp4" }),
              setVideoUploadPercent,
              "Products",
              setUploadedFileArr,
              uploadedFileArr,
              dimensions,
              setIsConvertingOrUploading
            );
            setIsConvertingOrUploading(false);
        } else {
          SweetAlert.fire("You cancelled the conversion!");
        }
      });

  };

  const handleToggle = () => {
    if ( !isConvertingOrUploading ) {
        if (uploadedFileArr.length > 0) {
            setProductFile(uploadedFileArr)
        }
        toggle();
    } else {
        toast.error("While converting or uploading files, you can't toggle.");
    }
  }

  const handleSaveMedia = () => {
    if ( !isConvertingOrUploading ) {
        if (uploadedFileArr.length > 0) {
            setProductFile(uploadedFileArr)
            toggle();
        } else {
            toast.error("You can't save media without any changes.")
        }
    } else {
        toast.error("While converting or uploading files, you can't save.");
    }
  }

  return (
    <div className="App percentage-card">
      <div>
        <Row>
            <Button color="primary" onClick={ () => {
                const newDimensions = videoDimension.width + "x" + videoDimension.height;
                setIsConvertingOrUploading(true);
                firebaseStoreUploadFile(
                    fileSrc,
                    setFileUploadPercent,
                    "Products",
                    setUploadedFileArr,
                    uploadedFileArr,
                    newDimensions,
                    setIsConvertingOrUploading
                );
            }}

            className="mb-3"
            disabled={ fileUploadPercent > 0 }
            > { fileUploadPercent > 0 && fileUploadPercent < 100 ? "Uploading..." : fileUploadPercent >= 100 ? "Uploaded": "Click to Start Uploading File"} </Button>
          <h5 className="primary-color text-center mb-2">
            Uploading File Progress
          </h5>
          <CustomPercentageLoader percent={fileUploadPercent} />
        </Row>
        {
            fileSrc.type.includes("video") && <div>
                <Nav tabs className="mt-3">
                { videoDimension.height > 720 && <NavItem>
                    <NavLink
                    className={
                        activeTab == "1" ? "primary-color active" : "primary-color"
                    }
                    onClick={() => setActiveTab("1")}
                    >
                    Change to Quality (720)
                    </NavLink>
                </NavItem> }
                { videoDimension.height > 480 && <NavItem>
                    <NavLink
                    className={
                        activeTab == "2" ? "primary-color active" : "primary-color"
                    }
                    onClick={() => setActiveTab("2")}
                    >
                    Change to Quality (480)
                    </NavLink>
                </NavItem> }
                { videoDimension.height > 360 &&  <NavItem>
                    <NavLink
                    className={
                        activeTab == "3" ? "primary-color active" : "primary-color"
                    }
                    onClick={() => setActiveTab("3")}
                    >
                    Change to Quality (360)
                    </NavLink>
                </NavItem> }
                { videoDimension.height > 360 &&<NavItem>
                    <NavLink
                    className={
                        activeTab == "0" ? "primary-color active" : "primary-color"
                    }
                    onClick={() => setActiveTab("0")}
                    >
                    Close Tabs
                    </NavLink>
                </NavItem> }
                </Nav>
                <TabContent activeTab={activeTab} className="primary-color">
                <TabPane tabId="1">
                    <Row className="p-2">
                    <Button
                        color="primary"
                        outline={true}
                        onClick={() =>
                        videoCompression(
                            "1280x720",
                            new Date().getTime() + "-video-720.mp4",
                            fileSrc,
                            setVideoComprssionPercent720,
                            setVideoUploadPercent720
                        )
                        }
                        disabled={videoComprssionPercent720 > 0.000000001}
                    >
                        Click - Convert to video quality 1280x720
                    </Button>
                    <Col className="mt-3">
                        <div className="text-center mb-4">
                        <h5 className="primary-color">Converting Progress</h5>
                        </div>
                        <CustomPercentageLoader percent={videoComprssionPercent720} />
                    </Col>
                    <Col className="mt-3">
                        <div className="text-center mb-4">
                        <h5 className="primary-color">Uploading Progress</h5>
                        </div>
                        <CustomPercentageLoader percent={videoUploadPercent720} />
                    </Col>
                    </Row>
                </TabPane>
                <TabPane tabId="2">
                    <Row className="p-2">
                    <Button
                        color="primary"
                        outline={true}
                        onClick={() =>
                        videoCompression(
                            "640x480",
                            new Date().getTime() + "-video-480.mp4",
                            fileSrc,
                            setVideoComprssionPercent480,
                            setVideoUploadPercent480
                        )
                        }
                        disabled={videoComprssionPercent480 > 0.000000001}
                    >
                        {" "}
                        Click - Convert to video quality 640x480 (480){" "}
                    </Button>
                    <Col className="mt-3">
                        <div className="text-center mb-4">
                        <h5 className="primary-color">Converting Progress</h5>
                        </div>
                        <CustomPercentageLoader percent={videoComprssionPercent480} />
                    </Col>
                    <Col className="mt-3">
                        <div className="text-center mb-4">
                        <h5 className="primary-color">Uploading Progress</h5>
                        </div>
                        <CustomPercentageLoader percent={videoUploadPercent480} />
                    </Col>
                    </Row>
                </TabPane>
                <TabPane tabId="3">
                    <Row className="p-2">
                    <Button
                        color="primary"
                        outline={true}
                        onClick={() =>
                        videoCompression(
                            "480x360",
                            new Date().getTime() + "-video-360.mp4",
                            fileSrc,
                            setVideoComprssionPercent360,
                            setVideoUploadPercent360
                        )
                        }
                        disabled={videoComprssionPercent360 > 0.000000001}
                    >
                        {" "}
                        Click - Convert to video quality 480x360 (360){" "}
                    </Button>
                    <Col className="mt-3">
                        <div className="text-center mb-4">
                        <h5 className="primary-color">Converting Progress</h5>
                        </div>
                        <CustomPercentageLoader percent={videoComprssionPercent360} />
                    </Col>
                    <Col className="mt-3">
                        <div className="text-center mb-4">
                        <h5 className="primary-color">Uploading Progress</h5>
                        </div>
                        <CustomPercentageLoader percent={videoUploadPercent360} />
                    </Col>
                    </Row>
                </TabPane>
                </TabContent>
            </div>
        }

        <div className="d-flex justify-content-between mt-5">
          <Button color="light" onClick={handleToggle}>Close</Button>

          <Button color="primary" className="mr-1" onClick={handleSaveMedia}>
            Save
          </Button>
        </div>
      </div>
    </div>
  );
};

export default VideoCompression;
