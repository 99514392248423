import { PACKAGE_ACTION_TYPES } from "./package.types";

const PACKAGE_INITIAL_STATE = {
  packages: []
};

export const packageReducer = (state = PACKAGE_INITIAL_STATE, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case PACKAGE_ACTION_TYPES.FETCH_PACKAGES_START:
      return { ...state, isLoading: true}
    case PACKAGE_ACTION_TYPES.FETCH_PACKAGES_SUCCESS:
        return { ...state, packages: payload, isLoading: false }
    case PACKAGE_ACTION_TYPES.FETCH_PACKAGES_FAILED:
        return { ...state, error: payload, isLoading: false }
    default:
        return state;
  }
};