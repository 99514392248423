import { useEffect, useState } from "react";
import { ShoppingBag } from "react-feather";
import { Link } from "react-router-dom";
import { getTotalAndReservedSeatOfTableGrade } from "../../services/total-firebase.services";
import { Spinner } from "reactstrap";


const ConcertTableGradeCard = ({ tableGrade, concert, concertTitle }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [reservedSeats, setReservedSeats] = useState(null);
  // tableGrade.id
  useEffect(() => {
      const totalAndReservedSeat = async () => {
        setIsLoading(true);
        await getTotalAndReservedSeatOfTableGrade(concert, tableGrade.id).then((result) => {
          setReservedSeats(result?.reservedSeats)
          setIsLoading(false);
        });
    }

    totalAndReservedSeat();
    setIsLoading(false);
  }, []);

  return (
    <>
      <div className="col-3 mb-3">
        <div className="tag-content h-100 position-relative main-list-hover">
          <div className="tag-box position-relative">
            <div
              className="tag-img d-flex justify-content-center align-items-center"
              style={{ backgroundColor: tableGrade.Color }}
            >
              <div className="text-container-box p-3 mt-2 text-center">
                <span className="tag-title mt-10">
                  {tableGrade.Grade}
                </span>
              </div>
            </div>
          </div>
          <div className="d-flex flex-column gap-2 mt-2 mb-2">
            <div>
              <h5 className="tag-title mt-10">
                Table Number: <span style={{ color: tableGrade.Color }}>{tableGrade.Table_Number}</span>
              </h5>
            </div>
            <div >
              <h5 className="tag-title mt-10">
                Capacity: <span style={{ color: tableGrade.Color }}>{tableGrade.Capacity}</span>
              </h5>
            </div>
            <div tyle={{ color: tableGrade.Color }}>
              {!isLoading && reservedSeats !== null ? <h6 className="tag-title mt-10">
                  Reserved Capacity: <span style={{ color: tableGrade.Color }}>{reservedSeats}</span>
              </h6> : <Spinner /> }
            </div>
          </div>
          <div className="link-container">
            <Link
              to={`/concerts/${concert}/${concertTitle.replace(new RegExp("[;\\\\/:*?$#\"<>|&']"), ' ')}/tb_grades/${tableGrade.id}/${tableGrade.Grade.replace(new RegExp("[;\\\\/:*?$#\"<>|&']"), ' ')}/${tableGrade.Table_Number}`}
              onClick={(event) => event.stopPropagation()}
            >
              <ShoppingBag /> Reservations
            </Link>
          </div>
        </div>
      </div>

    </>
  );
};

export default ConcertTableGradeCard;
