import { useState, useEffect } from "react";
import { Form, Button, Spinner } from "reactstrap";
import FormInput from "../../form-input/form-input.component";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import SweetAlert from "sweetalert2";
import { addTag, deleteTag, updateTag } from "../../../store/tags/tag.action";
import { selectTags } from "../../../store/tags/tag.selector";
import { selectCurrentAdmin } from "../../../store/admin/admin.selector";

const TagForm = ({ toggle, tagObj }) => {
  const [values, setValues] = useState({
    Name: "",
    Image: "",
    Color: "#7366ff",
    Status: 1,
    Created_at: new Date(),
    Updated_at: new Date(),
  });
  const [isUploading, setIsUploading] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [photoUrl, setPhotoUrl] = useState(null);
  const [TagId, setTagId] = useState(null);
  const allTags = useSelector(selectTags);
  const currentAdmin = useSelector(selectCurrentAdmin);

  useEffect(() => {
    if (tagObj && Object.keys(tagObj).length > 0) {
      const { Updated_at, id, ...rest } = tagObj;
      setValues({ ...values, ...rest });
      setTagId(id);
      setPhotoUrl(tagObj.Image ? tagObj.Image : null);
    }
  }, [tagObj]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (isUploading) return;
    setIsSaving(true);
    values.Image = photoUrl ? photoUrl : null;

    TagId
      ? updateTag({ ...values, id:TagId }, allTags, TagId).then(() => {
          toast.success("Your tag has been saved.");
          setIsSaving(false);
          toggle();
        })
      : addTag(values, allTags).then(() => {
          toast.success("Your tag has been saved.");
          setIsSaving(false);
          toggle();
        });
  };

  const onChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  // Handle Delete Tag
  const handleDeleteTag = () => {
    SweetAlert.fire({
      title: "Are you sure?",
      text: "Once deleted, it will be hard to recover it again.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Ok",
      cancelButtonText: "cancel",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        deleteTag(TagId).then(() => {
          SweetAlert.fire("Deleted!", "Your Tag has been deleted.", "success");
          const index = allTags.findIndex(alb => alb.id === TagId);
          allTags.splice(index, 1);
          toggle();
        }, (err) => {
          SweetAlert.fire("Not deleted", "Your Tag has not been deleted.", "error"); 
        })
        
      } else {
        SweetAlert.fire("Your tag is safe!");
      }
      
    });
  };

  return (
    <Form className="form-bookmark needs-validation" onSubmit={handleSubmit}>
      <div className="form-row row mb-3">
        <div className="col-8">
          <div className="mb-3">
            <FormInput
              name="Name"
              type="text"
              placeholder="Tag Name"
              errorMessage="Tag Name should be more than 3 characters!"
              label="Name"
              min={3}
              required
              value={values["Name"]}
              onChange={onChange}
            />
          </div>

          <div className="mb-3">
            <FormInput
              name="Color"
              type="color"
              placeholder="Tag Color"
              label="Color"
              value={values["Color"]}
              onChange={onChange}
            />
          </div>
          
        </div>
        <div className="col-4">
          <FormInput
            name="Image"
            type="file"
            placeholder="Upload an image"
            label="Tag image"
            uploadDirectory="Tags"
            value={values["Image"]}
            setValues={setValues}
            values={values}
            setIsUploading={setIsUploading}
            setPhotoUrl={setPhotoUrl}
            photoUrl={photoUrl}
            onChange={onChange}
          />
        </div>
      </div>
      <div className={TagId && currentAdmin.Role === 'Admin' ? "d-flex justify-content-between" : ""}>
        {(TagId && currentAdmin.Role === 'Admin') && (
          <Button color="secondary" onClick={handleDeleteTag}>
            Delete
          </Button>
        )}
        <div
          className={
            (!TagId ||  currentAdmin.Role !== 'Admin') ? "d-flex justify-content-between" : "d-flex gap-2"
          }
        >
          <Button color="light" onClick={toggle}>
            Cancel
          </Button>

          <Button
            color="primary"
            className="mr-1"
            disabled={isSaving ? true : false}
          >
            {isSaving ? (
              <Spinner style={{ height: "20px", width: "20px" }} />
            ) : (
              "Save"
            )}{" "}
          </Button>
        </div>
      </div>
    </Form>
  );
};

export default TagForm;
