import React from 'react';
import { Document, Page, View, Text, Image, StyleSheet, Svg, Path } from '@react-pdf/renderer';

const styles = StyleSheet.create({
  page: {
    padding: 5,
    backgroundColor: "#1f2937",
    color: "#9ca3af",
    fontSize: "7px",
  },
  header: {
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: 5,
    gap: 10,
  },
  logoContainer: {
    width: 50,
    height: 50,
  },
  logo: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },
  content: {
    marginBottom: 5,
  },
  row: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  rowLabel: {
    flex: 1,
    fontSize: "8px",
    fontWeight: 'bold',
    color: "#fff",
  },
  rowValue: {
    flex: 2,
    textAlign: 'right',
    fontSize: "8px",
  },
  tableHeader: {
    fontWeight: 'bold',
    fontSize: "8px",
    color: "#fff",
  },
  p5: {
    paddingVertical: 3,
    borderBottom: "1px solid gray",
  },
  mb10: {
    marginBottom: 10,
  },
  importantPoints: {
    marginVertical: 5,
    fontSize: "8px",
    fontWeight: 'bold',
    color: "#fff",
  },

  iconContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 5, 
    maxWidth: '80%',
  },
  iconOContainer: {
    width: 12,
    height: 12,
    flexShrink: 0,
    marginRight: 10, 
  },
  icon: {
    width: 10,
    height: 10,
  },
  textWrap: {
    wordBreak: 'break-word'
  }
});

// New component for each row of content
const TableRow = ({ label, value }) => (
  <View>
    <View style={styles.row}>
      <Text style={styles.rowLabel}>{label}</Text>
      <Text style={styles.rowValue}>{value}</Text>
    </View>
  </View>
);

const ReservationTicketPDF = ({ dateForPrint }) => (
  <Document>
    <Page size="A7" style={styles.page}>
      <View style={styles.header}>
        <View style={styles.logoContainer}>
          <Image
            style={styles.logo}
            src={dateForPrint.concertPhoto || require("../../assets/images/logo/logo.png")}
          />
        </View>
      </View>

      <View style={styles.content}>
        <View style={styles.p5}>
          <TableRow label="Name:" value={dateForPrint.userName} />
          <TableRow
            label="Concert:"
            value={dateForPrint.concertTitle || ""}
          />
          <TableRow
            label="Grade:"
            value={
              dateForPrint.gradeName
                ? `${dateForPrint.gradeName} (${dateForPrint.tableNumber || ""})`
                : ""
            }
          />
        </View>

        <View style={styles.p5}>
          <Text style={styles.tableHeader}>Address:</Text>
          <Text>
            {dateForPrint?.address}
          </Text>
        </View>

        <View style={styles.p5}>
          <Text style={styles.tableHeader}>
            Date: {dateForPrint.concertStartDate || ""}
          </Text>
          <Text>
            Doors Open at { dateForPrint?.concertStartDate?.split(' at ')[1] || '' }, closes at { dateForPrint?.concertEndDate?.split(' at ')[1] || '' }
          </Text>
        </View>

        <View>
          <Text style={styles.importantPoints}>Important point to remember: </Text>
          <View style={styles.iconContainer}>
            <View style={styles.iconOContainer}>
              <Image
              style={styles.icon}
              src={require("../../assets/images/icons/check-circle.png")}
            />
            </View>
            <Text style={styles.textWrap}>
              Have the Bakhtar app downloaded on your phone
            </Text>
          </View>
          <View style={styles.iconContainer}>
            <View style={styles.iconOContainer}>
              <Image
              style={styles.icon}
              src={require("../../assets/images/icons/check-circle.png")}
            />
            </View>
            <Text>
              Tickets are non-refundable
            </Text>
          </View>

          <View style={styles.iconContainer}>
            <View style={styles.iconOContainer}>
              <Image
              style={styles.icon}
              src={require("../../assets/images/icons/check-circle.png")}
            />
            </View>
            <Text>
              Children under 13 are not allowed, however 13 
              years or above will need to purchase a ticket to attend the event
            </Text>
          </View>

          <View style={styles.iconContainer}>
            <View style={styles.iconOContainer}>
              <Image
              style={styles.icon}
              src={require("../../assets/images/icons/check-circle.png")}
            />
            </View>
            <Text>
              we will be filming the whole show and the videos will be shared on Bakhtar Media's social platform
            </Text>
          </View>

          <View style={styles.iconContainer}>
            <View style={styles.iconOContainer}>
              <Image
              style={styles.icon}
              src={require("../../assets/images/icons/check-circle.png")}
            />
            </View>
            <Text>
            the Ticket is not transferable, it is strictly prohibited to resel, gift, or transfer this ticket to another individual or entity.
            </Text>
          </View>

          <View style={styles.iconContainer}>
            <View style={styles.iconOContainer}>
              <Image
              style={styles.icon}
              src={require("../../assets/images/icons/check-circle.png")}
            />
            </View>
            <Text>
              Please reach out to our staff if you need further assistance
            </Text>
          </View>


        </View>
        
      </View>
    </Page>
  </Document>
);

export default ReservationTicketPDF;
