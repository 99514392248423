import { ALBUM_ACTION_TYPES } from "./album.types";

const ALBUM_INITIAL_STATE = {
    albums: [],
};

export const albumReducer = (state = ALBUM_INITIAL_STATE, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case ALBUM_ACTION_TYPES.FETCH_ALBUMS_START:
      return { ...state, isLoading: true}
    case ALBUM_ACTION_TYPES.FETCH_ALBUMS_SUCCESS:
        return { ...state, albums: payload, isLoading: false }
    case ALBUM_ACTION_TYPES.FETCH_ALBUMS_FAILED:
        return { ...state, error: payload, isLoading: false }  
    default:
        return state;
  }
};