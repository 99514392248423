import React, { useEffect } from "react";
import Breadcrumb from "../../layout/breadcrumb";
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Spinner,
} from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  selectCollections,
  selectCollectionsIsLoading,
  selectLastColectionDoc,
} from "../../store/collections/collection.selector";
import CollectionCard from "./collection-card/collection-card.component";
import "./collections.styles.scss";
import AddCollectionAlbumBTN from "../add-collection-album-btn/add-collection-album-btn.component";
import { useState } from "react";
import { fetchCollectionsAsync, loadMoreCollectionsDB, searchCollectionsAsync } from "../../store/collections/collection.action";
import CollectionForm from "./collection-form/collection-form.component";
import CustomModal from "../custom-modal/custom-modal.component";
import { selectCurrentAdmin } from "../../store/admin/admin.selector";
import InfiniteScroll from 'react-infinite-scroll-component';
import { getTotalCollections } from "../../services/collections-firebase.services";
import CustomBackButton from "../custom-back-button/custom-back-button.component";
import CustomSearchBar from "../custom-search-bar/custom-search-bar.component";

const Collections = () => {
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);

  const dispatch = useDispatch();

  const isLoading = useSelector(selectCollectionsIsLoading);
  const allCollections = useSelector(selectCollections);

  const [collectionObj, setCollectionObj] = useState({});
  const setCollectionFormData = (collectObj) => setCollectionObj(collectObj);
  const currentAdmin = useSelector(selectCurrentAdmin);
  const lastCollectionDoc = useSelector(selectLastColectionDoc);

  const [ totalCollection, setTotalCollection] = useState(0);
  const [query, setQuery] = useState("")

  useEffect(() => {
    if (allCollections.length === 0) dispatch(fetchCollectionsAsync());

    const getTotalCollectionDB = async () => {
      const total = await getTotalCollections();

      setTotalCollection(total);
    }

    getTotalCollectionDB();
  }, []);

  const loadMoreCollections = () => {
    dispatch(loadMoreCollectionsDB(lastCollectionDoc, allCollections))
  }

  const searchCollection = () => {
    if (query !== '') {
      dispatch(searchCollectionsAsync(query))
    } else {
      dispatch(fetchCollectionsAsync());
    }
  }
  return (
    <>
      <Breadcrumb parent="Dashboard" title="Collections" />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card className="main-content-card">
              <CardHeader className="bg-transparent d-flex justify-content-between">
                <h5 className="d-flex gap-1"> <CustomBackButton /> List Of Collections</h5>
                <h5>Total Collections: { totalCollection }</h5>
              </CardHeader>
              <CardBody className="main-content-card-body" id="scrollableDiv">
                <Row>
                  {isLoading ? (
                    <div className="text-center">
                      <Spinner />
                    </div>
                  ) : (
                    <>
                    <CustomSearchBar placeholder="Search your collection title here..." setQuery={setQuery} keyUpFunc={searchCollection} />
                      {(currentAdmin.Role === 'Editor' || currentAdmin.Role === 'Admin') && 
                      <div className="row">
                        <AddCollectionAlbumBTN
                          title={"ADD Collection"}
                          toggle={() => {
                            setCollectionFormData({})
                            toggle()
                          }}
                          className={
                            allCollections.length === 0 ? "d-flex flex-row p-3 main-list-hover" : "d-flex flex-row p-3 main-list-hover"
                          }
                          containerClassName="col-12"
                          setCollectionFormData={setCollectionFormData}
                        />
                      </div>
                      }

                      <CustomModal
                        toggle={toggle}
                        modal={modal}
                        modalTitle={"Add Collection"}
                      >
                        <CollectionForm toggle={toggle}  collectionObj={collectionObj}/>
                      </CustomModal>

                      <InfiniteScroll
                      dataLength={totalCollection}
                      next={loadMoreCollections}
                      hasMore={ allCollections.length > 0 &&  totalCollection > allCollections.length && query === '' }
                      loader={<div className='primary-color text-center'> loading... </div>}
                      scrollableTarget="scrollableDiv"
                      endMessage={
                        <p className='text-center primary-color'>
                          <b>Yay! You have seen it all</b>
                        </p>
                      }
                    >
                      <div className="row">
                        {allCollections?.map((collection) => (
                          <CollectionCard
                            key={collection.id + "" + Math.floor(Math.random() * 10000) + " " + new Date().getTime() }
                            collection={collection}
                            setCollectionFormData={setCollectionFormData}
                            toggle={toggle}
                          />
                        ))}
                      </div>
                    </InfiniteScroll>
                      
                    </>
                  )}
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Collections;
