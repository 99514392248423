
import { useEffect, useState } from 'react';
import CustomDataLoader from '../../custom-data-loader/custom-data-loader.component';
import { getDonationPurposeUsers } from '../../../services/donation-purposes-firebase.services';
import { FIREBASE_STORAGE_BASE_URL } from '../../../constant/constant';
import photoPlaceholder from "../../../assets/images/lightgallry/01.jpg";

const UsersDonationPurpose = ({donationPurposeId}) => {

    const [isDataLoading, setIsDataLoading] = useState(true);
    const [usersDonation, setUsersDonation] = useState([]);
    let totalDonation = 0;

    useEffect(() => {
        const getUserDonation = async (donationPurposeId) => {
            await getDonationPurposeUsers(donationPurposeId).then((data)=> {
                setUsersDonation(data);
                setIsDataLoading(false)
            }, (err) => {
                setIsDataLoading(false)
            })
        }

        getUserDonation(donationPurposeId)
    }, []);
    return(
        isDataLoading ? <CustomDataLoader /> :
        <table className="table align-baseline">
            <thead>
                <tr>
                    <th>#</th>
                    <th>Profile</th>
                    <th>User Name</th>
                    <th>User Email</th>
                    <th>Donation Amount</th>
                </tr>
            </thead>
            <tbody>
                {
                    usersDonation.length > 0 ? (
                        usersDonation.map((donation, index) => {
                            totalDonation += donation.amount;
                            return (
                                <tr key={index}>
                                    <td>{index + 1}</td>
                                    <td ><img className="user-profile" src={donation.profile ? donation.profile : photoPlaceholder } alt=""  crossOrigin="anonymous"/></td>
                                    <td>{donation.name}</td>
                                    <td>{donation.email}</td>
                                    <td>{donation.amount}</td>
                                </tr>
                            )
                        }) 
                        
                    ): <tr className="text-center mt-2">
                            <td colSpan={5}>
                            <p className="primary-color">Doesn't have users who donated for this purpose!!</p>
                            </td>
                        </tr>
                }

                {
                   usersDonation.length > 0 && (<tr >
                        <td colSpan={4}>
                        <p className="primary-color"><strong>Total Donations</strong></p>
                        </td>
                        <td>
                            <p className="primary-color">{totalDonation}</p>
                        </td>
                    </tr>
                    )
                }
                
            </tbody>
        </table>
    )
}

export default UsersDonationPurpose;