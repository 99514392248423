import { useEffect, useState } from "react";
import Breadcrumb from "../../layout/breadcrumb";
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Spinner,
} from "reactstrap";
import CustomBackButton from "../custom-back-button/custom-back-button.component";
import { Link, useParams } from "react-router-dom";
import Breadcrumbs from "../../layout/breadcrumb";
import { getAllConcertTableGrades } from "../../services/concerts-firebase.services";
import ConcertTableGradeCard from "./concert-table-grade-card.component";

const ConcertTableGrade = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { concert, concertTitle } = useParams();
  const [allTableGrades, setAllTableGrades] = useState([]);

  useEffect(() => {
    const allConcertTbGrades = async () => {
      setIsLoading(true);
      await getAllConcertTableGrades(concert).then((result) => {
        setAllTableGrades(result);
        setIsLoading(false);
      }).catch((err) => { setIsLoading(false); });
    };

    allConcertTbGrades();
  }, []);


  return (
    <>
      <Breadcrumbs
        parent={concertTitle ? concertTitle : "Table Grade"}
        title="Concerts"
      />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card className="main-content-card">
              <CardHeader className="bg-transparent  d-flex justify-content-between">
                <h5 className="d-flex gap-1">
                  <CustomBackButton /> List Of {concertTitle + "'s" || ""} Table
                  Grades

                </h5>
              </CardHeader>
              {isLoading ? (
                <div className="text-center">
                  <Spinner />
                </div>
              ) : (
                <CardBody className="main-content-card-body">
                  <Row>
                    <>
                      {allTableGrades?.map((tableGrade, index) => (
                        <ConcertTableGradeCard
                          key={tableGrade.id}
                          tableGrade={tableGrade}
                          concert={concert}
                          concertTitle={concertTitle}
                        />
                      ))}
                    </>
                  </Row>
                </CardBody>
              )}
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default ConcertTableGrade;
