import { createSelector } from "reselect";

const selectConcertsReducer = (state) => state.concerts;
export const selectTableGrades = (state) => state.tableGrades;
export const selectLastConcertsDoc = (state) => state.concerts.lastConcertsDoc;

export const selectConcerts = createSelector(
    [selectConcertsReducer],
    (concertsSlice) => {
        return concertsSlice.concerts;
    }
);

export const selectConcertsIsLoading = createSelector(
    [selectConcertsReducer],
    (concertsSlice) => concertsSlice.isLoading
)