import { DONATION_PURPOSE_ACTION_TYPES } from "./donation-purpose.types";

const   DONATION_PURPOSE_INITIAL_STATE = {
  donationPurposes: []
};

export const donationPurposeReducer = (state =  DONATION_PURPOSE_INITIAL_STATE, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case DONATION_PURPOSE_ACTION_TYPES.FETCH_DONATION_PURPOSES_START:
      return { ...state, isLoading: true}
    case DONATION_PURPOSE_ACTION_TYPES.FETCH_DONATION_PURPOSES_SUCCESS:
        return { ...state, donationPurposes: payload, isLoading: false }
    case DONATION_PURPOSE_ACTION_TYPES.FETCH_DONATION_PURPOSES_FAILED:
        return { ...state, error: payload, isLoading: false }
    default:
        return state;
  }
};