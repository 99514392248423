import { createSelector } from "reselect";

const selectTableGradeReducer = (state) => state.tableGrades;

export const selectTableGrades = createSelector(
    [selectTableGradeReducer],
    (tableGradeSlice) => {
        return tableGradeSlice.tableGrades;
    }
)

export const selectTableGradesIsLoading = createSelector(
    [selectTableGradeReducer],
    (tableGradeSlice) => tableGradeSlice.isLoading
)