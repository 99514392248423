import "./tag-card.styles.scss";

import photoPlaceholder from "../../../assets/images/lightgallry/01.jpg";
import { FIREBASE_STORAGE_BASE_URL } from "../../../constant/constant";
import { useSelector } from "react-redux";
import { selectCurrentAdmin } from "../../../store/admin/admin.selector";

const TagCard = ({ tag, setTagFormData, toggle }) => {
  const { Name, Color, Image, id } = tag;
  const currentAdmin = useSelector(selectCurrentAdmin);
  const handleOpenTagFormModal = () => {
    if(currentAdmin.Role === 'Editor' || currentAdmin.Role === 'Admin') {
      setTagFormData(tag);
      toggle();
    }
  };
  return (
    <>
      <div className="col-3 mb-3">
        <div
          className="tag-content h-100 position-relative main-list-hover"
          onClick={handleOpenTagFormModal}
        >
          <div className="tag-box position-relative">
            <div className="tag-img">
              <img
                src={
                  Image ? 
                  (
                    Image.includes(FIREBASE_STORAGE_BASE_URL) ? Image : FIREBASE_STORAGE_BASE_URL + Image
                  ) 
                  : photoPlaceholder
                }
                alt={Name}
                crossOrigin="anonymous"
              />
            </div>
            <div className="text-container-box mt-2" style={{ color: Color }}>
              <span className="tag-title mt-10">{Name}</span>
            </div>
          </div>
          <div
            className="tag-color-badge"
            style={{ backgroundColor: Color }}
          ></div>
        </div>
      </div>
    </>
  );
};

export default TagCard;
