import { RESERVE_CONCERT_ACTION_TYPES } from "./reserve-concert.types";

const RESERVATION_INITIAL_STATE = {
   reservations : [],
};

export const reservationReducer = (state = RESERVATION_INITIAL_STATE, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case RESERVE_CONCERT_ACTION_TYPES.FETCH_RESERVE_CONCERT_START:
      return { ...state, isLoading: true}
    case RESERVE_CONCERT_ACTION_TYPES.FETCH_RESERVE_CONCERT_SUCCESS:
        return { ...state, reservations: payload, isLoading: false }
    case RESERVE_CONCERT_ACTION_TYPES.FETCH_RESERVE_CONCERT_FAILED:
        return { ...state, error: payload, isLoading: false }  
    default:
        return state;
  }
};