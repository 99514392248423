import { createSeat, deleteSeatService, getAllSeats, updateSeatService } from "../../services/seats-firebase.services";
import { createAction } from "../../utils/reducer.utils";
import { SEAT_ACTION_TYPES } from "./seat.types";



export const fetchSeatsStart = () =>
    createAction(SEAT_ACTION_TYPES.FETCH_SEAT_START);


export const fetchSeatsSuccess = (seatsArray) => 
    createAction(SEAT_ACTION_TYPES.FETCH_SEAT_SUCCESS, seatsArray);



export const fetchSeatsFailed = (error) =>
    createAction(SEAT_ACTION_TYPES.FETCH_SEAT_FAILED, error);

export const fetchSeatsAsync = (tableGradeId, allSeats) => async (dispatch) => {
    dispatch(fetchSeatsStart());

    try {
        const seatsArray = await getAllSeats(tableGradeId);
        console.log("seats Array", seatsArray)
        const fetchedSeats =  formatFetchedSeats(seatsArray, tableGradeId);
        const allSeatsData =  allSeats ? allSeats : fetchedSeats;

        const desObj = { ...allSeatsData, ...fetchedSeats };

        dispatch(fetchSeatsSuccess(desObj));
    } catch(error) {
        console.log("Error", error)
        dispatch(fetchSeatsFailed(error))
    }
}


export const formatFetchedSeats = (seats, tableGradeId) => {
    return (seats.length > 0) ? seats.reduce((acc, seat) => {
        acc[seat.Grade_ID] = acc[seat.Grade_ID] ?? [];
        acc[seat.Grade_ID].push(seat);
        return acc;
      }, {}) : { [tableGradeId]: [] }
}

export const addSeat =  async (seat, seatsArray) => {
    try {
        const res = await createSeat(seat);
        createOrUpdateSeat(res, seatsArray)

        return res
    } catch(error) {
        console.log(error);
        return error
    }
}

const createOrUpdateSeat = async (seat, seatsArray) => {
    const checkSeatExists = (seatsArray && seatsArray.length) > 0 ? seatsArray.find(alb => alb.id === seat.id) : false;
    if(checkSeatExists) {
        const index = seatsArray.findIndex(alb => alb.id === seat.id)
        seatsArray[index] = seat;
    } else  {
        return seatsArray.unshift(seat);
    }
}

// Update seat 
export const updateSeat =  async (seat, seatsArray, seatId) => {
    try {
        const res = await updateSeatService(seat, seatId);
        createOrUpdateSeat(res, seatsArray);
    } catch(error) {
        console.log(error);
    }
}

// // Delete Seat
export const deleteSeat = async (seatId) => {
    try {
        await deleteSeatService(seatId)
    } catch(error) {
        console.log(error);
    }
}