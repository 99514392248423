import "./custom-percentage-loader.styles.scss";

const CustomPercentageLoader = ({ percent }) => {
  return (
    <div className="percent">
      <svg>
        <circle cx="105" cy="105" r="100"></circle>
        <circle
          cx="105"
          cy="105"
          r="100"
          style={{ "--percent": percent }}
        ></circle>
      </svg>
      <div className="number primary-color">
        <h3>
          {percent}
          <span>%</span>
        </h3>
      </div>
    </div>
  );
};

export default CustomPercentageLoader;
