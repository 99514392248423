import './collection-card.styles.scss';
import photoPlaceholder from '../../../assets/images/lightgallry/01.jpg';
import { FIREBASE_STORAGE_BASE_URL } from '../../../constant/constant';
import { Grid, ShoppingCart } from 'react-feather';
import { Link } from 'react-router-dom';

const ConcertCards = ({ concerts, setCollectionFormData, toggle }) => {
    const { Title, Description, Photo, id } = concerts;
    const handleOpenConcertsFormModal = () => {
        setCollectionFormData(concerts);
        toggle();
    }

    return (
        <>
            <div className='col-3 mb-3'>
                <div
                    onClick={handleOpenConcertsFormModal}
                    className="collection-content h-100 position-relative main-list-hover">
                    <div className="collection-box">
                        <div className="collection-img">
                            <img src={Photo ?
                                (
                                    Photo.includes(FIREBASE_STORAGE_BASE_URL) ? Photo : FIREBASE_STORAGE_BASE_URL + Photo
                                )
                                : photoPlaceholder} alt={Title} crossOrigin='anonymous' />
                        </div>
                        <span className="collection-title mt-10">{Title}</span>
                        <p className="collection-description"><strong>Description:</strong> {Description}</p>
                    </div>

                    <div className="link-container">
                        <Link to={`/concerts/${id}/${Title.replace(new RegExp("[;\\\\/:*?$#\"<>|&']"), ' ')}/tb-grades`} onClick={(event) => event.stopPropagation()}> <Grid /> Table Grades </Link>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ConcertCards;