import { getUserSubscriptions } from "../../../services/users-firebase.services";
import { formatTimestap } from "../../../utils/helper.utils";
import CustomDataLoader from "../../custom-data-loader/custom-data-loader.component";
import { useEffect, useState } from 'react';

const UserSubscriptions = ({userId}) => {

    const [isDataLoading, setIsDataLoading] = useState(true);
    const [userSubscriptions, setUserSubscriptions] = useState([]);

    useEffect(() => {
        console.log("DATA", userId);
        const getUserSubscription = async (userId) => {
            await getUserSubscriptions(userId).then((data)=> {
                setUserSubscriptions(data);
                setIsDataLoading(false)
                console.log("DATA", data);
            }, (err) => {
                console.log("ERROR", err);
                setIsDataLoading(false)
            })
        }

        getUserSubscription(userId)
    }, []);
    return(
        isDataLoading ? <CustomDataLoader /> : 
        <table className="table align-baseline">
            <thead>
                <tr>
                    <th>#</th>
                    <th>Package Title</th>
                    <th>Package Duration</th>
                    <th>Payable</th>
                    <th>Expiration Date</th>
                    <th>Status</th>
                </tr>
            </thead>
            <tbody>
                {
                    userSubscriptions.length > 0 ? userSubscriptions.map((subscription, index) => (
                        <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{subscription.Title}</td>
                            <td>{subscription.Duration}</td>
                            <td>{subscription.Payable_Amount}</td>
                            <td>{ formatTimestap(subscription.Expiration_Date) }</td>
                            <td>{ subscription.Status }</td>
                        </tr>
                    )) : <tr className="text-center mt-2">
                            <td colSpan={6}>
                            <p className="primary">Doesn't have Subscription!</p>
                            </td>
                        </tr>
                }
                
            </tbody>
        </table>
    )
}

export default UserSubscriptions;