import { Plus } from 'react-feather';
import './add-collection-album-btn.styles.scss';

const AddCollectionAlbumBTN = ({ toggle, title, className, containerClassName }) => {
    return (
        <div className={containerClassName ? containerClassName + " mb-3": "col-3 mb-3" } onClick={toggle}>
            <div className={`add-collection-box ${className}`}>
                <Plus />
                <a>{title}</a>
            </div>
        </div>
    )
}

export default AddCollectionAlbumBTN;