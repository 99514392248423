import { createSelector } from "reselect";

const selectAlbumReducer = (state) => {
    return state.products;
};

export const selectAlbumProductsMap = createSelector(
    [selectAlbumReducer],
    (productSlice) => productSlice.products
)

export const selectAlbumProductsIsLoading = createSelector(
    [selectAlbumReducer],
    (albumProductsSlice) => albumProductsSlice.isLoading
)