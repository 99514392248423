import "./package-card.styles.scss";
import { useSelector } from "react-redux";
import { selectCurrentAdmin } from "../../../store/admin/admin.selector";
import CustomModal from "../../custom-modal/custom-modal.component";
import SubscribedUsers from "../subscribed-users/subscribed-users.component";
import { useState } from "react";


const PackageCard = ({ packageObj, setPackageFormData, toggle }) => {
  const { name, description, billing_cycles, id } = packageObj;
  const currentAdmin = useSelector(selectCurrentAdmin);
  const [subscriptionId, setSubscriptionId] = useState();
  // Donation Modal state
  const [subscribedUsersModal, setSubscribedUsersModal] = useState(false);
  const toggleSubscribedUsersModal = (subscription) => {
    setSubscriptionId(subscription.id ? subscription.id : null);
    setSubscribedUsersModal(!subscribedUsersModal);
  };
  
  const handleOpenPackageFormModal = () => {
    if((currentAdmin.Role === 'Editor' || currentAdmin.Role === 'Admin')) {
      setPackageFormData(packageObj);
      toggle();
    }
  };
  return (
    <>
      <div
        className="package-list-item position-relative row align-items-center main-list-hover"
        // onClick={handleOpenPackageFormModal}
      >
        <div className="col-md-3">
          <div className="d-flex align-items-center gap-2 position-relative">
            <span className="package-list-name">{name}</span>
            <div className="package-list-color"></div>
          </div>
        </div>
        <div className="col-md-3 d-flex">
          <p className="package-list-description">
            {description}
          </p>
        </div>
        <div className="col-md-2 d-flex">
          <p className="package-list-description">
            {billing_cycles[0]?.frequency?.interval_count} { billing_cycles[0]?.frequency?.interval_unit }
          </p>
        </div>
        <div className="col-md-2 d-flex">
          <p className="package-list-description">
          {billing_cycles[0]?.pricing_scheme?.fixed_price?.value} 
          {/* {billing_cycles[0]?.pricing_scheme?.fixed_price?.currency_code} */}
          </p>
        </div>
        <div className="col-md-2 d-flex">
          <div className="d-flex flex-column">
            <div className="package-list-edit">
              <button onClick={() => toggleSubscribedUsersModal(packageObj)}>Subscribed Users</button>
            </div>
          </div>
        </div>
      </div>

      {/* Subscribed Users Modal */}
      <CustomModal
        toggle={toggleSubscribedUsersModal}
        modal={subscribedUsersModal}
        modalTitle={"Subscribed Users"}
      >
        <SubscribedUsers subscriptionId={subscriptionId} />
      </CustomModal>
    </>
  );
};

export default PackageCard;
