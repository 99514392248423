import "./seat-list.styles.scss";
import CustomModal from "../../custom-modal/custom-modal.component";
import { Spinner } from "reactstrap";
import { Plus } from "react-feather";
import { useState } from "react";
import { useSelector } from "react-redux";
import { selectCurrentAdmin } from "../../../store/admin/admin.selector";
import CustomSearchBar from "../../custom-search-bar/custom-search-bar.component";
import SeatRow from "../seat-row/seat-row.component";
import SeatForm from "../seat-form/seat-form.component";

const SeatList = ({ toggle, modal, tableGradeId, allSeats, isLoading }) => {
  const [seatObj, setSeatObj] = useState({});
  const setSeatFormData = (prodObj) => setSeatObj(prodObj);
  const currentAdmin = useSelector(selectCurrentAdmin);
  const [query, setQuery] = useState("")

  const handleAddSeatToggle = () => {
    if(currentAdmin.Role === 'Editor' || currentAdmin.Role === 'Admin') {
      setSeatFormData({});
      toggle();
    }
  }
  return (
    <>
      <div className="list-view">
        {(currentAdmin.Role === 'Editor' || currentAdmin.Role === 'Admin') && <div className="row">
          {/* Open Add Modal Here */}
          <div
            className="add-product-list-box main-list-hover"
            onClick={handleAddSeatToggle}
          >
            <Plus />
            <span>Add Seat</span>
          </div>
        </div>
        }
        <CustomSearchBar placeholder="Search your seat here..." setQuery={setQuery} />
        <div className="row mt-2">
          <div className="col-md-8">
            <span className="product-list-table-head-text">Seat Number</span>
          </div>

          <div className="col-md-2">
            <span className="product-list-table-head-text">Status</span>
          </div>

          <div className="col-md-2">Action</div>
        </div>
        
        {isLoading ? (
          <div className="text-center">
            <Spinner />
          </div>
        ) : allSeats && allSeats.length > 0 ? (
          allSeats?.filter((seat) => {
            if (query === '') {
              return seat;
            } else if (seat.Seat_Number === query) {
              return seat;
            }
          })
          .map((seat) => (
            <SeatRow
              seat={seat}
              key={seat.id}
              toggle={toggle}
              setSeatFormData={setSeatFormData}
            />
          ))
        ) : (
          <div className="row">
            <div className="col-12 text-center  mt-2">
              <p className="primary">Doesn't have Seat!</p>
            </div>
          </div>
        )}
        <CustomModal toggle={toggle} modal={modal} modalTitle={"Save Reseravtion"}>
          <SeatForm toggle={toggle} tableGradeId={tableGradeId} seatObj={seatObj} />
        </CustomModal>
      </div>
    </>
  );
};

export default SeatList;
