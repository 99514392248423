import { useState, useEffect } from 'react';
import { Form, Button, Spinner } from 'reactstrap'
import FormInput from '../../form-input/form-input.component';
import { useSelector }  from 'react-redux';
import { addCollectionAlbum, deleteCollectionAlbum, updateCollectionAlbum } from '../../../store/album/album.action';
import { selectCollectionAlbumsMap } from '../../../store/album/album.selector';
import { toast } from 'react-toastify';
import SweetAlert from "sweetalert2";
import { selectCurrentAdmin } from '../../../store/admin/admin.selector';

const AlbumForm = ({ toggle, collectionId, albumObj }) => {
    const [values, setValues] = useState({
        Title: "",
        Release_Date: "",
        Photo: "",
        CollectionID: collectionId,
        Created_at: new Date(),
        Updated_at: new Date(),
        Status: 1
    });
    const [isUploading, setIsUploading] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [photoUrl, setPhotoUrl] = useState(null);
    const [albumId, setAlbumId] = useState(null);
    const allCollectionAlbums = useSelector(selectCollectionAlbumsMap);
    const currentAdmin = useSelector(selectCurrentAdmin);

    useEffect(() => {
        if (Object.keys(albumObj).length > 0) {
            const { Updated_at, id, ...rest } = albumObj;
            setValues({ ...values, ...rest });
            setAlbumId(id);
            setPhotoUrl(albumObj.Photo ? albumObj.Photo: null);
        }

        setIsLoading(false);
    }, []);


    const handleSubmit = (e) => {
        e.preventDefault();
        if (isUploading) return;
        setIsSaving(true)
        values.Photo = photoUrl ? photoUrl : null;

        albumId !== null ?
            updateCollectionAlbum({ ...values, id:albumId }, allCollectionAlbums[collectionId], albumId).then(() => {
                toast.success('Album successfully saved.');
                setIsSaving(false)
                toggle()
            })
        : addCollectionAlbum(values, allCollectionAlbums[collectionId]).then(() => {
            toast.success('Album successfully saved.');
            setIsSaving(false)
            toggle()
        })
    };

    const onChange = (e) => {
        setValues({ ...values, [e.target.name]: e.target.value });
    };

    // Handle Delete Album
  const handleDeleteAlbum = () => {
    SweetAlert.fire({
      title: "Are you sure?",
      text: "Once deleted, it will be hard to recover it again.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Ok",
      cancelButtonText: "cancel",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        deleteCollectionAlbum(albumId);
        SweetAlert.fire("Deleted!", "Your album has been deleted.", "success");
        const index = allCollectionAlbums[collectionId].findIndex(alb => alb.id === albumId);
        allCollectionAlbums[collectionId].splice(index, 1);
        toggle();
      } else {
        SweetAlert.fire("Your album is safe!");
      }
      
    });
  };

    return (
        <Form className="form-bookmark needs-validation" onSubmit={handleSubmit}>
            <div className="form-row row mb-3">
                <div className="col-8">
                    <FormInput
                        name= "Title"
                        type= "text"
                        placeholder= "Album Title"
                        errorMessage="Album Title should be more than 3 characters!"
                        label= "Title"
                        min="3"
                        required
                        value={values['Title']}
                        onChange={onChange}
                    />

                    <FormInput
                        name= "Release_Date"
                        type= "datetime-local"
                        label= "Realse Date"
                        errorMessage="Please select release date!"
                        required
                        value={values['Release_Date']}
                        onChange={onChange}
                    />
                </div>
                <div className="col-4">
                    { !isLoading && 
                        (<FormInput
                            name= "Photo"
                            type= "file"
                            placeholder= "Upload a photo"
                            label= "Album photo"
                            uploadDirectory="Albums"
                            value={values['Photo']}
                            setValues={setValues}
                            values={values}
                            setIsUploading={setIsUploading}
                            setPhotoUrl={setPhotoUrl}
                            photoUrl={photoUrl}
                            onChange={onChange}
                        />)
                    }
                </div>
            </div>
            <div className={albumId && currentAdmin.Role === 'Admin' ? "d-flex justify-content-between" : ""}>
                {(albumId && currentAdmin.Role === 'Admin') && (
                <Button color="secondary" onClick={handleDeleteAlbum}>
                    Delete
                </Button>
                )}
                <div
                className={
                    (!albumId ||  currentAdmin.Role !== 'Admin') ? "d-flex justify-content-between" : "d-flex gap-2"
                }
                >
                <Button color="light" onClick={toggle}>
                    Cancel
                </Button>

                <Button
                    color="primary"
                    className="mr-1"
                    disabled={isSaving ? true : false}
                >
                    {isSaving ? (
                    <Spinner style={{ height: "20px", width: "20px" }} />
                    ) : (
                    "Save"
                    )}{" "}
                </Button>
                </div>
            </div>
        </Form>
    ) 
}

export default AlbumForm;