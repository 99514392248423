import { useState, useEffect } from "react";
import { Form, Button, Spinner } from "reactstrap";
import FormInput from "../../form-input/form-input.component";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import SweetAlert from "sweetalert2";

import { selectCurrentAdmin } from "../../../store/admin/admin.selector";
import { selectDonationPurposes } from "../../../store/donation-purposes/donation-purpose.selector";
import { addDonationPurpose, deleteDonationPurpose, updateDonationPurpose } from "../../../store/donation-purposes/donation-purpose.action";

const DonationPurposeForm = ({ toggle, donationPurposeObj }) => {
  const [values, setValues] = useState({
    Title: "",
    Photo: "",
    Description: "",
    Status: 1,
    Created_at: new Date(),
    Updated_at: new Date(),
  });
  const [isUploading, setIsUploading] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [photoUrl, setPhotoUrl] = useState(null);
  const [donationPurposeId, setDonationPurposeId] = useState(null);
  const allDonationPurposes = useSelector(selectDonationPurposes);
  const currentAdmin = useSelector(selectCurrentAdmin);

  useEffect(() => {
    if (Object.keys(donationPurposeObj).length > 0) {
      const { Updated_at, id, ...rest } = donationPurposeObj;
      setValues({ ...values, ...rest });
      setDonationPurposeId(id);
      setPhotoUrl(donationPurposeObj.Photo ? donationPurposeObj.Photo : null);
    }
  }, [donationPurposeObj]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (isUploading) return;
    setIsSaving(true);
    values.Photo = photoUrl ? photoUrl : null;

    donationPurposeId
      ? updateDonationPurpose({ ...values, id:donationPurposeId }, allDonationPurposes, donationPurposeId).then(() => {
          toast.success("Your donation purpose has been saved.");
          setIsSaving(false);
          toggle();
        })
      : addDonationPurpose(values, allDonationPurposes).then(() => {
          toast.success("Your donation purpose has been saved.");
          setIsSaving(false);
          toggle();
        });
  };

  const onChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  // Handle Delete Donation Purpose
  const handleDeleteDonationPurpose = () => {
    SweetAlert.fire({
      title: "Are you sure?",
      text: "Once deleted, it will be hard to recover it again.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Ok",
      cancelButtonText: "cancel",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        deleteDonationPurpose(donationPurposeId).then(() => {
          SweetAlert.fire("Deleted!", "Your donation purpose has been deleted.", "success");
          const index = allDonationPurposes.findIndex(alb => alb.id === donationPurposeId);
          allDonationPurposes.splice(index, 1);
          toggle();
        }, (err) => {
          SweetAlert.fire("Not deleted", "Your donation purpose has not been deleted.", "error"); 
        })
        
      } else {
        SweetAlert.fire("Your donation purpose is safe!");
      }
      
    });
  };

  return (
    <Form className="form-bookmark needs-validation" onSubmit={handleSubmit}>
      <div className="form-row row mb-3">
        <div className="col-8">
          <div className="mb-3">
            <FormInput
              name="Title"
              type="text"
              placeholder="Donation purpose title"
              errorMessage="Donation purpose title should be more than 3 characters!"
              label="Title"
              min={3}
              required
              value={values["Title"]}
              onChange={onChange}
            />
          </div>

          <div className="mb-3">
            <FormInput
              name="Description"
              type="textarea"
              placeholder="Description"
              label="Description"
              value={values["Description"]}
              onChange={onChange}
            />
          </div>
        </div>
        <div className="col-4">
          <div className="mt-4">
            <FormInput
              name="Photo"
              type="file"
              placeholder="Upload an Photo"
              label="Donation Purpose Photo"
              uploadDirectory="DonationPurposes"
              value={values["Photo"]}
              setValues={setValues}
              values={values}
              setIsUploading={setIsUploading}
              setPhotoUrl={setPhotoUrl}
              photoUrl={photoUrl}
              onChange={onChange}
            />
          </div>
        </div>
      </div>
      <div className={donationPurposeId && currentAdmin.Role === 'Admin' ? "d-flex justify-content-between" : ""}>
        {(donationPurposeId && currentAdmin.Role === 'Admin') && (
          <Button color="secondary" onClick={handleDeleteDonationPurpose}>
            Delete
          </Button>
        )}
        <div
          className={
            (!donationPurposeId ||  currentAdmin.Role !== 'Admin') ? "d-flex justify-content-between" : "d-flex gap-2"
          }
        >
          <Button color="light" onClick={toggle}>
            Cancel
          </Button>

          <Button
            color="primary"
            className="mr-1"
            disabled={isSaving ? true : false}
          >
            {isSaving ? (
              <Spinner style={{ height: "20px", width: "20px" }} />
            ) : (
              "Save"
            )}{" "}
          </Button>
        </div>
      </div>
    </Form>
  );
};

export default DonationPurposeForm;
