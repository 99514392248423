import { useState, useEffect } from "react";
import { Form, Button, Spinner } from "reactstrap";
import FormInput from "../../form-input/form-input.component";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import SweetAlert from "sweetalert2";
import { selectCurrentAdmin } from "../../../store/admin/admin.selector";
import { addTableGrade, deleteTableGrade, updateTableGrade } from "../../../store/table-grade/table-grade.action";
import { selectTableGrades } from "../../../store/table-grade/table-grade.selector";

const TableGradeForm = ({ toggle, tableGradeObj }) => {
  const [values, setValues] = useState({
    Grade: "",
    Color: "#7366ff",
    Table_Number: 0,
    Price: 0,
    Capacity: 0,
    Status: 1,
    Created_at: new Date(),
    Updated_at: new Date(),
  });
  const [isSaving, setIsSaving] = useState(false);
  const [tableGradeId, setTableGradeId] = useState(null);
  const allTableGrades = useSelector(selectTableGrades);
  const currentAdmin = useSelector(selectCurrentAdmin);

  useEffect(() => {
    if (tableGradeObj && Object.keys(tableGradeObj).length > 0) {
      const { Updated_at, id, ...rest } = tableGradeObj;
      setValues({ ...values, ...rest });
      setTableGradeId(id);
    }
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();

    setIsSaving(true);


    tableGradeId
      ? updateTableGrade({ ...values, id:tableGradeId }, allTableGrades, tableGradeId).then(() => {
          toast.success("Your table grade has been saved.");
          setIsSaving(false);
          toggle();
        })
      : addTableGrade(values, allTableGrades).then(() => {
          toast.success("Your table grade has been saved.");
          setIsSaving(false);
          toggle();
        });
  };

  const onChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  // Handle Delete Table Grade
  const handleDeleteTableGrade = () => {
    SweetAlert.fire({
      title: "Are you sure?",
      text: "Once deleted, it will be hard to recover it again.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Ok",
      cancelButtonText: "cancel",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        deleteTableGrade(tableGradeId).then(() => {
          SweetAlert.fire("Deleted!", "Your table grade has been deleted.", "success");
          const index = allTableGrades.findIndex(alb => alb.id === tableGradeId);
          allTableGrades.splice(index, 1);
          toggle();
        }, (err) => {
          SweetAlert.fire("Not deleted", "Your table grade has not been deleted.", "error"); 
        })
        
      } else {
        SweetAlert.fire("Your table grade is safe!");
      }
      
    });
  };

  return (
    <Form className="form-bookmark needs-validation" onSubmit={handleSubmit}>
      <div className="form-row row mb-3">
        <div className="col-6">
          <div className="mb-3">
            <FormInput
              name="Grade"
              type="text"
              placeholder="Grade Name"
              errorMessage="Grade Name should be more than 3 characters!"
              label="Grade"
              min={3}
              required
              value={values["Grade"]}
              onChange={onChange}
            />
          </div>
        </div>
        
        <div className="col-6">
          <div className="mb-3">
            <FormInput
              name="Color"
              type="color"
              placeholder="Grade Color"
              label="Color"
              style={{ height: "40px" }}
              value={values["Color"]}
              onChange={onChange}
            />
          </div>
          
        </div>

        <div className="col-4">
          <div className="mb-3">
            <FormInput
              name="Table_Number"
              type="number"
              placeholder="Table Number"
              errorMessage="Table Number is required!"
              label="Table Number"
              required
              value={values["Table_Number"]}
              onChange={onChange}
            />
          </div>
        </div>
        <div className="col-4">
          <div className="mb-3">
            <FormInput
              name="Capacity"
              type="number"
              placeholder="Capacity"
              errorMessage="Capacity is required!"
              label="Capacity"
              min={1}
              required
              value={values["Capacity"]}
              onChange={onChange}
            />
          </div>
        </div>
        <div className="col-4">
          <div className="mb-3">
            <FormInput
              name="Price"
              type="text"
              placeholder="Price"
              errorMessage="Price is required!"
              label="Price"
              required
              value={values["Price"]}
              onChange={onChange}
            />
          </div>
        </div>
      </div>
      <div className={tableGradeId && currentAdmin.Role === 'Admin' ? "d-flex justify-content-between" : ""}>
        {(tableGradeId && currentAdmin.Role === 'Admin') && (
          <Button color="secondary" onClick={handleDeleteTableGrade}>
            Delete
          </Button>
        )}
        <div
          className={
            (!tableGradeId ||  currentAdmin.Role !== 'Admin') ? "d-flex justify-content-between" : "d-flex gap-2"
          }
        >
          <Button color="light" onClick={toggle}>
            Cancel
          </Button>

          <Button
            color="primary"
            className="mr-1"
            disabled={isSaving ? true : false}
          >
            {isSaving ? (
              <Spinner style={{ height: "20px", width: "20px" }} />
            ) : (
              "Save"
            )}{" "}
          </Button>
        </div>
      </div>
    </Form>
  );
};

export default TableGradeForm;
