import { createSelector } from "reselect";

const selectUserReducer = (state) => state.users;

export const selectUsers = createSelector(
    [selectUserReducer],
    (userSlice) => {
        return userSlice.users;
    }
)

export const selectUsersIsLoading = createSelector(
    [selectUserReducer],
    (userSlice) => userSlice.isLoading
)