import { useState, useEffect } from "react";
import { Form, Button, Spinner } from "reactstrap";
import FormInput from "../../form-input/form-input.component";

import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import SweetAlert from "sweetalert2";


import { selectCurrentAdmin } from "../../../store/admin/admin.selector";
import { selectSeatsMap } from "../../../store/seat/seat.selector";
import { addSeat, deleteSeat, updateSeat } from "../../../store/seat/seat.action";

const SeatForm = ({ toggle, tableGradeId, seatObj }) => {
  const [values, setValues] = useState({
    Grade_ID: tableGradeId,
    Seat_Number: 0,
    Created_at: new Date(),
    Updated_at: new Date(),
    Status: 1,
  });

  const [isSaving, setIsSaving] = useState(false);
  
  const allSeats = useSelector(selectSeatsMap);
  const [seatId, setSeatId] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const currentAdmin = useSelector(selectCurrentAdmin);

  useEffect(() => {
    if (seatObj && Object.keys(seatObj).length > 0) {
      const { Updated_at, id, ...rest } = seatObj;
      setValues({ ...values, ...rest });
      setSeatId(id);
    }
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();

    setIsSaving(true);

    seatId !== null
      ? updateSeat(
          { ...values, id: seatId },
          allSeats[tableGradeId],
          seatId
        ).then(() => {
          toast.success("Your seat has been Updated.");
          setIsSaving(false);
          setSeatId(null);
          toggle();
        },(err)=> { toast.error("Something went wrong, please try again"); setIsSaving(false);})
      : addSeat(
          values,
          allSeats[tableGradeId] ? allSeats[tableGradeId] : []
        ).then(() => {
          toast.success("Your seat has been saved.");
          setIsSaving(false);
          toggle();
        }, (err)=> { toast.error("Something went wrong, please try again"); setIsSaving(false);});
  };

  // Handle Delete seat
  const handleDeleteSeat = () => {
    SweetAlert.fire({
      title: "Are you sure?",
      text: "Once deleted, it will be hard to recover it again.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Ok",
      cancelButtonText: "cancel",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        deleteSeat(seatId).then(() => {
          SweetAlert.fire(
            "Deleted!",
            "Your seat has been deleted.",
            "success"
          );
          const index = allSeats[tableGradeId].findIndex(
            (seat) => seat.id === seatId
          );
          allSeats[tableGradeId].splice(index, 1);
          toggle();
        }, (err) => SweetAlert.fire(
          "Woops!",
          "Something went wrong, please try again later.",
          "error"
        ))
      } else {
        SweetAlert.fire("Your seat is safe!");
      }
    });
  };

  const onChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };


  return (
    <>
      <Form className="form-bookmark needs-validation" onSubmit={handleSubmit}>
        {!isLoading && (
          <div className="form-row row mb-3">

            <div className="col-6 mb-3">
              <FormInput
                name="Seat_Number"
                type="text"
                placeholder="Seat Number"
                errorMessage="Seat Number is required!"
                label="Seat Number"
                step=".01"
                required
                value={values["Seat_Number"]}
                onChange={onChange}
              />
            </div>

            <div className="col-6 mb-3">
              <FormInput
                name="Status"
                type="number"
                placeholder="Seat Status"
                errorMessage="Seat Status is required!"
                label="Seat Status"
                min={0}
                max={1}
                required
                value={values["Status"]}
                onChange={onChange}
              />
            </div>
          </div>
        )}
        <div
          className={
            seatId && currentAdmin.Role === "Admin"
              ? "d-flex justify-content-between"
              : ""
          }
        >
          {seatId && currentAdmin.Role === "Admin" && (
            <Button color="secondary" onClick={handleDeleteSeat}>
              Delete
            </Button>
          )}
          <div
            className={
              !seatId || currentAdmin.Role !== "Admin"
                ? "d-flex justify-content-between"
                : "d-flex gap-2"
            }
          >
            <Button color="light" onClick={toggle}>
              Cancel
            </Button>

            <Button
              color="primary"
              className="mr-1"
              disabled={isSaving ? true : false}
            >
              {isSaving ? (
                <Spinner style={{ height: "20px", width: "20px" }} />
              ) : (
                "Save"
              )}
            </Button>
          </div>
        </div>
      </Form>
    </>
  );
};

export default SeatForm;
