import {
    updateDoc,
    collection,
    addDoc,
    doc,
    where,
    setDoc,
    query,
    orderBy,
    getDocs,
} from "firebase/firestore";
import { db } from "../config/firebase.config";
import { removeUndefinedNull } from "../utils/helper.utils";

export const createCollectionAlbum = async (docData) => {
    docData = removeUndefinedNull(docData);
    const res = await addDoc(collection(db, "Albums"), docData);
    if (res.id) {
        return {
            id: res.id,
            ...docData
        }
    }
}

export const updateCollectionAlbumService = async (docData, productId) => {
    docData = removeUndefinedNull(docData);
    const { id, ...rest } = docData; 
    const updatedDoc = { ...rest, Updated_at: new Date() };
    await updateDoc(doc(db, "Albums", productId), updatedDoc)

    return await {
        ...updatedDoc,
        id: productId
    }
}

export const deleteCollectionAlbumService = async (productId) => {
    await updateDoc(doc(db, "Albums", productId), { Status: 0, Updated_at: new Date() });
    
}

export const getCollectionAlbums = async (collectionId) => {
    const collectionAblumsRef = collection(db, 'Albums');
    const q = query(collectionAblumsRef, where("CollectionID", '==', collectionId), where("Status", '==', 1), orderBy("Created_at", "desc"));
    const querySnapshot = await getDocs(q);
    return querySnapshot.docs.map((docSnapshot) =>  ({
        id: docSnapshot.id,
        ...docSnapshot.data()
    }))
   
}

