import React from 'react';
// import Loader from "../layout/loader"
// import {ToastContainer} from 'react-toastify'
import { routes } from './routes';
import { Route, Navigate } from 'react-router-dom'
import Main from './layout/main';
import Authentication from './layout/authentication/authentication.component';
import { useDispatch, useSelector } from 'react-redux';
import { getAdminDocById, onAuthStateChangedListener, signOutUser } from './services/auth-firebase.services';
import { useEffect } from 'react';
import { setCurrentAdmin } from './store/admin/admin.action';
import { selectCurrentAdmin } from './store/admin/admin.selector';
import { useState } from 'react';
import Dashboard from './components/dashboard/dashboard.component';
import CustomDataLoader from './components/custom-data-loader/custom-data-loader.component';
import CustomRoutesContainer from './components/custom-routes-container/custom-routes-container.component';
import SignInForm from './components/auth/sign-in-form/sign-in-form.component';
import SignUpForm from './components/auth/sign-up-form/sign-up-form.component';
import ForgotPasswordForm from './components/auth/forgot-password-form/forgot-password-form.component';


const App = () => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const currentAdmin = useSelector(selectCurrentAdmin);

  useEffect(() => {
    if(!localStorage.getItem('layout_version')){
      localStorage.setItem("layout_version", "dark-only");
    }
    const unsubscribe = onAuthStateChangedListener( async (admin) => {
      if (admin) {
        const adminData = await getAdminDocById(admin.uid);

        if(adminData && adminData.Status && adminData.Status == 1) {
          dispatch(setCurrentAdmin({ ...admin, ...adminData }));
          setIsLoading(false);
        } else if(currentAdmin && Object.keys(currentAdmin).length !== 0) {
          signOutUser();
          dispatch(setCurrentAdmin({}));
          setIsLoading(false);
        } else {
          setIsLoading(false);
        }
      } else {
        setIsLoading(false);
      } 
      
    });
    // setIsLoading(false);
    return unsubscribe;
  }, []);

  return (
    <>
      { isLoading && (<CustomDataLoader />)}
      <CustomRoutesContainer>
        { (currentAdmin && currentAdmin !== null && Object.keys(currentAdmin).length !== 0) ? (
          <Route path="/" element={<Main />}  >
            <Route index={true} path='dashboard' element={<Dashboard />} />
            {
              routes.map(({ path, Component }, index) => (
                <Route key={path} path={`${path}`} index={ index === 0} element={<Component />} />
              ))
            }
            
            <Route path="*" element={<Navigate replace to="/dashboard" />} />
          </Route>
          ) : 

          ( 
            !isLoading ? (
              <>
                <Route  path='/authentication' element={<Authentication />} >
                  <Route path='login' element={ <SignInForm /> } />
                  <Route path='signup' element={ <SignUpForm /> } />
                  <Route path='forgotPassword' element={ <ForgotPasswordForm /> } />
                  <Route path='resetPassword' element={ <SignUpForm /> } />
                </Route>
                <Route path="*" element={<Navigate replace to="/authentication/login" />}/>
              </>
            ) : ''
          )
        }
        {/* <Route  path='/login' element={<Authentication />} /> */}
      </CustomRoutesContainer>
    </>
  );
}

export default App;

