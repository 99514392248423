import { Card, CardBody, CardHeader, Container, Row, Col } from "reactstrap";
import Breadcrumbs from "../../layout/breadcrumb";
import "./users.styles.scss";
import UsersList from "./users-list.component";
import CustomBackButton from "../custom-back-button/custom-back-button.component";
import { useState } from "react";

const Users = () => {
  const [totalCountOfUser, setTotalCountOfUser] = useState(0);
  
  return (
    <>
      <Breadcrumbs parent="Users" title="Users" />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card className="main-content-card">
              <CardHeader className="bg-transparent d-flex justify-content-between" >
                <h5 className="d-flex gap-1"> <CustomBackButton /> Users </h5>
                <h5>Total Users: { totalCountOfUser }</h5>
              </CardHeader>
              <CardBody className="main-content-card-body"  id="scrollableDiv">
                <UsersList setTotalCountOfUser={setTotalCountOfUser} />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Users;
