import {
    setDoc,
    collection,
    addDoc,
    doc,
    where,
    updateDoc,
    limit,
    query,
    orderBy,
    getDocs,
    getDoc,
} from "firebase/firestore";
import { db } from "../config/firebase.config";
import { removeUndefinedNull } from "../utils/helper.utils";


export const getAllConcerts = async () => {
    const concertsRef = collection(db, 'Concerts');
    const q = query(concertsRef, where("Status", "==", 1));
    const querySnapshot = await getDocs(q);
    return querySnapshot.docs.map((docSnapshot) => ({
        id: docSnapshot.id,
        ...docSnapshot.data()
    }))
}

export const getAllTableGrades = async () => {
    const tableGradeRef = collection(db, 'Table_Grade');
    const q = query(tableGradeRef);
    const querySnapshot = await getDocs(q);
    return querySnapshot.docs.map((docSnapshot) => ({
        id: docSnapshot.id,
        ...docSnapshot.data()
    }))
}

// get all table grades taken by single table
export const getAllConcertTableGrades = async (concertId) => {

    const concertDocRef = doc(db, "Concerts", concertId);
    const snapshot = await getDoc(concertDocRef);

    if (snapshot.data() && snapshot.data().Table_Grade) {
        return await Promise.all(snapshot.data().Table_Grade.map(async (tbg) =>  {
            const tableGrade = await getTableGradeByID(tbg.Grade_ID)
            return tableGrade;
            
        }))
    } else {
        return []
    }
   
}

// Get Table Grade By ID
const getTableGradeByID = async (id) => {
    const tbGradeRef = doc(db, 'Table_Grade', id);
    const snapshot = await getDoc(tbGradeRef)

    return {
        id: snapshot.id,
        ...snapshot.data()
    };
}

export const getAllHalls = async () => {
    const tableGradeRef = collection(db, 'Hall');
    const q = query(tableGradeRef);
    const querySnapshot = await getDocs(q);
    return querySnapshot.docs.map((docSnapshot) => ({
        id: docSnapshot.id,
        ...docSnapshot.data()
    }))
}

export const updateConcertsService = async (docData, collectionId) => {
    docData = removeUndefinedNull(docData);
    const { id, ...rest } = docData; 
    const updatedDoc = { 
        ...rest, 
        Title: rest.Title.toLowerCase(), 
        Updated_at: new Date(),
        C_E_Datetime: new Date(docData.C_E_Datetime),
        C_S_Datetime: new Date(docData.C_S_Datetime)  
    };
    await updateDoc(doc(db, "Concerts", collectionId), updatedDoc)
    return await {
        ...docData,
        id: collectionId
    }
}

export const deleteConcertsService = async (collectionId) => {
    await updateDoc(doc(db, "Concerts", collectionId), { Status: 0, Updated_at: new Date() });
}

export const createConcerts = async (docData) => {
    docData = removeUndefinedNull(docData);
    const res = await addDoc(collection(db, "Concerts"), 
        { 
            ...docData, 
            Title: docData.Title.toLowerCase(),
            C_E_Datetime: new Date(docData.C_E_Datetime),
            C_S_Datetime: new Date(docData.C_S_Datetime)  
        }
    );

    if (res.id) {
        return {
            id: res.id,
            ...docData
        }
    }
}

export const searchConcerts = async (searchString = '') => {
    const concertSearchRef = collection(db, 'Concerts');
    const q = query(concertSearchRef,
        where('Title', '>=', searchString.toLocaleLowerCase()),
        where('Title', '<=', searchString.toLocaleLowerCase() + '\uf8ff'));

    const querySnapshot = await getDocs(q);
    const concerts = querySnapshot.docs.map((docSnapshot) => ({
        id: docSnapshot.id,
        ...docSnapshot.data()
    }));
    return { concerts }
}

export const getCurrentConcert = async (concertId) => {
    const concertRef = doc(db, 'Concerts', concertId);
    const snapshot = await getDoc(concertRef)

    return snapshot.data();

}