import { Modal, ModalHeader, ModalBody } from 'reactstrap'
import './custom-modal.styles.scss';

const CustomModal = (props) => {
    const { modal, toggle, children, modalTitle, hideCloseBtn} = props
    return (
        <Modal isOpen={modal} toggle={toggle} size="lg" centered backdrop="static">
            <ModalHeader toggle={hideCloseBtn ? hideCloseBtn : toggle} className='border-0'>{modalTitle}</ModalHeader>
            <ModalBody>
                {children}
            </ModalBody>
        </Modal>
    )
}

export default CustomModal;