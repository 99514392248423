import Admin from "../components/admin/admin.component";
import Albums from "../components/albums/albums.component";
import Collections from "../components/collections/collections.component";
import ConcertTableGrade from "../components/concert-table-grade.component.jsx/concert-table-grade.component";
import Concerts from "../components/concerts/concerts.component";
import DonationPurpose from "../components/donations-purpose/donation-purpose.component";
import Packages from "../components/packages/packages.component";
import Products from "../components/products/products.component";
import ReserveConcert from "../components/reserve-concert/reserve-concert.component";
import Seat from "../components/seats/seat.component";
import TableGrades from "../components/table-grade/table-grade.component";
import Tags from "../components/tags/tags.component";
import Users from "../components/users/users.component";
export const routes = [
    { path: `collections/*`, Component: Collections },
    { path: `collections/:collection/:collectionTitle/albums`, Component: Albums },
    { path: `collections/:collection/:collectionTitle/albums/:album/:albumTitle`, Component: Products },
    { path: `concerts/:concert/:concertTitle/tb-grades`, Component: ConcertTableGrade },
    { path: `concerts/:concert/:concertTitle/tb_grades/:tableGrade/:tableGradeTitle/:tableNumber`, Component: ReserveConcert },
    { path: `table-grades/:tableGrade/:tableGradeTitle/seats`, Component: Seat },
    { path: `users`, Component: Users },
    { path: `admins`, Component: Admin },
    { path: `packages`, Component: Packages },
    { path: `concerts`, Component: Concerts },
    { path: `tags`, Component: Tags },
    { path: `table-grades`, Component: TableGrades },
    { path: `donation-purposes`, Component: DonationPurpose },

]
