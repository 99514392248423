import { Spinner } from "reactstrap";
import "./package-list.styles.scss";
import { Plus } from "react-feather";
import PackageCard from "../package-card/package-card.component";
import { useSelector } from "react-redux";
import { selectCurrentAdmin } from "../../../store/admin/admin.selector";
import CustomSearchBar from "../../custom-search-bar/custom-search-bar.component";
import { useState } from "react";

const PackageList = ({
  toggle,
  allPackages,
  setPackageFormData,
  isLoading,
}) => {
  const currentAdmin = useSelector(selectCurrentAdmin);
  const [query, setQuery] = useState("")

  const handleOpenPackageFormModal = () => {
    if((currentAdmin.Role === 'Editor' || currentAdmin.Role === 'Admin')) {
      setPackageFormData({});
      toggle();
    }
  };

  return (
    <>
      <div className="package-list-view">
        {
          // currentAdmin.Role === "Editor" || currentAdmin.Role === "Admin") && (
          // <div className="row">
          //   <div
          //     className="add-package-list-box main-list-hover"
          //     onClick={handleOpenPackageFormModal}
          //   >
          //     <Plus />
          //     <a>Add Package</a>
          //   </div>
          // </div>
        }
        <CustomSearchBar placeholder="Search your package title here..." setQuery={setQuery} />
        <div className="row mt-2">
          <div className="col-md-3">
            <span className="product-list-table-head-text">Title</span>
          </div>
          <div className="col-md-3">
            <span className="product-list-table-head-text">Description</span>
          </div>
          <div className="col-md-2">
            <span className="product-list-table-head-text">Duration</span>
          </div>
          <div className="col-md-2">
            <span className="product-list-table-head-text">Payable</span>
          </div>
          <div className="col-md-2">Action</div>
        </div>
        {isLoading ? (
          <div className="text-center">
            <Spinner />
          </div>
        ) : allPackages && allPackages.length > 0 ? (
          allPackages?.filter(pack => {
            if (query === '') {
              return pack;
            } else if (pack.name.toLowerCase().includes(query.toLowerCase())) {
              return pack;
            }
          }).map((packageObj) => (
            <PackageCard
              key={packageObj.id}
              packageObj={packageObj}
              setPackageFormData={setPackageFormData}
              toggle={toggle}
            />
          ))
        ) : (
          <div className="row">
            <div className="col-12 text-center  mt-2">
              <p className="primary">Doesn't have packages!</p>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default PackageList;
