import {
  createAuthUserWithEmailAndPassword,
  createUserDocumentFromAuth,
  deleteAdminService,
  getAllAdmins,
  updateAdminService,
} from "../../services/auth-firebase.services";
import { getUniqueArr } from "../../utils/helper.utils";
import { createAction } from "../../utils/reducer.utils";
import { ADMIN_ACTION_TYPES } from "./admin.types";

export const setCurrentAdmin = (admin) =>
  createAction(ADMIN_ACTION_TYPES.SET_CURRENT_ADMIN, admin);

export const setLastAdminDoc = (doc) =>
  createAction(ADMIN_ACTION_TYPES.SET_LAST_ADMIN_DOC, doc);

export const fetchAdminsStart = () =>
  createAction(ADMIN_ACTION_TYPES.FETCH_ADMINS_START);

export const fetchAdminsSuccess = (adminsArray) =>
  createAction(ADMIN_ACTION_TYPES.FETCH_ADMINS_SUCCESS, adminsArray);

export const fetchAdminsFailed = (error) =>
  createAction(ADMIN_ACTION_TYPES.FETCH_ADMINS_FAILED, error);

export const fetchAdminsAsync = () => async (dispatch) => {
   dispatch(fetchAdminsStart());

  try {
    const result = await getAllAdmins();
    dispatch(fetchAdminsSuccess(result.admins));
    dispatch(setLastAdminDoc(result.lastDoc));
    
  } catch (error) {
    dispatch(fetchAdminsFailed(error));
  }
};

export const searchAdminsAsync = (name = '') => async (dispatch) => {
  try {
    const result = await getAllAdmins(null, name);
    dispatch(fetchAdminsSuccess(result.admins));
    dispatch(setLastAdminDoc(result.lastDoc));
    
  } catch (error) {
    dispatch(fetchAdminsFailed(error));
  }
};

export const loadMoreAdminsDB = (lastDoc = null, allAdmins) => async (dispatch) => {
    try {
      const result = await getAllAdmins(lastDoc);
      
      let newResult  = getUniqueArr([...result.admins, ...allAdmins ]);
      console.log("lastDoc", result.lastDoc);
      dispatch(fetchAdminsSuccess(newResult));
      dispatch(setLastAdminDoc(result.lastDoc));
      
    } catch (error) {
        console.log("ERROR", error);
    }
  };

export const addAdmin = async (admin, adminsArray) => {
  const { user } = await createAuthUserWithEmailAndPassword(
    admin.Email,
    admin.Password
  );
  const { Password, ConfirmPassword, ...rest } = admin;
  const res = await createUserDocumentFromAuth(user, { ...rest });

  createOrUpdateAdmin({ id: res, ...rest }, adminsArray);
};

const createOrUpdateAdmin = async (admin, adminsArray) => {
  const checkAdminExists = adminsArray.find((collec) => admin.id === collec.id);
  if (checkAdminExists) {
    const index = adminsArray.findIndex((collect) => collect.id === admin.id);
    adminsArray[index] = admin;
  } else {
    return adminsArray.unshift(admin);
  }
};

// Update Admin
export const updateAdmin = async (admin, adminArray, adminId) => {
  try {
    await updateAdminService(
      { Status: admin.Status, Role: admin.Role },
      adminId
    );
    const { Password, ConfirmPassword, ...rest } = admin;
    createOrUpdateAdmin(rest, adminArray);
  } catch (error) {
    console.log(error);
  }
};

// Delete Admin
export const deleteAdmin = async (adminId) => {
  await deleteAdminService(adminId);
};
