import { createAlbumProductService, deleteAlbumProductService, getAlbumProducts, updateAlbumProductService } from "../../services/products-firebase.services";
import { createAction } from "../../utils/reducer.utils";
import { PRODUCT_ACTION_TYPES } from "./product.types";

export const fetchAlbumProductsStart = () =>
    createAction(PRODUCT_ACTION_TYPES.FETCH_PRODUCTS_START);


export const fetchAlbumProductsSuccess = (productsArray) => 
    createAction(PRODUCT_ACTION_TYPES.FETCH_PRODUCTS_SUCCESS, productsArray);



export const fetchAlbumProductsFailed = (error) =>
    createAction(PRODUCT_ACTION_TYPES.FETCH_PRODUCTS_FAILED, error);

export const fetchAlbumProductsAsync = (albumId, allAlbumProducts) => async (dispatch) => {
    dispatch(fetchAlbumProductsStart());

    try {
        const productsArray = await getAlbumProducts(albumId);
        const fetchedProducts =  formatFetchedProducts(productsArray, albumId);
        const allProducts =  allAlbumProducts ? allAlbumProducts : formatFetchedProducts(allAlbumProducts, albumId);
        const desObj = { ...fetchedProducts, ...allProducts};
        dispatch(fetchAlbumProductsSuccess(desObj));
    } catch(error) {
        dispatch(fetchAlbumProductsFailed(error))
    }
}


export const formatFetchedProducts = (albumProducts, albumId) => {
    return albumProducts.length > 0 ? albumProducts.reduce((acc, product) => {
        acc[product.AlbumID] = acc[product.AlbumID] ?? [];
        acc[product.AlbumID].push(product);
        return acc;
      }, {}) : { [albumId]: [] };
}


export const addAlbumProduct =  async (product, productsArray) => {
    try {
        const res = await createAlbumProductService(product);
        createOrUpdateAlbumProduct(res, productsArray);
    } catch(error) {
        console.log(error);
    }
}

const createOrUpdateAlbumProduct = (product, productsArray) => {
    const checkAlbumProductExists = productsArray.length > 0  ? productsArray.find(prod => prod.id === product.id) : false;
    if(checkAlbumProductExists) {
        const index = productsArray.findIndex(prod => prod.id === product.id)
        productsArray[index] = product;
    } else  {
        productsArray.unshift(product);
    }
}

// Update Product 
export const updateAlbumProduct =  async (product, productsArray, productId) => {
    try {
        const res = await updateAlbumProductService(product, productId);
        createOrUpdateAlbumProduct(res, productsArray);
    } catch(error) {
        console.log(error);
    }
}

// Delete Product
export const deleteAlbumProduct = async (productId) => {
    try {
        await deleteAlbumProductService(productId)
    } catch(error) {
        console.log(error);
    }
}