import { combineReducers } from "redux";
import { collectionReducer } from "./collections/collection.reducer";
import { adminReducer } from "./admin/admin.reducer";
import { albumReducer } from "./album/album.reducer";
import { productReducer } from "./product/product.reducer";
import { dashboardReducer } from "./dashboard/dashboard.reducer";
import { tagReducer } from "./tags/tag.reducer";
import { packageReducer } from "./packages/package.reducer";
import { donationPurposeReducer } from "./donation-purposes/donation-purpose.reducer";
import { concertsReducer } from "./concerts/concerts.reducer";
import { reservationReducer } from "./reserve-concert/reserve-concert.reducer";
import { tableGradeReducer } from "./table-grade/table-grade.reducer";
import { seatReducer } from "./seat/seat.reducer";
import { userReducer } from "./users/user.reducer";
import { hallReducer } from "./hall/hall.reducer";


export const rootReducer = combineReducers({
    collections: collectionReducer,
    admin: adminReducer,
    albums: albumReducer,
    products: productReducer,
    dashboard: dashboardReducer,
    tags: tagReducer,
    donationPurposes: donationPurposeReducer,
    packages: packageReducer,
    concerts: concertsReducer,
    reservations: reservationReducer,
    tableGrades: tableGradeReducer,
    seats: seatReducer,
    halls: hallReducer,
    users: userReducer,
})