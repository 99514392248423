import { getUserLibraries } from "../../../services/users-firebase.services";
import photoPlaceholder from "../../../assets/images/lightgallry/01.jpg";
import { useEffect, useState } from 'react';
import CustomDataLoader from "../../custom-data-loader/custom-data-loader.component";
import { FIREBASE_STORAGE_BASE_URL } from "../../../constant/constant";
import { formatBytes } from "../../../utils/helper.utils";
import '../users.styles.scss';
const UserLibraries = ({userId}) => {
    console.log("USer ID", userId);
    const [isDataLoading, setIsDataLoading] = useState(true);
    const [userLibraries, setUserLibraries] = useState([]);

    useEffect(() => {
        setIsDataLoading(true)
        const getUserLib = async (userId) => {
            await getUserLibraries(userId).then((data)=> {
                setUserLibraries(data);
                setIsDataLoading(false)
            }, (err) => {
                console.log("ERROR", err);
                setIsDataLoading(false)
            })
        }

        getUserLib(userId)
    }, []);

    return(
        isDataLoading ? <CustomDataLoader /> : (
            <table className="table align-baseline">
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Thumbnail</th>
                        <th>Title</th>
                        <th>Description</th>
                        <th>Type</th>
                        <th>Size</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        userLibraries.length > 0 ? userLibraries.map((lib, index) => (
                            <tr key={index + 1}>
                                <td>{index + 1}</td>
                                <td ><img className="user-profile" src={lib.Thumbnail ? FIREBASE_STORAGE_BASE_URL + lib.Thumbnail : photoPlaceholder } alt="" crossOrigin="anonymous"/></td>
                                <td>{lib.Title}</td>
                                <td>{lib.Description}</td>
                                <td>{lib.Type}</td>
                                <td>{lib.Media_Size ? formatBytes(lib.Media_Size) : ''}</td>
                            </tr>
                        )) : (
                            <tr className="text-center mt-2">
                                <td colSpan={6}>
                                <p className="primary">Doesn't have libraries!</p>
                                </td>
                            </tr>
                        )
                    }
                </tbody>
            </table>
        )
    )
}

export default UserLibraries;