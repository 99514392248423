import { createPackage, deletePackageService, getAllPackages, updatePackageService } from "../../services/packages-firebase.services";
import { createAction } from "../../utils/reducer.utils";
import { PACKAGE_ACTION_TYPES } from "./package.types";

export const fetchPackagesStart = () =>
    createAction(PACKAGE_ACTION_TYPES.FETCH_PACKAGES_START);


export const fetchPackagesSuccess = (packagesArray) =>
    createAction(PACKAGE_ACTION_TYPES.FETCH_PACKAGES_SUCCESS, packagesArray);


export const fetchPackagesFailed = (error) =>
    createAction(PACKAGE_ACTION_TYPES.FETCH_PACKAGES_FAILED, error);

export const fetchPackagesAsync = () => async (dispatch) => {
    dispatch(fetchPackagesStart());

    try {
        const packagesArray = await getAllPackages();
        console.log("packagesArray",packagesArray);
        dispatch(fetchPackagesSuccess(packagesArray));
    } catch(error) {
        dispatch(fetchPackagesFailed(error))
    }
}

export const addPackage =  async (packageObj, packagesArray) => {
    try {
        const res = await createPackage(packageObj);
       createOrUpdatePackage(res, packagesArray);
    } catch(error) {
        console.log(error);
    }
}

const createOrUpdatePackage = async (packageObj, packagesArray) => {
    const checkPackageExists = packagesArray.find(pack => pack.id === packageObj.id)
    if(checkPackageExists) {
        const index = packagesArray.findIndex(pack => pack.id === packageObj.id)
        packagesArray[index] = packageObj;
    } else  {
        return packagesArray.unshift(packageObj);
    }
}

// Update Package 
export const updatePackage =  async (packageObj, packagesArray, packageId) => {
    try {
        const res = await updatePackageService(packageObj, packageId);
        createOrUpdatePackage(res, packagesArray);
    } catch(error) {
        console.log(error);
    }
}

// Delete Package
export const deletePackage = async (packageId) => {
    try {
        await deletePackageService(packageId)
    } catch(error) {
        console.log(error);
    }
}