import { useParams } from "react-router-dom";
import { Card, CardBody,CardHeader, Row, Col, Container } from 'reactstrap';
import Breadcrumbs from "../../layout/breadcrumb";
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CustomBackButton from "../custom-back-button/custom-back-button.component";
import { selectSeatIsLoading, selectSeatsMap } from "../../store/seat/seat.selector";
import { fetchSeatsAsync } from "../../store/seat/seat.action";
import SeatList from "./seat-list/seat-list.component";

const Seat = () => {
    const { tableGrade, tableGradeTitle } = useParams();

  const dispatch = useDispatch();

  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);

  const isLoading = useSelector(selectSeatIsLoading);
  const allSeats = useSelector(selectSeatsMap);

  useEffect(() => {
    if (
      !allSeats[tableGrade] ||
      allSeats[tableGrade].length === 0
    )
      dispatch(fetchSeatsAsync(tableGrade, allSeats));
  }, []);
    return (
        <>
        <Breadcrumbs parent={tableGradeTitle ? tableGradeTitle : 'Seat'} title="Table Grade"  />
            <Container fluid={true}>
                <Row>
                <Col sm="12">
                    <Card className="main-content-card">
                    <CardHeader className="bg-transparent d-flex justify-content-between">
                        <h5 className="d-flex gap-1"> <CustomBackButton /> {tableGradeTitle ? tableGradeTitle + ' > ' : ''} Seat </h5>
                        <h5>Total Seats : { allSeats[tableGrade] && allSeats[tableGrade].length > 0 ? allSeats[tableGrade].length : 0 }</h5>
                    </CardHeader>
                    <CardBody className="main-content-card-body">
                        <Row>
                            <SeatList toggle={toggle} modal={modal} tableGradeId={tableGrade} allSeats={allSeats[tableGrade]} isLoading={isLoading} />
                        </Row>
                    </CardBody>
                    </Card>
                </Col>
                </Row>
            </Container>
            
        </>
    )
}

export default Seat;