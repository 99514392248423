import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { storage } from "../../config/firebase.config";
import { useState, useEffect } from "react";
import "./file-input.styles.scss";
import { Film, Music, Upload } from "react-feather";
import { FIREBASE_STORAGE_BASE_URL } from "../../constant/constant";
import { formatBytes } from "../../utils/helper.utils";

export const FileInput = ({
  uploadDirectory,
  setIsUploading,
  setPhotoUrl,
  photoUrl = "",
  fileType = "",
  name,
  placeholder,
  setProductFile,
  productFile = {},
}) => {
  const [percent, setPercent] = useState(0); // Handle file upload event and update state
  const [showProgress, setShowProgress] = useState(false); // Handle progress
  const [fileUrl, setFileUrl] = useState(null);
  const [selectedFileDetails, setselectedFileDetails] = useState({});
  const [isLoading, setIsLoading] = useState(true); // Handle

  useEffect(() => {
    if( photoUrl !== '' && photoUrl !== null ) {
      setFileUrl(
        photoUrl.includes(FIREBASE_STORAGE_BASE_URL) ? photoUrl : FIREBASE_STORAGE_BASE_URL + photoUrl
      );
    }
    if( Object.keys(productFile).length > 0 ) {
      setselectedFileDetails({ ...productFile });
    } 
    setIsLoading(false);
  }, [photoUrl, productFile]);

  function handleChange(event) {
    handleUpload(event.target.files[0]);
  }

  function handleUpload(file) {
    if (file) {
      setFileUrl(null);
      setselectedFileDetails({...selectedFileDetails, name: file.name, size: file.size, type: file.type });
      setShowProgress(true);
      setIsUploading(true);
      const storageRef = ref(storage, `/${uploadDirectory}/${file.name}`); // progress can be paused and resumed. It also exposes progress updates. // Receives the storage reference and the file to upload.
      const uploadTask = uploadBytesResumable(storageRef, file);
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const percent = Math.round(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          ); // update progress
          setPercent(percent);
        },
        (err) => {
          setIsUploading(true);
          console.log(err);
        },
        () => {
          // download url
          getDownloadURL(uploadTask.snapshot.ref).then((url) => {
            setShowProgress(false);
            setFileUrl(url);
            fileType === "video-audio"
              ? setProductFile({
                  url: url.replace(FIREBASE_STORAGE_BASE_URL, ""),
                  size: file.size,
                  name: file.name,
                  type: file.type.substr(0, 5),
                })
              : setPhotoUrl(url.replace(FIREBASE_STORAGE_BASE_URL, ""));
            setPercent(0);
            setIsUploading(false);
          });
        }
      );
    }
  }

  return (
    <>
      <input
        type="file"
        id={name}
        className="d-none"
        onChange={handleChange}
        accept={fileType === "video-audio" ? "audio/*,video/*" : "image/*"}
      />
      {
        !isLoading && (
          fileType === "video-audio" ? (
            <label className="w-100 photo-input-label" htmlFor={name}>
              <label className="primary-color">Upload video/audio media</label>
              {showProgress || (Object.keys(selectedFileDetails).length > 0) ? (
                <div className="d-flex justify-content-between w-100 uploaded-file">
                  <div className="d-flex align-items-end gap-1">
                    {selectedFileDetails.type.includes("audio") ? (
                      <Music />
                    ) : (
                      <Film />
                    )}
                    {selectedFileDetails.name.substr(0, 20)}
                  </div>
                  <div>{formatBytes(selectedFileDetails.size)}</div>
                </div>
              ) : (
                <div className="btn btn-outline-primary w-100">
                  <Upload width={20} /> Upload a Media{" "}
                </div>
              )}
    
              {showProgress ? (
                <>
                  <div className="w-uploading-info d-flex justify-content-between gap-1 align-items-center">
                    <div className="progressbar">
                      <div
                        className="child-progressbar"
                        style={{ width: `${percent}%` }}
                      ></div>
                    </div>
    
                    <span className="percentage">{percent}%</span>
                  </div>
                </>
              ) : null}
            </label>
          ) : (
            <label
              className="d-flex flex-column align-items-center photo-input-label"
              htmlFor={name}
            >
              <div className="w-avatar-container">
                {fileUrl && fileUrl != null ? (
                  <img src={fileUrl} className="" alt="" crossOrigin='anonymous' />
                ) : (
                  ""
                )}
              </div>
              {showProgress ? (
                <div className="loader-avatar-image">{percent}%</div>
              ) : (
                ""
              )}
              <span className="primary-color">{placeholder}</span>
            </label>
          )
        ) 
      }
    </>
  );
};
