import {
    setDoc,
    collection,
    addDoc,
    doc,
    where,
    updateDoc,
    query,
    orderBy,
    getDocs,
} from "firebase/firestore";
import { db } from "../config/firebase.config";
import { removeUndefinedNull } from "../utils/helper.utils";

export const createAlbumProductService = async (docData) => {
    docData = removeUndefinedNull(docData);
    const res = await addDoc(collection(db, "Products"), docData);
    console.log("Doc Data", res.id);
    if (res.id) {
        return {
            id: res.id,
            ...docData
        }
    }
}

export const updateAlbumProductService = async (docData, productId) => {
    docData = removeUndefinedNull(docData);
    const { id, ...rest } = docData;
    const updatedDoc = { ...rest, Updated_at: new Date() };
    await updateDoc(doc(db, "Products", productId), updatedDoc)

    return await {
        ...updatedDoc,
        id: productId
    }
}

export const deleteAlbumProductService = async (productId) => {
    await updateDoc(doc(db, "Products", productId), { Status: 0, Updated_at: new Date() });
    
}

export const getAlbumProducts = async (albumId) => {
    const ablumProductsRef = collection(db, 'Products');
    const q = query(ablumProductsRef, where("AlbumID", '==', albumId), where("Status", '==', 1), orderBy("Created_at", "desc"));
    const querySnapshot = await getDocs(q);
    return querySnapshot.docs.map((docSnapshot) =>  ({
        id: docSnapshot.id,
        ...docSnapshot.data()
    }))
   
}



