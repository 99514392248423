import React, { useEffect } from "react";
import Breadcrumb from "../../layout/breadcrumb";
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Spinner,
} from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import "./table-grade.styles.scss";
import AddCollectionAlbumBTN from "../add-collection-album-btn/add-collection-album-btn.component";
import { useState } from "react";
import CustomModal from "../custom-modal/custom-modal.component";

import { selectCurrentAdmin } from "../../store/admin/admin.selector";
import CustomBackButton from "../custom-back-button/custom-back-button.component";
import CustomSearchBar from "../custom-search-bar/custom-search-bar.component";
import { selectTableGrades, selectTableGradesIsLoading } from "../../store/table-grade/table-grade.selector";
import { fetchTableGradesAsync } from "../../store/table-grade/table-grade.action";
import TableGradeForm from "./table-grade-form/table-grade-form.component";
import TableGradeCard from "./table-grade-card/table-grade-card.component";

const TableGrades = () => {
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);

  const dispatch = useDispatch();

  const isLoading = useSelector(selectTableGradesIsLoading);
  const allTableGrades = useSelector(selectTableGrades);

  const [tableGradeObj, setTableGradeObj] = useState({});
  const setTableGradeFormData = (tgObj) => setTableGradeObj(tgObj);

  const currentAdmin = useSelector(selectCurrentAdmin);

  const [query, setQuery] = useState("")

  useEffect(() => {
    if (allTableGrades.length === 0) dispatch(fetchTableGradesAsync());
  }, []);

  return (
    <>
      <Breadcrumb parent="Dashboard" title="Table Grade" />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card className="main-content-card">
              <CardHeader className="bg-transparent  d-flex justify-content-between">
                <h5 className="d-flex gap-1"> <CustomBackButton /> List Of Table Grades </h5>
                <h5 >Total Table Grades: {allTableGrades && allTableGrades.length > 0 ? allTableGrades.length : 0} </h5>
              </CardHeader>
              <CardBody className="main-content-card-body">
                <Row>
                  {isLoading ? (
                    <div className="text-center">
                      <Spinner />
                    </div>
                  ) : (
                    <>
                      <CustomSearchBar placeholder="Search your table grade here..." setQuery={setQuery} />
                      {(currentAdmin.Role === "Editor" ||
                        currentAdmin.Role === "Admin") && (
                        <AddCollectionAlbumBTN
                          title={"Add Table Grade"}
                          toggle={() => {
                            setTableGradeFormData({});
                            toggle();
                          }}
                          className={
                            allTableGrades.length === 0
                              ? "empty-list main-list-hover"
                              : "main-list-hover"
                          }
                          setTableGradeFormData={setTableGradeFormData}
                        />
                      )}

                      
                      <CustomModal
                        toggle={toggle}
                        modal={modal}
                        modalTitle={"Save Table Grade"}
                      >
                        <TableGradeForm toggle={toggle} tableGradeObj={tableGradeObj} />
                      </CustomModal>

                      {
                      allTableGrades?.filter(tableGrade => {
                      if (query === '') {
                        return tableGrade;
                      } else if (tableGrade.Grade.toLowerCase().includes(query.toLowerCase())) {
                        return tableGrade;
                      }
                    }).map((tableGrade) => (
                        <TableGradeCard
                          key={tableGrade.id}
                          tableGrade={tableGrade}
                          setTableGradeFormData={setTableGradeFormData}
                          toggle={toggle}
                        />
                      ))}
                    </>
                  )}
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default TableGrades;
