import { useState } from "react";

import { Button, Spinner } from "reactstrap";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { createAuthUserWithEmailAndPassword, createUserDocumentFromAuth } from "../../../services/auth-firebase.services";
import FormInput from "../../form-input/form-input.component";

const defaultFormFields = {
  name: "",
  email: "",
  password: "",
  confirmPassword: "",
};

const SignUpForm = () => {
  const [formFields, setFormFields] = useState(defaultFormFields);
  const { name, email, password, confirmPassword } = formFields;

  const [savingForm, setSavingForm] = useState(false);
  const navigate = useNavigate();

  const resetFormFields = () => {
    setFormFields(defaultFormFields);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (password !== confirmPassword) {
      toast.error("Passwords do not match");
      return;
    }
    setSavingForm(true);
    try {
      const { user } = await createAuthUserWithEmailAndPassword(
        email,
        password
      );

      await createUserDocumentFromAuth(user, { name });
      toast.success(
        "Your account has been created successfully. wait to confirm your account by admin"
      );
      navigate("/authentication/login");
      resetFormFields();
    } catch (err) {
      if (err.code === "auth/email-already-in-use") {
        toast.error("Can not create user, email already in use");
      } else {
        toast.error("User creation encountered an error");
        console.log("User creation encountered an error", err);
      }
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormFields({
      ...formFields,
      [name]: value,
    });
  };

  return (
    <form className="theme-form" onSubmit={handleSubmit}>
      <h4>Sign Up Bakhtar Dashboard</h4>
      <div className="form-group">
        <label className="col-form-label">Name</label>
        <FormInput
          type="text"
          id="name"
          onChange={handleChange}
          name="name"
          placeholder="ABCDEF"
          value={name}
          required
        />
      </div>
      <div className="form-group">
        <label className="col-form-label">Email Address</label>
        <FormInput
          type="email"
          id="email"
          onChange={handleChange}
          name="email"
          placeholder="Test@gmail.com"
          value={email}
          required
        />
      </div>
      <div className="form-group">
        <label className="col-form-label">Password</label>
        <FormInput
          placeholder="*********"
          type="password"
          id="password"
          errorMessage="Password should be 8-20 characters and include at least 1 letter, 1 number and 1 special character!"
          label="Password"
          pattern={`^(?=.*[0-9])(?=.*[a-zA-Z])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,20}$`}
          onChange={handleChange}
          name="password"
          value={password}
          required
        />
      </div>
      <div className="form-group">
        <label className="col-form-label">Confirm Password</label>
        <FormInput
          name="confirmPassword"
          type="password"
          placeholder="Confirm Password"
          errorMessage="Passwords don't match!"
          label="Confirm Password"
          pattern={password}
          required
          value={confirmPassword}
          onChange={handleChange}
        />
      </div>
      <div className="form-group mb-0">
        <div className="text-end mt-3">
          <button className="btn btn-primary btn-block w-100" type="submit">
            {savingForm ? <Spinner /> : "Sign Up"}
          </button>
        </div>
        <div className="text-end mt-3">
          <Link className="link" to={"/authentication/login"}>
            Already have an account ?
          </Link>
        </div>
      </div>
    </form>
  );
};

export default SignUpForm;
