import { useState, useEffect, useCallback } from "react";
import { Form, Button, Spinner, Label } from "reactstrap";
import "./index.scss";
import FormInput from "../../form-input/form-input.component";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import SweetAlert from "sweetalert2";
import { selectCurrentAdmin } from "../../../store/admin/admin.selector";
import { selectConcerts } from "../../../store/concerts/concerts.selector";
import {
  addConcerts,
  deleteConcerts,
  updateConcerts,
} from "../../../store/concerts/concerts.action";
import {
  getAllHalls,
  getAllTableGrades,
} from "../../../services/concerts-firebase.services";
import { convertToCustomFormat, convertToInputFormat, formatTimestap } from "../../../utils/helper.utils";
import { selectTableGrades } from "../../../store/table-grade/table-grade.selector";
import { selectHalls } from "../../../store/hall/hall.selector";

const ConcertsForm = ({ toggle, collectionObj }) => {
  const [values, setValues] = useState({
    Title: "",
    Description: "",
    Photo: "",
    Status: 1,
    Table_Grade: [],
    Created_at: new Date(),
    Updated_at: new Date(),
    C_E_Datetime: "",
    C_S_Datetime: "",
  });
  const [isUploading, setIsUploading] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [photoUrl, setPhotoUrl] = useState(null);
  const [collectionId, setCollectionId] = useState(null);
  const allConcerts = useSelector(selectConcerts);
  const currentAdmin = useSelector(selectCurrentAdmin);
  const [checkedItem, setChecked] = useState([]);
  // const [selectedHall, setSelectedHall] = useState({});
  const [hallInputs, setHallInputs] = useState({});
  const allTableGrades = useSelector(selectTableGrades);
  const [tableGrades, setTableGrades] = useState(allTableGrades);
  // const allHalls = useSelector(selectHalls);
  // const [halls, setHalls] = useState(allHalls);

  const setTableGradesData = async () => {
    if (
      tableGrades && tableGrades.length > 0 &&
      collectionObj &&
      collectionObj.Table_Grade &&
      collectionObj.Table_Grade.length > 0
    ) {
      collectionObj.Table_Grade.forEach((item) => {
        let currentItem = tableGrades.find((tblGrade) => tblGrade.id === item.Grade_ID);
        if (currentItem && Object.keys(currentItem).length > 0) {
          handleCheckBox(true, currentItem, true);
          handleHallNameChange(currentItem?.id, item.Hall_Name)
        }
      });
    }

  };

  // const setHallsData = useCallback(async () => {
  //   if (
  //     collectionObj &&
  //     collectionObj.Table_Grade &&
  //     collectionObj.Table_Grade.length > 0
  //   ) { 
  //     let currentHall = halls.find((hall) => hall.id === collectionObj.Table_Grade[0].Hall_Name)
  //     setSelectedHall({
  //       Hall_name: currentHall.Hall_name,
  //       id: currentHall.id,
  //     });
  //   }
  // }, []);



  useEffect(() => {
    if (Object.keys(collectionObj).length > 0) {
      const { Updated_at, id, ...rest } = collectionObj;
      console.log("collectionObj", collectionObj)
      setValues({ 
        ...values, 
        ...rest,
        C_E_Datetime: convertToInputFormat(collectionObj.C_E_Datetime), 
        C_S_Datetime: convertToInputFormat(collectionObj.C_S_Datetime) 
      
      });


      setCollectionId(id);
      setPhotoUrl(collectionObj.Photo ? collectionObj.Photo : null);
    }
    setTableGradesData();
  }, [collectionObj]);

  // useEffect(() => {
  //   setHallsData();
  // }, []);

  // format Table grade function 
  const formatTableGrade = () => {
    const uniqueObjects = Array.from(new Set(checkedItem.map(obj => obj.id))).map(id => {
      return checkedItem.find(obj => obj.id === id);
    });
    return uniqueObjects.map((item) => ({
      Grade_ID: item.id,
      Capacity: parseInt(item.Capacity),
      Hall_Name: hallInputs[item.id],
    }));
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    if (isUploading) return;
    if (checkedItem.length === 0) {
      toast.error("Please select at least on table grade!")
      return;
    }
    setIsSaving(true);
    values.Photo = photoUrl ? photoUrl : null;

    
    values.Table_Grade = formatTableGrade()
    // console.log("VAlues", values)

    collectionId
      ? updateConcerts(
          { ...values, id: collectionId },
          allConcerts,
          collectionId
        ).then(() => {
          toast.success("Your Concert has been saved.");
          setIsSaving(false);
          toggle();
        })
      : addConcerts(values, allConcerts).then(() => {
          toast.success("Your Concert has been saved.");
          setIsSaving(false);
          toggle();
        });
  };

  const onChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const handleHallNameChange = (itemId, hall) => {
    setHallInputs((prevHallInputs) => ({
      ...prevHallInputs,
      [itemId]: hall,
    }));
  };

  // Handle Delete Collection
  const handleDeleteCollection = () => {
    SweetAlert.fire({
      title: "Are you sure?",
      text: "Once deleted, it will be hard to recover it again.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Ok",
      cancelButtonText: "cancel",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        deleteConcerts(collectionId);
        SweetAlert.fire(
          "Deleted!",
          "Your concerts has been deleted.",
          "success"
        );
        const index = allConcerts.findIndex((alb) => alb.id === collectionId);
        allConcerts.splice(index, 1);
        toggle();
      } else {
        SweetAlert.fire("Your concerts is safe!");
      }
    });
  };

  const handleCheckBox = (value, item, isEdit = false) => {
    setChecked((prevSelectedItems) => {
      
      if (value) {
        return [...prevSelectedItems, item];
      } else {
        return prevSelectedItems.filter(
          (selectedItem) => selectedItem.id !== item.id
        );
      }
    });
  };

  const isItemChecked = (item) => {
    return checkedItem?.some(
      (selectedItem) => selectedItem.id === item.id
    );
  };

  return (
    <Form className="form-bookmark needs-validation p-3" onSubmit={handleSubmit}>
      <div className="form-row row">
        <div className="col-8">
          <FormInput
            name="Title"
            type="text"
            placeholder="Title"
            errorMessage="Title should be more than 3 characters!"
            label="Title"
            min={3}
            required
            value={values["Title"]}
            onChange={onChange}
          />

          <div className="mt-2">
            <FormInput
              name="C_S_Datetime"
              type="datetime-local"
              placeholder="Concert Start Date"
              errorMessage="Concert Start Date is required!"
              label="Concert Start Date"
              required
              value={values["C_S_Datetime"]}
              onChange={onChange}
            />
          </div>

          <div className="mt-2">
            <FormInput
              name="C_E_Datetime"
              type="datetime-local"
              placeholder="Concert End Date"
              errorMessage="Concert End Date is required!"
              label="Concert End Date"
              required
              value={values["C_E_Datetime"]}
              onChange={onChange}
            />
          </div>
        </div>
        <div className="col-4">
          <FormInput
            name="photo"
            type="file"
            placeholder="Upload a photo"
            label="Collection photo"
            uploadDirectory="collections"
            value={values["Photo"]}
            setValues={setValues}
            values={values}
            setIsUploading={setIsUploading}
            setPhotoUrl={setPhotoUrl}
            photoUrl={photoUrl}
            onChange={onChange}
          />
        </div>
      </div>
      <div className="form-row row checkbox_list">
        <h6>Table Grades</h6>
        {tableGrades?.map((item, index) => (
          <div className="col-6 mb-3" key={index}>
            <Label>
              <input
                id={`checkbox-${item.id}`}
                type="checkbox"
                checked={isItemChecked(item)}
                onChange={(e) => handleCheckBox(e.target.checked, item)}
              />
              <span>{item?.Grade}</span>
            </Label>
            <Label>
              <FormInput
                type="text"
                value={hallInputs[item.id] || ""}
                placeholder="Hall Name"
                errorMessage="Please add a hall name!"
                required={isItemChecked(item)}
                disabled={!isItemChecked(item)}
                onChange={(e) => handleHallNameChange(item.id, e.target.value)}
              />
            </Label>
          </div>
        ))}
      </div>
      {/* <div className="form-row row mb-3">
        <div className="col-6 ">
          <div className="checkbox_list">
            <h4>Select Hall</h4>
            {halls?.map((item, index) => (
              <Label key={index}>
                <input
                  id={`checkbox-${index}`}
                  type="radio"
                  name="Hall_Name"
                  value={item?.id}
                  checked={selectedHall.id === item?.id}
                  onChange={(e) => setSelectedHall(item)}
                />
                <span>{item?.Hall_name}</span>
              </Label>
            ))}
          </div>
        </div>

        <div className="col-6">
          <FormInput
            name="Description"
            type="textarea"
            placeholder="Description"
            label="Description"
            value={values["Description"]}
            onChange={onChange}
          />
        </div>
      </div> */}
      <div className="col-12 mb-3">
          <FormInput
            name="Description"
            type="textarea"
            placeholder="Description"
            label="Description"
            value={values["Description"]}
            onChange={onChange}
          />
        </div>
      <div
        className={
          collectionId && currentAdmin.Role === "Admin"
            ? "d-flex justify-content-between"
            : ""
        }
      >
        {collectionId && currentAdmin.Role === "Admin" && (
          <Button color="secondary" onClick={handleDeleteCollection}>
            Delete
          </Button>
        )}
        <div
          className={
            !collectionId || currentAdmin.Role !== "Admin"
              ? "d-flex justify-content-between"
              : "d-flex gap-2"
          }
        >
          <Button color="light" onClick={toggle}>
            Cancel
          </Button>

          <Button
            color="primary"
            className="mr-1"
            disabled={isSaving ? true : false}
          >
            {isSaving ? (
              <Spinner style={{ height: "20px", width: "20px" }} />
            ) : (
              "Save"
            )}
          </Button>
        </div>
      </div>
    </Form>
  );
};

export default ConcertsForm;
