import {
  updateDoc,
  collection,
  addDoc,
  doc,
  where,
  setDoc,
  query,
  orderBy,
  getDocs,
  getDoc,
} from "firebase/firestore";
import { db } from "../config/firebase.config";
import { removeUndefinedNull } from "../utils/helper.utils";

export const createConcertReservation = async (docData) => {
  docData = removeUndefinedNull(docData);
  const res = await addDoc(collection(db, "Reserved"), { ...docData,  Price: parseInt(docData.Price) });


   // Decrease Capacity in Table_Grade of the Concert
   const concertRef = doc(db, 'Concerts', docData.Concert_ID);
   const concertSnapshot = await getDoc(concertRef);
   const concertData = concertSnapshot.data();
 
   const updatedTableGrades = concertData?.Table_Grade?.map((tableGrade) => {
     if (tableGrade.Grade_ID === docData.Grade_ID && tableGrade.Capacity > 0) {
       return {
         ...tableGrade,
         Capacity: tableGrade.Capacity - 1,
       };
     }
     return tableGrade;
   });

   console.log("updated", updatedTableGrades)
 
   await updateDoc(concertRef, { Table_Grade: updatedTableGrades });


  if (res.id) {
    return {
      id: res.id,
      ...docData,
    };
  }
};

export const updateConcertReservationService = async (docData, reservedId) => {
  docData = removeUndefinedNull(docData);
  const { id, ...rest } = docData; 
  const updatedDoc = { ...rest, Price: parseInt(docData.Price), Updated_at: new Date() };
  await updateDoc(doc(db, "Reserved", reservedId), updatedDoc);

  return await {
    ...updatedDoc,
    id: reservedId,
  };
};

export const deleteConcertReservationService = async (reservedId, reservationObj) => {
  await updateDoc(doc(db, "Reserved", reservedId), {
    Status: 0,
    Updated_at: new Date(),
  });

  // Adjust capacity in Table_Grade of the Concert
  const concertRef = doc(db, 'Concert', reservationObj.Concert_ID);
  const concertSnapshot = await getDoc(concertRef);
  const concertData = concertSnapshot.data();

  const updatedTableGrades = concertData.Table_Grade.map((tableGrade) => {
    if (tableGrade.Grade_ID === reservationObj.Grade_ID) {
      return {
        ...tableGrade,
        Capacity: tableGrade.Capacity + 1,
      };
    }
    return tableGrade;
  });

  await updateDoc(concertRef, { Table_Grade: updatedTableGrades });

};

export const getConcertReservations = async (concertId, tableGradeId) => {
  const concertReservationsRef = collection(db, "Reserved");
  const q = query(
    concertReservationsRef,
    where("Concert_ID", "==", concertId),
    where("Grade_ID", "==", tableGradeId),
    where("Status", "==", 1),
    orderBy("Created_at", "desc")
  );
  const querySnapshot = await getDocs(q);
  return querySnapshot.docs.map((docSnapshot) => ({
    id: docSnapshot.id,
    ...docSnapshot.data(),
  }));
};

// Define a function to get all available seats for a specific concert
export const getAvailableSeatsForConcert = async (concertId, gradeId) => {
  try {
    // Step 1: Retrieve the taken Seat_ID values for the specified concert
    const reservedSnapshot = await getDocs(
      query(collection(db, "Reserved"), where("Concert_ID", "==", concertId), where("Status", "==", 1), where("Grade_ID", "==", gradeId))
    );
    const takenSeatIds = reservedSnapshot.docs.map((doc) => doc.data().Seat_ID);

    // Step 2: Retrieve all seat details where Seat_ID is not in takenSeatIds
    const seatsSnapshot = await getDocs(
      query(collection(db, "Seats"), where("Grade_ID", "==", gradeId), where("Status", "==", 1)));

    const availableSeats = seatsSnapshot.docs
      .map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }))
      .filter((seat) => !takenSeatIds.includes(seat.id));

    // Step 3: Get the table grade info for each seat
    const seatsWithTableGrade = await Promise.all(
      availableSeats.map(async (seat) => {
        const tableGradeDocRef = doc(db, "Table_Grade", seat.Grade_ID);
        const tableGradeDoc = await getDoc(tableGradeDocRef);

        if (tableGradeDoc.exists()) {
          const tableGradeData = tableGradeDoc.data();
          return {
            ...seat,
            Table_Grade: {
              Grade: tableGradeData.Grade,
              Color: tableGradeData.Color,
            },
          };
        } else {
          return seat;
        }
      })
    );

    return seatsWithTableGrade;
  } catch (error) {
    console.error(error);
    throw new Error("Error fetching available seats for the concert");
  }
};
