import "./table-grade-card.styles.scss";

import { useSelector } from "react-redux";
import { selectCurrentAdmin } from "../../../store/admin/admin.selector";
import { Crosshair } from "react-feather";
import { Link } from "react-router-dom";

const TableGradeCard = ({ tableGrade, setTableGradeFormData, toggle }) => {
  const { Grade, Color, Table_Number, Price, Capacity, id } = tableGrade;
  const currentAdmin = useSelector(selectCurrentAdmin);
  const handleOpenTableGradeFormModal = () => {
    if(currentAdmin.Role === 'Editor' || currentAdmin.Role === 'Admin') {
      setTableGradeFormData(tableGrade);
      toggle();
    }
  };
  return (
    <>
      <div className="col-3 mb-3">
        <div
          className="tag-content h-100 position-relative main-list-hover"
          onClick={handleOpenTableGradeFormModal}
        >
          <div className="tag-box position-relative">
            <div className="tag-img d-flex justify-content-center align-items-center" style={{ backgroundColor: Color }}>
              <div className="text-container-box p-3 mt-2 text-center">
              <span className="tag-title mt-10">{Grade}</span>
            </div>
          </div>
          <div className="d-flex flex-column gap-2 mt-2 mb-2">
            <div >
              <h6 className="tag-title mt-10">
                Table Number: <span style={{ color: tableGrade.Color }}>{Table_Number}</span>
              </h6>
              <h6 className="tag-title mt-10">
                Capacity: <span style={{ color: tableGrade.Color }}>{Capacity}</span>
              </h6>
              <h6 className="tag-title mt-10">
                Price: <span style={{ color: tableGrade.Color }}>{Price}</span>
              </h6>
            </div>
          </div>
          </div>
          {/* <div className="link-container">
              <Link to={`/table-grades/${id}/${Grade}/seats`} onClick={(event) => event.stopPropagation()}> <Crosshair /> Seats </Link>
          </div> */}
        </div>
      </div>
    </>
  );
};

export default TableGradeCard;
