import { useState, useEffect } from "react";
import { Form, Button, Spinner } from "reactstrap";
import FormInput from "../../form-input/form-input.component";
import {
  addAlbumProduct,
  deleteAlbumProduct,
  updateAlbumProduct,
} from "../../../store/product/product.action";
import { selectAlbumProductsMap } from "../../../store/product/product.selector";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import SweetAlert from "sweetalert2";
import Select from "react-select";
import { selectTags } from "../../../store/tags/tag.selector";
import { fetchTagsAsync } from "../../../store/tags/tag.action";

import { selectCurrentAdmin } from "../../../store/admin/admin.selector";
import VideoCompression from "../video-compression.component";
import CustomModal from "../../custom-modal/custom-modal.component";
import { Film, Music, Upload } from "react-feather";
import { formatBytes } from "../../../utils/helper.utils";
import './product-form.styles.scss';

const ProductForm = ({ toggle, albumId, productObj }) => {
  const [values, setValues] = useState({
    AlbumID: albumId,
    Title: "",
    Description: "",
    Thumbnail: "",
    Tags: [],
    Media_Name: "",
    Media_Path: [],
    Media_Size: [],
    Media_Dimensions: [],
    Type: "",
    Created_at: new Date(),
    Updated_at: new Date(),
    Status: 1,
  });
  const [isUploading, setIsUploading] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [photoUrl, setPhotoUrl] = useState(null);
  const [productFile, setProductFile] = useState([]);
  const [fileSrc, setFileSrc] = useState({});
  

  const allAlbumProducts = useSelector(selectAlbumProductsMap);
  const [productId, setProductId] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const dispatch = useDispatch();
  const [tagList, setTagList] = useState([]);

  const [selectedTags, setSelectedTags] = useState([]);
  const allTags = useSelector(selectTags);

  const [uploadModal, setUploadModal] = useState(false);
  const toggleUploadModal = () => setUploadModal(!uploadModal);

  const currentAdmin = useSelector(selectCurrentAdmin);
  
  useEffect(() => {
    if (allTags && allTags.length > 0) {
      const tagListArray = [];
      allTags.forEach((tag) => {
        tagListArray.push({ value: tag.id, label: tag.Name });
      });
      setTagList(tagListArray);
    } else {
      if (allTags.length === 0) dispatch(fetchTagsAsync());
    }

    if (Object.keys(productObj).length > 0) {
      const { Updated_at, id, Media_Dimensions, Media_Size, Media_Path,  ...rest } = productObj;
      setValues({ ...values, ...rest });
      setProductId(id);
      setPhotoUrl(productObj.Thumbnail ? productObj.Thumbnail : null);
      if (productObj.Type) {
        productObj.Media_Path.forEach((file, index) => {
          setProductFile([{
            name: productObj.Media_Name,
            type: productObj.Type,
            url: productObj.Media_Path[index],
            size: productObj.Media_Size[index],
            dimensions: productObj.Media_Dimensions[index]
          }])
        })
      }
      
      //
      if (productObj.Tags && productObj.Tags.length > 0) {
        const selectedProductTags = [];
        allTags.map((tag) => {
          if (productObj.Tags.includes(tag.id)) {
            selectedProductTags.push({ value: tag.id, label: tag.Name });
          }
        });
        setSelectedTags(selectedProductTags);
      }
    }

    setIsLoading(false);
  }, [productId]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (isUploading) return;
    setIsSaving(true);
    values.Tags =
      selectedTags.length > 0 ? selectedTags.map((t) => t.value) : null;
    values.Thumbnail = photoUrl ? photoUrl : null;
    if (productFile && productFile.length > 0) {
      productFile.forEach((file, index) => {
        values.Media_Size.push(productFile[index].size);
        values.Media_Path.push(productFile[index].url);
        values.Media_Dimensions.push(productFile[index].type.includes("video") ? productFile[index].dimensions : '');
      })
      values.Media_Name = productFile.length > 0 ? productFile[0].name : '';
      values.Type = productFile.length > 0 ? (productFile[0].type.includes("video") ? "video" : "audio") : '';
    } else {
      values.Type = "";
      values.Media_Name = "";
    }

    productId !== null
      ? updateAlbumProduct(
          { ...values, id: productId },
          allAlbumProducts[albumId],
          productId
        ).then(() => {
          toast.success("Your product has been Updated.");
          setIsSaving(false);
          setProductId(null);
          toggle();
        },(err)=> { toast.error("Something went wrong, please try again"); setIsSaving(false);})
      : addAlbumProduct(
          values,
          allAlbumProducts[albumId] ? allAlbumProducts[albumId] : []
        ).then(() => {
          toast.success("Your product has been saved.");
          setIsSaving(false);
          toggle();
        }, (err)=> { toast.error("Something went wrong, please try again"); setIsSaving(false);});
  };

  // Handle Delete Product
  const handleDeleteProduct = () => {
    SweetAlert.fire({
      title: "Are you sure?",
      text: "Once deleted, it will be hard to recover it again.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Ok",
      cancelButtonText: "cancel",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        deleteAlbumProduct(productId).then(() => {
          SweetAlert.fire(
            "Deleted!",
            "Your product has been deleted.",
            "success"
          );
          const index = allAlbumProducts[albumId].findIndex(
            (prod) => prod.id === productId
          );
          allAlbumProducts[albumId].splice(index, 1);
          toggle();
        }, (err) => SweetAlert.fire(
          "Woops!",
          "Something went wrong, please try again later.",
          "error"
        ))
      } else {
        SweetAlert.fire("Your product is safe!");
      }
    });
  };

  const onChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  function handleSelectTag(data) {
    setSelectedTags(data);
  }

  const handleSelectFile = (e) => {
    const file = e.target.files[0];
    setFileSrc(file);
    toggleUploadModal();
  };

  const deleteUploadedFile = () => {
    SweetAlert.fire({
      title: "Are you sure?",
      text: "if you delete this file, you will lose all of your files.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Ok",
      cancelButtonText: "cancel",
      reverseButtons: true,
    }).then(async (result) => {
      if (result.value) {
          setProductFile([]);
      } else {
        SweetAlert.fire("Your file is safe!");
      }
    });

  }

  return (
    <>
      <Form className="form-bookmark needs-validation" onSubmit={handleSubmit}>
        {!isLoading && (
          <div className="form-row row mb-3">
            <div className="col-4 mb-3">
              <FormInput
                name="Title"
                type="text"
                placeholder="Product Title"
                errorMessage="Product Title should be more than 3 characters!"
                label="Title"
                min="3"
                required
                value={values["Title"]}
                onChange={onChange}
              />
            </div>
            <div className="col-4 mb-3">
              <label htmlFor="">Select Product Tags.</label>
              <Select
                options={tagList}
                placeholder="Select color"
                value={selectedTags}
                onChange={handleSelectTag}
                isSearchable={true}
                isMulti
              />
            </div>
            <div className="col-4 mb-3">
              <label> Upload Media File (Video / Audio )</label>
              { productFile.length > 0 ?
                <div className="d-flex justify-content-between w-100 uploaded-file">
                <div className="d-flex align-items-end gap-1" style={{ width: '90%'}}>
                  {productFile[0].type.includes("audio") ? (
                    <Music />
                  ) : (
                    <Film />
                  )}
                  <div className="w-100 overflow-hidden">{productFile[0].name}</div>
                </div>
                <div>
                  <button className="bg-transparent border-0" onClick={deleteUploadedFile} type="button">x</button>
                </div>
              </div>
              : <>
                  <label className="primary-color btn btn-outline-primary w-100 d-flex justify-content-center"  htmlFor="upload-media-file" >
                    <Upload width={20} /> Upload Media
                    
                  </label>
                  <input
                    type="file"
                    className="d-none"
                    id="upload-media-file"
                    onChange={handleSelectFile}
                    onClick={(event)=> { 
                      event.currentTarget.value = null
                    }}
                    accept="audio/*,video/*"
                  /> 
                </> 
              }
            </div>
            <div className="col-8 mb-3">
              <FormInput
                name="Description"
                type="textarea"
                rows={4}
                placeholder="Description"
                label="Description"
                value={values["Description"]}
                onChange={onChange}
              />
            </div>
            <div className="col-4 mb-3">
              <FormInput
                name="Thumbnail"
                type="file"
                placeholder="Upload a thumbnail"
                label="Product thumbnail"
                uploadDirectory="collections"
                value={values["Thumbnail"]}
                setValues={setValues}
                values={values}
                setIsUploading={setIsUploading}
                setPhotoUrl={setPhotoUrl}
                photoUrl={photoUrl}
                onChange={onChange}
              />
            </div>
          </div>
        )}
        <div
          className={
            productId && currentAdmin.Role === "Admin"
              ? "d-flex justify-content-between"
              : ""
          }
        >
          {productId && currentAdmin.Role === "Admin" && (
            <Button color="secondary" onClick={handleDeleteProduct}>
              Delete
            </Button>
          )}
          <div
            className={
              !productId || currentAdmin.Role !== "Admin"
                ? "d-flex justify-content-between"
                : "d-flex gap-2"
            }
          >
            <Button color="light" onClick={toggle}>
              Cancel
            </Button>

            <Button
              color="primary"
              className="mr-1"
              disabled={isSaving ? true : false}
            >
              {" "}
              {isSaving ? (
                <Spinner style={{ height: "20px", width: "20px" }} />
              ) : (
                "Save"
              )}{" "}
            </Button>
          </div>
        </div>
      </Form>

      {/* File Upload Modal */}
      <CustomModal
        toggle={toggleUploadModal}
        modal={uploadModal}
        hideCloseBtn={ () => { return } }
        modalTitle = "Upload File" 
      >
        <VideoCompression fileSrc={fileSrc} toggle={toggleUploadModal} setProductFile={setProductFile} />
      </CustomModal>
    </>
  );
};

export default ProductForm;
