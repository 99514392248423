import { getAllCollections, createCollection, deleteCollectionService, updateCollectionService } from "../../services/collections-firebase.services";
import { createAction } from "../../utils/reducer.utils";
import { COLLECTION_ACTION_TYPES } from "./collection.types";

export const fetchCollectionsStart = () =>
    createAction(COLLECTION_ACTION_TYPES.FETCH_COLLECTIONS_START);

export const setLastCollectionDoc = (doc) =>
  createAction(COLLECTION_ACTION_TYPES.SET_LAST_COLLECTION_DOC, doc);


export const fetchCollectionsSuccess = (collectionsArray) =>
    createAction(COLLECTION_ACTION_TYPES.FETCH_COLLECTIONS_SUCCESS, collectionsArray);


export const fetchCollectionsFailed = (error) =>
    createAction(COLLECTION_ACTION_TYPES.FETCH_COLLECTIONS_FAILED, error);

export const fetchCollectionsAsync = () => async (dispatch) => {
    dispatch(fetchCollectionsStart());

    try {
        const result = await getAllCollections();
        console.log("REsult", result);
        dispatch(fetchCollectionsSuccess(result.collections));
        dispatch(setLastCollectionDoc(result.lastDoc));
    } catch(error) {
        dispatch(fetchCollectionsFailed(error))
    }
}

export const searchCollectionsAsync = (title = '') => async (dispatch) => {
    try {
      const result = await getAllCollections(null, title);
      
      dispatch(fetchCollectionsSuccess(result.collections));
      dispatch(setLastCollectionDoc(result.lastDoc));
      
    } catch (error) {
      dispatch(fetchCollectionsFailed(error));
    }
};


export const loadMoreCollectionsDB = (lastDoc = null, collections) => async (dispatch) => {
    try {
      const result = await getAllCollections(lastDoc);
      console.log("RESULT", result);
      let collectionData = [...collections, ...result.collections];
      
      dispatch(fetchCollectionsSuccess(collectionData));
      dispatch(setLastCollectionDoc(result.lastDoc));
      
    } catch (error) {
        console.log("ERROR", error);
    }
  };

export const addCollection =  async (collection, collectionsArray) => {
    try {
        const res = await createCollection(collection);
       createOrUpdateCollection(res, collectionsArray);
    } catch(error) {
        console.log(error);
    }
}

const createOrUpdateCollection = async (collection, collectionsArray) => {
    const checkCollectionExists = collectionsArray.find(collec => collection.id === collec.id)
    if(checkCollectionExists) {
        const index = collectionsArray.findIndex(collect => collect.id === collection.id)
        collectionsArray[index] = collection;
    } else  {
        return collectionsArray.unshift(collection);
    }
}

// Update Collection 
export const updateCollection =  async (collection, collectionArray, collectionId) => {
    try {
        const res = await updateCollectionService(collection, collectionId);
        createOrUpdateCollection(res, collectionArray);
    } catch(error) {
        console.log(error);
    }
}

// Delete Collection
export const deleteCollection = async (collectionId) => {
    try {
        await deleteCollectionService(collectionId)
    } catch(error) {
        console.log(error);
    }
}