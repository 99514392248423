import { createTableGrade, deleteTableGradeService, getAllTableGrades, updateTableGradeService } from "../../services/table-grades-firebase.services";
import { createAction } from "../../utils/reducer.utils";
import { TABLE_GRADE_ACTION_TYPES } from "./table-grade.types";

export const fetchTableGradesStart = () =>
    createAction(TABLE_GRADE_ACTION_TYPES.FETCH_TABLE_GRADES_START);


export const fetchTableGradesSuccess = (tableGradesArray) =>
    createAction(TABLE_GRADE_ACTION_TYPES.FETCH_TABLE_GRADES_SUCCESS, tableGradesArray);


export const fetchTableGradesFailed = (error) =>
    createAction(TABLE_GRADE_ACTION_TYPES.FETCH_TABLE_GRADES_FAILED, error);

export const fetchTableGradesAsync = () => async (dispatch) => {
    dispatch(fetchTableGradesStart());

    try {
        const tableGradesArray = await getAllTableGrades();
        dispatch(fetchTableGradesSuccess(tableGradesArray));
    } catch(error) {
        dispatch(fetchTableGradesFailed(error))
    }
}

export const addTableGrade =  async (tableGrade, tableGradesArray) => {
    try {
        const res = await createTableGrade(tableGrade);
       createOrUpdateTableGrade(res, tableGradesArray);
    } catch(error) {
        console.log(error);
    }
}

const createOrUpdateTableGrade = async (tableGrade, tableGradesArray) => {
    console.log("Table tableGrade", tableGrade)
    const checkTableGradeExists = tableGradesArray.find(tg => tableGrade.id === tg.id)
    if(checkTableGradeExists) {
        const index = tableGradesArray.findIndex(tgt => tgt.id === tableGrade.id)
        tableGradesArray[index] = tableGrade;
    } else  {
        return tableGradesArray.unshift(tableGrade);
    }
}

// Update Table Grade 
export const updateTableGrade =  async (tableGrade, tableGradesArray, tableGradeId) => {
    try {
        const res = await updateTableGradeService(tableGrade, tableGradeId);
        createOrUpdateTableGrade(res, tableGradesArray);
    } catch(error) {
        console.log(error);
    }
}

// Delete Table Grade
export const deleteTableGrade = async (tableGradeId) => {
    try {
        await deleteTableGradeService(tableGradeId)
    } catch(error) {
        console.log(error);
    }
}