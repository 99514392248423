import { createSelector } from "reselect";

const selectCollectionReducer = (state) => state.collections;
export const selectLastColectionDoc = (state) => state.collections.lastCollectionDoc;

export const selectCollections = createSelector(
    [selectCollectionReducer],
    (collectionSlice) => {
        return collectionSlice.collections;
    }
)

export const selectCollectionsIsLoading = createSelector(
    [selectCollectionReducer],
    (collectionsSlice) => collectionsSlice.isLoading
)