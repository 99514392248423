import "./product-list.styles.scss";
import ProductRow from "../product-row/product-row.component";
import ProductForm from "../product-form/product-form.component";
import CustomModal from "../../custom-modal/custom-modal.component";
import { Spinner } from "reactstrap";
import { Plus } from "react-feather";
import { useState } from "react";
import { useSelector } from "react-redux";
import { selectCurrentAdmin } from "../../../store/admin/admin.selector";
import CustomSearchBar from "../../custom-search-bar/custom-search-bar.component";

const ProductList = ({ toggle, modal, albumId, allProducts, isLoading }) => {
  const [productObj, setProductObj] = useState({});
  const setProductFormData = (prodObj) => setProductObj(prodObj);
  const currentAdmin = useSelector(selectCurrentAdmin);
  const [query, setQuery] = useState("")

  const handleAddProductToggle = () => {
    if(currentAdmin.Role === 'Editor' || currentAdmin.Role === 'Admin') {
      setProductFormData({});
      toggle();
    }
  }
  return (
    <>
      <div className="list-view">
        {(currentAdmin.Role === 'Editor' || currentAdmin.Role === 'Admin') && <div className="row">
          {/* Open Add Modal Here */}
          <div
            className="add-product-list-box main-list-hover"
            onClick={handleAddProductToggle}
          >
            <Plus />
            <a>Add Product</a>
          </div>
        </div>
        }
        <CustomSearchBar placeholder="Search your product title here..." setQuery={setQuery} />
        <div className="row mt-2">
          <div className="col-md-3">
            <span className="product-list-table-head-text">Product</span>
          </div>
          <div className="col-md-3">
            <span className="product-list-table-head-text">Description</span>
          </div>
          <div className="col-md-2">
            <span className="product-list-table-head-text">Tags</span>
          </div>
          <div className="col-md-1">
            <span className="product-list-table-head-text">Type</span>
          </div>
          <div className="col-md-1">
            <span className="product-list-table-head-text">Size</span>
          </div>
          <div className="col-md-2">Action</div>
        </div>
        
        {isLoading ? (
          <div className="text-center">
            <Spinner />
          </div>
        ) : allProducts && allProducts.length > 0 ? (
          allProducts?.filter(product => {
            if (query === '') {
              return product;
            } else if (product.Title.toLowerCase().includes(query.toLowerCase())) {
              return product;
            }
          }).map((product) => (
            <ProductRow
              product={product}
              key={product.id}
              toggle={toggle}
              setProductFormData={setProductFormData}
            />
          ))
        ) : (
          <div className="row">
            <div className="col-12 text-center  mt-2">
              <p className="primary">Doesn't have products!</p>
            </div>
          </div>
        )}
        <CustomModal toggle={toggle} modal={modal} modalTitle={"Save Product"}>
          <ProductForm toggle={toggle} albumId={albumId} productObj={productObj} />
        </CustomModal>
      </div>
    </>
  );
};

export default ProductList;
