import React, { useEffect } from "react";
import Breadcrumb from "../../layout/breadcrumb";
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Spinner,
} from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import "./tags.styles.scss";
import AddCollectionAlbumBTN from "../add-collection-album-btn/add-collection-album-btn.component";
import { useState } from "react";
import CustomModal from "../custom-modal/custom-modal.component";
import { selectTags, selectTagsIsLoading } from "../../store/tags/tag.selector";
import { fetchTagsAsync } from "../../store/tags/tag.action";
import TagForm from "./tag-form/tag-form.component";
import TagCard from "./tag-card/tag-card.component";
import { selectCurrentAdmin } from "../../store/admin/admin.selector";
import CustomBackButton from "../custom-back-button/custom-back-button.component";
import CustomSearchBar from "../custom-search-bar/custom-search-bar.component";

const Tags = () => {
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);

  const dispatch = useDispatch();

  const isLoading = useSelector(selectTagsIsLoading);
  const allTags = useSelector(selectTags);

  const [tagObj, setTagObj] = useState({});
  const setTagFormData = (tgObj) => setTagObj(tgObj);

  const currentAdmin = useSelector(selectCurrentAdmin);

  const [query, setQuery] = useState("")

  useEffect(() => {
    if (allTags.length === 0) dispatch(fetchTagsAsync());
  }, []);

  return (
    <>
      <Breadcrumb parent="Dashboard" title="Tags" />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card className="main-content-card">
              <CardHeader className="bg-transparent  d-flex justify-content-between">
                <h5 className="d-flex gap-1"> <CustomBackButton /> List Of Tags </h5>
                <h5 >Total Tags: {allTags && allTags.length > 0 ? allTags.length : 0} </h5>
              </CardHeader>
              <CardBody className="main-content-card-body">
                <Row>
                  {isLoading ? (
                    <div className="text-center">
                      <Spinner />
                    </div>
                  ) : (
                    <>
                      <CustomSearchBar placeholder="Search your tags name here..." setQuery={setQuery} />
                      {(currentAdmin.Role === "Editor" ||
                        currentAdmin.Role === "Admin") && (
                        <AddCollectionAlbumBTN
                          title={"Add Tag"}
                          toggle={() => {
                            setTagFormData({});
                            toggle();
                          }}
                          className={
                            allTags.length === 0
                              ? "empty-list main-list-hover"
                              : "main-list-hover"
                          }
                          setTagFormData={setTagFormData}
                        />
                      )}

                      
                      <CustomModal
                        toggle={toggle}
                        modal={modal}
                        modalTitle={"Save Tag"}
                      >
                        <TagForm toggle={toggle} tagObj={tagObj} />
                      </CustomModal>

                      {
                      allTags?.filter(tag => {
                      if (query === '') {
                        return tag;
                      } else if (tag.Name.toLowerCase().includes(query.toLowerCase())) {
                        return tag;
                      }
                    }).map((tag) => (
                        <TagCard
                          key={tag.id}
                          tag={tag}
                          setTagFormData={setTagFormData}
                          toggle={toggle}
                        />
                      ))}
                    </>
                  )}
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Tags;
