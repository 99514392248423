import { createSelector } from "reselect";

const selectAlbumReducer = (state) => {
    return state.albums;
};

export const selectCollectionAlbumsMap = createSelector(
    [selectAlbumReducer],
    (albumSlice) => albumSlice.albums
)


// export const selectCollectionAlbumsMap = createSelector(
//     [selectCollectionAlumbs],
//     (albums) => albums
// )

export const selectCollectionAlbumsIsLoading = createSelector(
    [selectAlbumReducer],
    (collectionAlbumsSlice) => collectionAlbumsSlice.isLoading
)