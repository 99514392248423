import './album-card.styles.scss';

import photoPlaceholder from '../../../assets/images/lightgallry/01.jpg';
import { Link, useParams } from 'react-router-dom';
import { Folder } from 'react-feather';
import { FIREBASE_STORAGE_BASE_URL } from '../../../constant/constant';
import { useSelector } from 'react-redux';
import { selectCurrentAdmin } from '../../../store/admin/admin.selector';

const AlbumCard = ({album, setAlbumFormData, toggle }) => {
    const { Title, Photo, id, CollectionID, Release_Date } = album;
    const { collectionTitle } = useParams();
    const currentAdmin = useSelector(selectCurrentAdmin);
    const handleOpenAlbumFormModal = () => {
        if (currentAdmin.Role === 'Editor' || currentAdmin.Role === 'Admin') {
            setAlbumFormData(album);
            toggle()
        }
    }

    return (
        <>
            <div className='col-3 mb-3'>
                <div className="album-content h-100 position-relative main-list-hover" onClick={handleOpenAlbumFormModal} >
                    <div className="">
                        <div className="album-img">
                            {/* photo ? photo : photoPlaceholder */}
                            <img src={
                                Photo ? 
                                (
                                    Photo.includes(FIREBASE_STORAGE_BASE_URL) ? Photo : FIREBASE_STORAGE_BASE_URL + Photo
                                ) 
                                : photoPlaceholder} alt={Title} crossOrigin='anonymous' />
                        </div>
                        <div className="album-box">
                            <span className="album-title mt-10">{Title}</span>
                            <span className="album-description mt-10"><strong>Release Date: </strong>{Release_Date.replace('T', " ")}</span>   
                        </div>
                    </div>
                    <div className="link-container">
                        <Link to={`/collections/${CollectionID}/${collectionTitle}/albums/${id}/${Title.replace(new RegExp("[;\\\\/:*?$#\"<>|&']"), ' ')}`} onClick={(event) => event.stopPropagation()}> <Folder /> Products</Link>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AlbumCard;