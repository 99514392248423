import { createTag, deleteTagService, getAllTags, updateTagService } from "../../services/tags-firebase.services";
import { createAction } from "../../utils/reducer.utils";
import { TAG_ACTION_TYPES } from "./tag.types";

export const fetchTagsStart = () =>
    createAction(TAG_ACTION_TYPES.FETCH_TAGS_START);


export const fetchTagsSuccess = (tagsArray) =>
    createAction(TAG_ACTION_TYPES.FETCH_TAGS_SUCCESS, tagsArray);


export const fetchTagsFailed = (error) =>
    createAction(TAG_ACTION_TYPES.FETCH_TAGS_FAILED, error);

export const fetchTagsAsync = () => async (dispatch) => {
    dispatch(fetchTagsStart());

    try {
        const tagsArray = await getAllTags();
        dispatch(fetchTagsSuccess(tagsArray));
    } catch(error) {
        dispatch(fetchTagsFailed(error))
    }
}

export const addTag =  async (tag, tagsArray) => {
    try {
        const res = await createTag(tag);
       createOrUpdateTag(res, tagsArray);
    } catch(error) {
        console.log(error);
    }
}

const createOrUpdateTag = async (tag, tagsArray) => {
    const checkTagExists = tagsArray.find(tg => tag.id === tg.id)
    if(checkTagExists) {
        const index = tagsArray.findIndex(tgt => tgt.id === tag.id)
        tagsArray[index] = tag;
    } else  {
        return tagsArray.unshift(tag);
    }
}

// Update Tag 
export const updateTag =  async (tag, tagsArray, tagId) => {
    try {
        const res = await updateTagService(tag, tagId);
        createOrUpdateTag(res, tagsArray);
    } catch(error) {
        console.log(error);
    }
}

// Delete Tag
export const deleteTag = async (tagId) => {
    try {
        await deleteTagService(tagId)
    } catch(error) {
        console.log(error);
    }
}