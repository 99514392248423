import { createSelector } from "reselect";

const selectHallReducer = (state) => state.halls;

export const selectHalls = createSelector(
    [selectHallReducer],
    (hallSlice) => {
        return hallSlice.halls;
    }
)

export const selectHallsIsLoading = createSelector(
    [selectHallReducer],
    (hallSlice) => hallSlice.isLoading
)