import { HALL_ACTION_TYPES } from "./hall.types";

const HALL_INITIAL_STATE = {
  halls: []
};

export const hallReducer = (state = HALL_INITIAL_STATE, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case HALL_ACTION_TYPES.FETCH_HALLS_START:
      return { ...state, isLoading: true}
    case HALL_ACTION_TYPES.FETCH_HALLS_SUCCESS:
        return { ...state, halls: payload, isLoading: false }
    case HALL_ACTION_TYPES.FETCH_HALLS_FAILED:
        return { ...state, error: payload, isLoading: false }
    default:
        return state;
  }
};