import { SEAT_ACTION_TYPES } from "./seat.types";

const SEAT_INITIAL_STATE = {
   seats : [],
};

export const seatReducer = (state = SEAT_INITIAL_STATE, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case SEAT_ACTION_TYPES.FETCH_SEAT_START:
      return { ...state, isLoading: true}
    case SEAT_ACTION_TYPES.FETCH_SEAT_SUCCESS:
        return { ...state, seats: payload, isLoading: false }
    case SEAT_ACTION_TYPES.FETCH_SEAT_FAILED:
        return { ...state, error: payload, isLoading: false }  
    default:
        return state;
  }
};