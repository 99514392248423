import { useParams } from "react-router-dom";
import { Card, CardBody,CardHeader, Spinner, Container, Row, Col } from 'reactstrap';
import Breadcrumbs from "../../layout/breadcrumb";
import ProductList from "./product-list/product-list.component";
import { useState, useEffect } from 'react';
import { selectAlbumProductsIsLoading, selectAlbumProductsMap } from "../../store/product/product.selector";
import { fetchAlbumProductsAsync } from "../../store/product/product.action";
import { useDispatch, useSelector } from 'react-redux';
import { selectTags } from "../../store/tags/tag.selector";
import { fetchTagsAsync } from "../../store/tags/tag.action";
import { selectCurrentAdmin } from "../../store/admin/admin.selector";
import CustomBackButton from "../custom-back-button/custom-back-button.component";

const Products = () => {
    const { collection, album, albumTitle, collectionTitle } = useParams();
    const [modal, setModal] = useState(false);
    const toggle = () => setModal(!modal);
    const dispatch = useDispatch();

    const isLoading = useSelector(selectAlbumProductsIsLoading);
    const allAlbumProducts = useSelector(selectAlbumProductsMap);
    const allTags = useSelector(selectTags);
    const admin = useSelector(selectCurrentAdmin);

    useEffect(() => {
        if (!allAlbumProducts[album] || allAlbumProducts[album].length === 0) {
            dispatch(fetchAlbumProductsAsync(album, allAlbumProducts));
        }
        if (allTags.length === 0) dispatch(fetchTagsAsync());

        console.log("ADmin", admin);
        }, []);
    return (
        <>
        <Breadcrumbs parent={albumTitle ? albumTitle : 'Albums'} title="Products"  />
            <Container fluid={true}>
                <Row>
                <Col sm="12">
                    <Card className="main-content-card">
                    <CardHeader className="bg-transparent d-flex justify-content-between">
                        <h5 className="d-flex gap-1"> <CustomBackButton /> {albumTitle ? albumTitle + ' > ' : ''} Products </h5>
                        <h5>Total Products : { allAlbumProducts[album] && allAlbumProducts[album].length > 0 ? allAlbumProducts[album].length : 0 }</h5>
                    </CardHeader>
                    <CardBody className="main-content-card-body">
                        <Row>
                            <ProductList toggle={toggle} modal={modal} albumId={album} allProducts={allAlbumProducts[album]} isLoading={isLoading} />
                        </Row>
                    </CardBody>
                    </Card>
                </Col>
                </Row>
            </Container>
            
        </>
    )
}

export default Products;