import { useDispatch, useSelector } from "react-redux";
import {
  selectCollectionAlbumsIsLoading,
  selectCollectionAlbumsMap,
} from "../../store/album/album.selector";
import { fetchCollectionAlbumsAsync } from "../../store/album/album.action";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  Spinner,
  Container,
  Row,
  Col,
} from "reactstrap";
import Breadcrumbs from "../../layout/breadcrumb";
import AlbumCard from "./album-card/album-card.component";
import CustomModal from "../custom-modal/custom-modal.component";
import AddCollectionAlbumBTN from "../add-collection-album-btn/add-collection-album-btn.component";
import AlbumForm from "./album-form/album-form.component";
import { selectCurrentAdmin } from "../../store/admin/admin.selector";
import CustomBackButton from "../custom-back-button/custom-back-button.component";
import CustomSearchBar from "../custom-search-bar/custom-search-bar.component";

const Albums = () => {
  // get collection id form url
  const { collection, collectionTitle } = useParams();

  const dispatch = useDispatch();

  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);

  const [albumObj, setAlbumObj] = useState({});
  const setAlbumFormData = (albObj) => setAlbumObj(albObj);

  const isLoading = useSelector(selectCollectionAlbumsIsLoading);
  const allCollectionAlbums = useSelector(selectCollectionAlbumsMap);
  const currentAdmin = useSelector(selectCurrentAdmin);
  const [query, setQuery] = useState("");

  useEffect(() => {
    if (
      !allCollectionAlbums[collection] ||
      allCollectionAlbums[collection].length === 0
    )
      dispatch(fetchCollectionAlbumsAsync(collection, allCollectionAlbums));
  }, []);

  return (
    <>
      <Breadcrumbs
        parent={collectionTitle ? collectionTitle : "Collection"}
        title="Albums"
      />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card className="main-content-card">
              <CardHeader className="bg-transparent d-flex justify-content-between">
                <h5 className="d-flex gap-1">
                  
                  <CustomBackButton />
                  {collectionTitle ? collectionTitle + " > " : ""} Albums
                </h5>
                <h5>Total Albums : { allCollectionAlbums[collection] && allCollectionAlbums[collection].length > 0 ? allCollectionAlbums[collection].length: 0   }</h5>
              </CardHeader>
              <CardBody className="main-content-card-body">
                <Row>
                  {isLoading ? (
                    <div className="text-center">
                      <Spinner />
                    </div>
                  ) : (
                    <>
                    <CustomSearchBar placeholder="Search your album title here..." setQuery={setQuery} />
                      {(currentAdmin.Role === "Editor" ||
                        currentAdmin.Role === "Admin") && (
                        <AddCollectionAlbumBTN
                          title={"ADD Album"}
                          toggle={() => {
                            setAlbumFormData({});
                            toggle();
                          }}
                          className={
                            !allCollectionAlbums[collection] ||
                            allCollectionAlbums[collection].length === 0
                              ? "empty-list"
                              : ""
                          }
                        />
                      )}
                      <CustomModal
                        toggle={toggle}
                        modal={modal}
                        modalTitle={"Add Album"}
                      >
                        <AlbumForm
                          toggle={toggle}
                          collectionId={collection}
                          albumObj={albumObj}
                        />
                      </CustomModal>
                      {allCollectionAlbums[collection] &&
                        allCollectionAlbums[collection]
                          ?.filter((album) => {
                            if (query === "") {
                              return album;
                            } else if (
                              album.Title.toLowerCase().includes(
                                query.toLowerCase()
                              )
                            ) {
                              return album;
                            }
                          })
                          .map((album) => (
                            <AlbumCard
                              key={album.id}
                              album={album}
                              setAlbumFormData={setAlbumFormData}
                              toggle={toggle}
                            />
                          ))}
                    </>
                  )}
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Albums;
