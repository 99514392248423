import "./donation-purpose-card.styles.scss";

import photoPlaceholder from "../../../assets/images/lightgallry/01.jpg";
import { FIREBASE_STORAGE_BASE_URL } from "../../../constant/constant";
import { useSelector } from "react-redux";
import { selectCurrentAdmin } from "../../../store/admin/admin.selector";
import { Users } from "react-feather";
import { useState } from "react";
import CustomModal from "../../custom-modal/custom-modal.component";
import UsersDonationPurpose from "../users-donation/users-donation.component";

const DonationPurposeCard = ({ donationPurpose, setDonationPurposeFormData, toggle }) => {
  const { Title, Photo, Description, id } = donationPurpose;
  const currentAdmin = useSelector(selectCurrentAdmin);
  const [donationUserModal, setDonationUserModal] = useState(false);
  const donationUserToggle = () => setDonationUserModal(!donationUserModal);

  const handleOpenDonationPurposeFormModal = () => {
    if(currentAdmin.Role === 'Editor' || currentAdmin.Role === 'Admin') {
      setDonationPurposeFormData(donationPurpose);
      toggle();
    }
  };
  return (
    <>
      <div className="col-3 mb-3">
        <div
          className="tag-content h-100 position-relative main-list-hover"
          onClick={handleOpenDonationPurposeFormModal}
        >
          <CustomModal
                        toggle={donationUserToggle}
                        modal={donationUserModal}
                        modalTitle={"Users who donated for this purpose"}
                      >
                        <UsersDonationPurpose toggle={donationUserToggle}  donationPurposeId={id}/>
                    </CustomModal>
          <div className="tag-box position-relative">
            <div className="tag-img">
              <img
                src={
                  Photo ? FIREBASE_STORAGE_BASE_URL + Photo : photoPlaceholder
                }
                alt={Title}
                crossOrigin="anonymous"
              />
            </div>
            <div className="text-container-box mt-2 w-100">
              <span className="tag-title mt-10">{Title}</span>
              <p>
                {Description}
              </p>

              <div className="text-end">
                <button className="btn bg-transparent border-0 btn-primary" onClick={(e) => {e.stopPropagation();  donationUserToggle(); }}>
                  <Users /> Users
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DonationPurposeCard;
