import "./reserve-concert-list.styles.scss";
import CustomModal from "../../custom-modal/custom-modal.component";
import { Spinner } from "reactstrap";
import { Plus } from "react-feather";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { selectCurrentAdmin } from "../../../store/admin/admin.selector";
import CustomSearchBar from "../../custom-search-bar/custom-search-bar.component";
import ReserveConcertRow from "../reserve-concert-row/reserve-concert-row.component";
import ReserveConcertForm from "../reserve-concert-form/reserve-concert-form.component";
import { getCurrentConcert } from "../../../services/concerts-firebase.services";

const ReserveConcertList = ({ toggle, modal, concertId, gradeId, allReservations, isLoading }) => {
  const [reservationObj, setreservationObj] = useState({});
  const setReservationFormData = (prodObj) => setreservationObj(prodObj);
  const currentAdmin = useSelector(selectCurrentAdmin);
  const [query, setQuery] = useState("")
  const [currentConcert, setCurrentConcert] = useState(null)

  const handleAddReservationToggle = () => {
    if(currentAdmin.Role === 'Editor' || currentAdmin.Role === 'Admin') {
      setReservationFormData({});
      toggle();
    }
  }

  // getCurrentConcert
  useEffect(() => {
    const getCurrentCon = async () => {
      await getCurrentConcert(concertId).then((result) => {
        setCurrentConcert(result);
      });
    };

    getCurrentCon();
  }, []);

  // Check if current Table Grade Has Capacity or No
  const hasCapacityToAdd = currentConcert?.Table_Grade?.some((tableGrade) => {
    return tableGrade.Grade_ID === gradeId && tableGrade.Capacity > 0;
  });


  return (
    <>
      <div className="list-view">
        {(currentAdmin.Role === 'Editor' || currentAdmin.Role === 'Admin') && 
        (hasCapacityToAdd)
        && <div className="row">
          {/* Open Add Modal Here */}
          <div
            className="add-product-list-box main-list-hover"
            onClick={handleAddReservationToggle}
          >
            <Plus />
            <span>Add Reservation</span>
          </div>
        </div>
        }
        <CustomSearchBar placeholder="Search your reservation price here..." setQuery={setQuery} />
        <div className="row mt-2">
          <div className="col-md-2">
            <span className="product-list-table-head-text">User</span>
          </div>
          <div className="col-md-2">
            <span className="product-list-table-head-text">Rerserved For</span>
          </div>
          <div className="col-md-2">
            <span className="product-list-table-head-text">Grade</span>
          </div>
          <div className="col-md-2">
            <span className="product-list-table-head-text">Table Number</span>
          </div>
          <div className="col-md-2">
            <span className="product-list-table-head-text">Price</span>
          </div>

          <div className="col-md-2">Action</div>
        </div>
        
        {isLoading ? (
          <div className="text-center">
            <Spinner />
          </div>
        ) : allReservations && allReservations.length > 0 ? (
          allReservations?.filter((reservation) => {
            if (query === '') {
              return reservation;
            } else if (reservation.Price === query) {
              return reservation;
            }
          }).map((reservation) => (
            <ReserveConcertRow
              reservation={reservation}
              currentConcert={currentConcert}
              key={reservation.id}
              toggle={toggle}
              setReservationFormData={setReservationFormData}
            />
          ))
        ) : (
          <div className="row">
            <div className="col-12 text-center  mt-2">
              <p className="primary">Doesn't have Reseravation!</p>
            </div>
          </div>
        )}
        <CustomModal toggle={toggle} modal={modal} modalTitle={"Save Reseravtion"}>
          <ReserveConcertForm toggle={toggle} concertId={concertId}  gradeId={gradeId} reservationObj={reservationObj} />
        </CustomModal>
      </div>
    </>
  );
};

export default ReserveConcertList;
