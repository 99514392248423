import { createDonationPurposeService, deleteDonationPurposeService, getAllDonationPurposes, updateDonationPurposeService } from "../../services/donation-purposes-firebase.services";
import { createAction } from "../../utils/reducer.utils";
import { DONATION_PURPOSE_ACTION_TYPES } from "./donation-purpose.types";

export const fetchDonationPurposesStart = () =>
    createAction(DONATION_PURPOSE_ACTION_TYPES.FETCH_DONATION_PURPOSES_START);


export const fetchDonationPurposesSuccess = (donationPurposesArray) =>
    createAction(DONATION_PURPOSE_ACTION_TYPES.FETCH_DONATION_PURPOSES_SUCCESS, donationPurposesArray);


export const fetchDonationPurposesFailed = (error) =>
    createAction(DONATION_PURPOSE_ACTION_TYPES.FETCH_DONATION_PURPOSES_FAILED, error);

export const fetchDonationPurposesAsync = () => async (dispatch) => {
    dispatch(fetchDonationPurposesStart());

    try {
        const donationPurposesArray = await getAllDonationPurposes();
        dispatch(fetchDonationPurposesSuccess(donationPurposesArray));
    } catch(error) {
        dispatch(fetchDonationPurposesFailed(error))
    }
}

export const addDonationPurpose =  async (donationPurpose, donationPurposesArray) => {
    try {
        const res = await createDonationPurposeService(donationPurpose);
       createOrUpdateDonationPurpose(res, donationPurposesArray);
       return res;
    } catch(error) {
        console.log(error);
        return error;
    }
}

const createOrUpdateDonationPurpose = async (donationPurpose, donationPurposesArray) => {
    const checkDonationPurposeExists = donationPurposesArray.find(dp => donationPurpose.id === dp.id)
    if(checkDonationPurposeExists) {
        const index = donationPurposesArray.findIndex(dp => dp.id === donationPurpose.id)
        donationPurposesArray[index] = donationPurpose;
    } else  {
        return donationPurposesArray.unshift(donationPurpose);
    }
}

// Update Donation Purpose 
export const updateDonationPurpose =  async (donationPurpose, donationPurposesArray, id) => {
    try {
        const res = await updateDonationPurposeService(donationPurpose, id);
        createOrUpdateDonationPurpose(res, donationPurposesArray);
        return res;
    } catch(error) {
        console.log(error);
        return error;
    }
}

// Delete Donation Purpose
export const deleteDonationPurpose = async (id) => {
    try {
        return await deleteDonationPurposeService(id);
    } catch(error) {
        console.log(error);
        return error;
    }
}