import { useDispatch, useSelector } from "react-redux";
import Breadcrumb from "../../layout/breadcrumb";
import CustomBackButton from "../custom-back-button/custom-back-button.component";
import {
    Container,
    Row,
    Card,
    CardHeader,
    CardBody,
    Spinner,
    Col,
} from "reactstrap";
import { selectConcerts, selectConcertsIsLoading } from "../../store/concerts/concerts.selector";
import { useEffect, useState } from "react";
import { fetchConcertsAsync, searchConcertsAsync } from "../../store/concerts/concerts.action";
import ConcertCards from "./concerts-card/concerts-card.component";
import CustomSearchBar from "../custom-search-bar/custom-search-bar.component";
import AddCollectionAlbumBTN from "../add-collection-album-btn/add-collection-album-btn.component";
import CustomModal from "../custom-modal/custom-modal.component";
import ConcertsForm from "./concerts-form/concerts-form.component";
import { selectTableGrades } from "../../store/table-grade/table-grade.selector";
import { selectHalls } from "../../store/hall/hall.selector";
import { fetchTableGradesAsync } from "../../store/table-grade/table-grade.action";
import { fetchHallsAsync } from "../../store/hall/hall.action";

const Concerts = () => {

    const dispatch = useDispatch();
    const [modal, setModal] = useState(false);
    const toggle = () => setModal(!modal);
    const [collectionObj, setCollectionObj] = useState({});
    const setCollectionFormData = (collectObj) => setCollectionObj(collectObj);
    const allConcerts = useSelector(selectConcerts);
    const isLoading = useSelector(selectConcertsIsLoading);
    const [query, setQuery] = useState("");

    // const allHalls = useSelector(selectHalls);
    const allTableGrades = useSelector(selectTableGrades);

    useEffect(() => {
        if (allConcerts.length === 0) {
            dispatch(fetchConcertsAsync());
        }
    }, []);

    useEffect(() => {
        if (allTableGrades.length === 0) {
            dispatch(fetchTableGradesAsync());
        }
    }, []);

    // useEffect(() => {
    //     if (allHalls.length === 0) {
    //         dispatch(fetchHallsAsync());
    //     }
    // }, []);


    return (
        <>
            <Breadcrumb parent="Dashboard" title="Concerts" />
            <Container fluid={true}>
                <Row>
                    <Col sm="12">
                        <Card className="main-content-card">
                            <CardHeader className="bg-transparent d-flex justify-content-between">
                                <h5 className="d-flex gap-1"> <CustomBackButton /> List Of Concerts </h5>
                                <h5> Total Concerts: {allConcerts?.length} </h5>
                            </CardHeader>
                            <CardBody className="main-content-card-body">
                                <Row>
                                    {isLoading ? (
                                        <div className="text-center">
                                            <Spinner />
                                        </div>
                                    ) : (
                                        <>
                                            <CustomSearchBar placeholder="Search your concert title here..." setQuery={setQuery} />
                                            <div className="row">
                                                <AddCollectionAlbumBTN
                                                    title={"ADD Concert"}
                                                    toggle={() => {
                                                        setCollectionFormData({});
                                                        toggle();
                                                    }}
                                                    className={
                                                        allConcerts?.length === 0 ? "d-flex flex-row p-3 main-list-hover" : "d-flex flex-row p-3 main-list-hover"
                                                    }
                                                    containerClassName="col-12"
                                                    setCollectionFormData={setCollectionFormData}
                                                />
                                            </div>
                                            <CustomModal
                                                toggle={toggle}
                                                modal={modal}
                                                modalTitle={"Add Concert"}
                                            >
                                                <ConcertsForm toggle={toggle} collectionObj={collectionObj} />
                                            </CustomModal>
                                            <div className="row">
                                                
                                                {
                                                allConcerts?.filter((concert) => {
                                                    if (query === "") {
                                                      return concert;
                                                    } else if (
                                                      concert.Title.toLowerCase().includes(
                                                        query.toLowerCase()
                                                      )
                                                    ) {
                                                      return concert;
                                                    }
                                                  })
                                                .map((item, index) => (
                                                    <ConcertCards
                                                        key={index}
                                                        concerts={item}
                                                        setCollectionFormData={setCollectionFormData}
                                                        toggle={toggle}
                                                    />
                                                ))}
                                            </div>
                                        </>
                                    )}
                                </Row>

                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container >
        </>
    );
};

export default Concerts;
