import { createSelector } from "reselect";

const selectDonationPurposeReducer = (state) => state.donationPurposes;

export const selectDonationPurposes = createSelector(
    [selectDonationPurposeReducer],
    (donationPurposeSlice) => {
        return donationPurposeSlice.donationPurposes;
    }
)

export const selectDonationPurposesIsLoading = createSelector(
    [selectDonationPurposeReducer],
    (donationPurposeSlice) => donationPurposeSlice.isLoading
)