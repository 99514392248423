import { useState, useEffect } from "react";
import { Form, Button, Spinner } from "reactstrap";
import FormInput from "../../form-input/form-input.component";
import {
  deleteAlbumProduct,
  updateAlbumProduct,
} from "../../../store/product/product.action";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import SweetAlert from "sweetalert2";
import Select from "react-select";


import { selectCurrentAdmin } from "../../../store/admin/admin.selector";
import { addConcertReservation, deleteConcertReservation, updateConcertReservation } from "../../../store/reserve-concert/reserve-concert.action";
import { selectConcertReservationsMap } from "../../../store/reserve-concert/reserve-concert.selector";
import { getAllUsers } from "../../../services/users-firebase.services";
import { getAvailableSeatsForConcert } from "../../../services/reservations-firebase.services";
import { selectUsers } from "../../../store/users/user.selector";
import { selectSeatsMap } from "../../../store/seat/seat.selector";
const ReserveConcertForm = ({ toggle, concertId, gradeId, reservationObj }) => {
  const [values, setValues] = useState({
    Concert_ID: concertId,
    Grade_ID: gradeId,
    Price: 0,
    // Seat_ID: "",
    User_ID: "",
    Reserve_For: "",
    Created_at: new Date(),
    Updated_at: new Date(),
    Status: 1,
  });

  const [isSaving, setIsSaving] = useState(false);
  
  const allConcertReservations = useSelector(selectConcertReservationsMap);
  const [reservationId, setReservationId] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const currentAdmin = useSelector(selectCurrentAdmin);
  // const allSeats = useSelector(selectSeatsMap);

  const allUsers = useSelector(selectUsers);
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);

  // const [seats, setSeats] = useState([]);
  // const [selectedSeat, setSelectedSeat] = useState(null);

  // const [selectSeatError, setSelectSeatError] = useState('');
  const [selectUserError, setSelectUserError] = useState('');

  useEffect(() => {
    if (Object.keys(reservationObj).length > 0) {
      const { Updated_at, id, ...rest } = reservationObj;
      
      setValues({ ...values, ...rest });

      setReservationId(id)
    }
  }, [])

  useEffect(() => {
    if (Object.keys(reservationObj).length > 0) {
      let currentUser = users.find((user) => user.value === reservationObj.User_ID)
      setSelectedUser(currentUser);
    }
  }, [reservationObj, users])

  // useEffect(() => {
  //   if (Object.keys(reservationObj).length > 0) {
  //     const currentSeat = allSeats[gradeId].find(seat => seat.id === reservationObj.Seat_ID);
  //     setSelectedSeat({ value: currentSeat.id, label: currentSeat.Seat_Number})
  //   }
  // }, [reservationObj, allSeats])


  useEffect(() => {
    if ( allUsers && allUsers.length > 0 ) {
      const usersListArray = [];
      allUsers?.forEach((user) => {
          usersListArray.push({ value: user.id, label: user.name, profile: user.profile || null });
        });
      setUsers(usersListArray);

    }

  }, [allUsers]);


  // useEffect(() => {
  //   getAvailableSeatsForConcert(concertId, gradeId)
  //   .then(availableSeats => {
      
  //     let seatsArray = []
  //     availableSeats.forEach((seat) => {
  //       seatsArray.push({ value: seat.id, label: seat.Seat_Number, tableGrade: seat.Table_Grade || null });
  //     })
  //     setSeats(seatsArray)
  //   })
  //   .catch(error => {
  //     console.error(error);
  //   });

  // }, [concertId, gradeId]);

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!selectedUser) {
      setSelectUserError('Please select a user');
      return;
    }

    // if (!selectedSeat) {
    //   setSelectSeatError('Please select a seat');
    //   return;
    // }

    setIsSaving(true);

    // values.Seat_ID = selectedSeat?.value
    values.User_ID = selectedUser?.value

    reservationId !== null
      ? updateConcertReservation(
          { ...values, id: reservationId },
          allConcertReservations[concertId+gradeId],
          reservationId
        ).then(() => {
          toast.success("Your reservation has been Updated.");
          setIsSaving(false);
          setReservationId(null);
          toggle();
        },(err)=> { toast.error("Something went wrong, please try again"); setIsSaving(false);})
      : addConcertReservation(
          values,
          allConcertReservations[concertId+gradeId] ? allConcertReservations[concertId+gradeId] : []
        ).then(() => {
          toast.success("Your reservation has been saved.");
          setIsSaving(false);
          toggle();
        }, (err)=> { toast.error("Something went wrong, please try again"); setIsSaving(false);});
  };


  // Handle Delete Reservation
  const handleDeleteReservation = () => {
    SweetAlert.fire({
      title: "Are you sure?",
      text: "Once deleted, it will be hard to recover it again.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Ok",
      cancelButtonText: "cancel",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        deleteConcertReservation(reservationId, reservationObj).then(() => {
          SweetAlert.fire(
            "Deleted!",
            "Your reservation has been deleted.",
            "success"
          );
          const index = allConcertReservations[concertId+gradeId].findIndex(
            (reservation) => reservation.id === reservationId
          );
          allConcertReservations[concertId+gradeId].splice(index, 1);
          toggle();
        }, (err) => SweetAlert.fire(
          "Woops!",
          "Something went wrong, please try again later.",
          "error"
        ))
      } else {
        SweetAlert.fire("Your reservation is safe!");
      }
    });
  };

  const onChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const handleSelectUser = (data) => {
    setSelectedUser(data);
    setSelectUserError("");
  }

  // const handleSelectSeat = (data) => {
  //   setSelectedSeat(data);
  //   setSelectSeatError("");
  // }


  return (
    <>
      <Form className="form-bookmark needs-validation" onSubmit={handleSubmit}>
        {!isLoading && (
          <div className="form-row row mb-3">
            
            <div className="col-4 mb-3">
              <label htmlFor="">Select User.</label>
              <Select
                options={users}
                placeholder="Select User"
                value={selectedUser}
                onChange={handleSelectUser}
                isSearchable={true}
                isRequired
              />
              {selectUserError && <div style={{ color: 'red' }}>{selectUserError}</div>}
            </div>
            {/* <div className="col-6 mb-3">
              <label htmlFor="">Select From Available Seat.</label>
              <Select
                options={seats}
                placeholder="Select Seat"
                value={selectedSeat}
                onChange={handleSelectSeat}
                isSearchable={true}
                isRequired
              />
              {selectSeatError && <div style={{ color: 'red' }}>{selectSeatError}</div>}
            </div> */}

            <div className="col-4 mb-3">
              <FormInput
                name="Reserve_For"
                type="text"
                placeholder="Reservation for(someone) "
                errorMessage="Reservation for(someone) is required!"
                label="Reserve For(someone) full name"
                required
                value={values["Reserve_For"]}
                onChange={onChange}
              />
            </div>

            <div className="col-4 mb-3">
              <FormInput
                name="Price"
                type="number"
                placeholder="Reservation Price"
                errorMessage="Reseravtion Price is required!"
                label="Price"
                // step=".01"
                required
                value={values["Price"]}
                onChange={onChange}
              />
            </div>
          </div>
        )}
        <div
          className={
            reservationId && currentAdmin.Role === "Admin"
              ? "d-flex justify-content-between"
              : ""
          }
        >
          {reservationId && currentAdmin.Role === "Admin" && (
            <Button color="secondary" onClick={handleDeleteReservation}>
              Delete
            </Button>
          )}
          <div
            className={
              !reservationId || currentAdmin.Role !== "Admin"
                ? "d-flex justify-content-between"
                : "d-flex gap-2"
            }
          >
            <Button color="light" onClick={toggle}>
              Cancel
            </Button>

            <Button
              color="primary"
              className="mr-1"
              disabled={isSaving ? true : false}
            >
              {isSaving ? (
                <Spinner style={{ height: "20px", width: "20px" }} />
              ) : (
                "Save"
              )}
            </Button>
          </div>
        </div>
      </Form>
    </>
  );
};

export default ReserveConcertForm;
