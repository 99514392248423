import { useSelector } from "react-redux";
import "./admin-card.styles.scss";
import { selectCurrentAdmin } from "../../../store/admin/admin.selector";
import { formatTimestap } from "../../../utils/helper.utils";

const AdminCard = ({ adminObj, setAdminFormData, toggle }) => {
  const { Name, Email, Role, id, Status } = adminObj;

  const currentAdmin = useSelector(selectCurrentAdmin);
  
  const handleOpenAdminFormModal = () => {
    if(currentAdmin.uid !== adminObj.id && currentAdmin.Role === 'Admin' ) {
      setAdminFormData(adminObj);
      toggle();
    }
  };
  return (
    <>
      <div
        className="admin-list-item position-relative row align-items-center main-list-hover"
        onClick={handleOpenAdminFormModal}
      >
        <div className="col-md-3">
          <div className="d-flex align-items-center gap-2 position-relative">
            <span className="admin-list-name">{Name}</span>
            <div className="admin-list-color"></div>
          </div>
        </div>
        <div className="col-md-3 d-flex">
          <p className="admin-list-description">
            {Email}
          </p>
        </div>
        <div className={`${(currentAdmin.Role !== 'Admin') ? 'col-md-3 ': 'col-md-2 ' } d-flex`}>
          <p className="admin-list-description">
            {Role}
          </p>
        </div>
        <div className={` ${(currentAdmin.Role !== 'Admin') ? 'col-md-3 ': 'col-md-2 '} d-flex`}>
          <p className="admin-list-description">
            {Status && Status == 1 ? <span className="primary" >Active</span>: <span className="text-danger" >Deactive</span> } 
          </p>
        </div>
        {(currentAdmin.Role === 'Admin') && <div className="col-md-2 d-flex">
          <div className="d-flex flex-column">
            <div className="admin-list-edit">
              <button> {currentAdmin.uid !== adminObj.id ? (
                "Edit"
              ) : "Me"}</button>
            </div>
          </div>
        </div>}
      </div>
    </>
  );
};

export default AdminCard;
