import { TABLE_GRADE_ACTION_TYPES } from "./table-grade.types";

const TABLE_GRADE_INITIAL_STATE = {
  tableGrades: []
};

export const tableGradeReducer = (state = TABLE_GRADE_INITIAL_STATE, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case TABLE_GRADE_ACTION_TYPES.FETCH_TABLE_GRADES_START:
      return { ...state, isLoading: true}
    case TABLE_GRADE_ACTION_TYPES.FETCH_TABLE_GRADES_SUCCESS:
        return { ...state, tableGrades: payload, isLoading: false }
    case TABLE_GRADE_ACTION_TYPES.FETCH_TABLE_GRADES_FAILED:
        return { ...state, error: payload, isLoading: false }
    default:
        return state;
  }
};