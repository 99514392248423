import { getDownloadURL, ref, uploadBytesResumable } from "@firebase/storage";
import { storage } from "../config/firebase.config";
import { FIREBASE_STORAGE_BASE_URL } from "../constant/constant";

// Format Byties to KB and MB
export const formatBytes = (bytes, decimals = 0) => {
  if (!+bytes) return "0 Bytes";

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
};

// remove undefined and null values from an object
export const removeUndefinedNull = (obj) => {
  Object.keys(obj).forEach((key) => {
    if (obj[key] === null || obj[key] === undefined) {
      delete obj[key];
    }
  });

  return obj;
};

// change timestamps to readable date strings
export const formatTimestap = (timestamps, hasTime = false) => {
  const newDate = new Date(
    timestamps.seconds * 1000 + timestamps.nanoseconds / 1000000
  );
  // 4/29/2023
  if (hasTime) {

    const formatter = new Intl.DateTimeFormat('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric'
    });
    const formattedDate = formatter.format(newDate);

    return formattedDate;
  } else {
    return (
      newDate.getDay() + "/" + newDate.getMonth() + "/" + newDate.getFullYear()
    );
  }
  
};

export const convertToCustomFormat = (inputDatetime) => {
  const dateObject = new Date(inputDatetime);

  const formattedDate = `${dateObject.toDateString()} ${dateObject.toLocaleTimeString()} GMT${dateObject.getTimezoneOffset() >= 0 ? '-' : '+'}${Math.abs(dateObject.getTimezoneOffset() / 60).toString().padStart(2, '0')}${Math.abs(dateObject.getTimezoneOffset() % 60).toString().padStart(2, '0')}`;
  return formattedDate;
};


export const convertToInputFormat = (customFormattedDate) => {
  // Convert Firestore timestamp to JavaScript Date object
  const dateObject = 
  new Date(customFormattedDate.seconds * 1000 + customFormattedDate.nanoseconds / 1000000); // Firestore timestamp is in seconds

  // Format the Date object into the desired format
  const formattedDateTime = `${dateObject.getFullYear()}-${(dateObject.getMonth() + 1).toString().padStart(2, '0')}-${dateObject.getDate().toString().padStart(2, '0')}T${dateObject.getHours().toString().padStart(2, '0')}:${dateObject.getMinutes().toString().padStart(2, '0')}`;


  return customFormattedDate.seconds ? formattedDateTime : customFormattedDate;
};


// Upload File to Firebase Store
export const firebaseStoreUploadFile = (
  file,
  setVideoUploadPercent,
  uploadDirectory = "Products",
  setUploadedFileArr,
  uploadedFileArr,
  dimensions = "",
  setUploading
) => {
  if (file) {
    console.log("FILE", file);
    setUploading(true);
    const storageRef = ref(
      storage,
      `/${uploadDirectory}/${file.name ? file.name : new Date().getTime() + file.type }`
    ); // progress can be paused and resumed. It also exposes progress updates. // Receives the storage reference and the file to upload.
    const uploadTask = uploadBytesResumable(storageRef, file);
    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const percent = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        ); // update progress
        setVideoUploadPercent(percent);
      },
      (err) => {
        console.log(err);
      },
      () => {
        // download url
        getDownloadURL(uploadTask.snapshot.ref).then((url) => {
          let newUrl = url.replace(FIREBASE_STORAGE_BASE_URL, "");
          setUploadedFileArr([
            ...uploadedFileArr,
            {
              name: file.name ? file.name : new Date().getTime(),
              url: newUrl,
              dimensions: dimensions,
              type: file.type,
              size: file.size,
            },
          ]);
          setUploading(false);
        });
      }
    );
  }
};


export const getUniqueArr = (arr) => {
  const result = Object.values(
    arr.reduce((acc, obj) => ({ ...acc, [obj.id]: obj }), {})
  );

  return result;
}