import { getAllUsersNoPaginate } from "../../services/users-firebase.services";
import { createAction } from "../../utils/reducer.utils";
import { USER_ACTION_TYPES } from "./user.types";


export const fetchUsersStart = () =>
    createAction(USER_ACTION_TYPES.FETCH_USERS_START);


export const fetchUsersSuccess = (usersArray) =>
    createAction(USER_ACTION_TYPES.FETCH_USERS_SUCCESS, usersArray);


export const fetchUsersFailed = (error) =>
    createAction(USER_ACTION_TYPES.FETCH_USERS_FAILED, error);

export const fetchUsersAsync = () => async (dispatch) => {
    dispatch(fetchUsersStart());

    try {
        const usersArray = await getAllUsersNoPaginate();
        console.log("usersArray",usersArray);
        dispatch(fetchUsersSuccess(usersArray));
    } catch(error) {
        dispatch(fetchUsersFailed(error))
    }
}