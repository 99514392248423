import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Spinner } from "reactstrap";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import FormInput from "../../form-input/form-input.component";
import { setCurrentAdmin } from "../../../store/admin/admin.action";
import { signInAuthUserWithEmailAndPassword, signOutUser } from "../../../services/auth-firebase.services";

const defaultFormFields = {
  email: "",
  password: "",
};

const SignInForm = () => {
  const [formFields, setFormFields] = useState(defaultFormFields);
  const [savingForm, setSavingForm] = useState(false);

  const dispatch = useDispatch();

  const { email, password } = formFields;
  const navigate = useNavigate();

  const resetFormFields = () => {
    setFormFields(defaultFormFields);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setSavingForm(true);
    try {
      const res = await signInAuthUserWithEmailAndPassword(email, password);

      if (res.Status == 1) {
        dispatch(setCurrentAdmin(res));
        navigate("/dashboard");
        console.log("REsult", res);
      } else {
        dispatch(setCurrentAdmin({}));
        signOutUser();
        toast.error("user is not active");
        console.log("REsult", res);
      }
      resetFormFields();
      setSavingForm(false);
    } catch (err) {
      if (err.code === "auth/wrong-password") {
        toast.error("password is incorrect");
      } else if (err.code === "auth/user-not-found") {
        toast.error("user not found");
      } else {
        toast.error("User sign in encountered an error");
      }
      console.log("REsult", err);
      setSavingForm(false);
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormFields({
      ...formFields,
      [name]: value,
    });
  };

  return (
    
    <form className="theme-form" onSubmit={handleSubmit}>
      <h4>Sign in Bakhtar Dashboard</h4>
      <p>Enter your email & password to login</p>
      <div className="form-group">
        <label className="col-form-label">Email Address</label>
        <FormInput
          type="email"
          id="email"
          onChange={handleChange}
          name="email"
          placeholder="Test@gmail.com"
          value={email}
          required
        />
      </div>
      <div className="form-group">
        <label className="col-form-label">Password</label>
        <FormInput
          placeholder="*********"
          type="password"
          id="password"
          onChange={handleChange}
          name="password"
          value={password}
          required
        />
      </div>
      <div className="form-group mb-0">
        <div className="checkbox p-0"></div>
        <Link className="link" to="/authentication/forgotPassword">
          Forgot password?
        </Link>
        <div className="text-end mt-3">
          <button
            className="btn btn-primary btn-block w-100"
            type="submit"
          >
            {savingForm ? <Spinner /> : "Sign in"}
          </button>
        </div>
        <div className="text-end mt-3">
          <Link className="link" to={'/authentication/signup'}>Don't have an account ?</Link>
        </div>
      </div>
    </form>
              
  );
};

export default SignInForm;
