import { createCollectionAlbum, deleteCollectionAlbumService, getCollectionAlbums, updateCollectionAlbumService } from "../../services/albums-firebase.services";
import { createAction } from "../../utils/reducer.utils";
import { ALBUM_ACTION_TYPES } from "./album.types";


export const fetchCollectionAlbumsStart = () =>
    createAction(ALBUM_ACTION_TYPES.FETCH_ALBUMS_START);


export const fetchCollectionAlbumsSuccess = (albumsArray) => 
    createAction(ALBUM_ACTION_TYPES.FETCH_ALBUMS_SUCCESS, albumsArray);



export const fetchCollectionAlbumsFailed = (error) =>
    createAction(ALBUM_ACTION_TYPES.FETCH_ALBUMS_FAILED, error);

export const fetchCollectionAlbumsAsync = (collectionId, allCollectionAlbums) => async (dispatch) => {
    dispatch(fetchCollectionAlbumsStart());

    try {
        const albumsArray = await getCollectionAlbums(collectionId);
        const fetchedAlbums =  formatFetchedAlbums(albumsArray, collectionId);
        const allAlbums =  allCollectionAlbums ? allCollectionAlbums : formatFetchedAlbums(allCollectionAlbums, collectionId);

        const desObj = { ...fetchedAlbums, ...allAlbums};
        
        dispatch(fetchCollectionAlbumsSuccess(desObj));
    } catch(error) {
        dispatch(fetchCollectionAlbumsFailed(error))
    }
}


export const formatFetchedAlbums = (collectionAlbums, collectionId) => {
    return (collectionAlbums.length > 0) ? collectionAlbums.reduce((acc, album) => {
        acc[album.CollectionID] = acc[album.CollectionID] ?? [];
        acc[album.CollectionID].push(album);
        return acc;
      }, {}) : { [collectionId]: [] }
}

export const addCollectionAlbum =  async (album, albumsArray) => {
    try {
        const res = await createCollectionAlbum(album);
        createOrUpdateCollectionAlbum(res, albumsArray)
    } catch(error) {
        console.log(error);
    }
}

const createOrUpdateCollectionAlbum = async (album, albumsArray) => {
    const checkCollectionAlbumExists = (albumsArray && albumsArray.length) > 0 ? albumsArray.find(alb => alb.id === album.id) : false;
    if(checkCollectionAlbumExists) {
        const index = albumsArray.findIndex(alb => alb.id === album.id)
        albumsArray[index] = album;
    } else  {
        return albumsArray.unshift(album);
    }
}

// Update album 
export const updateCollectionAlbum =  async (album, albumsArray, albumId) => {
    try {
        const res = await updateCollectionAlbumService(album, albumId);
        createOrUpdateCollectionAlbum(res, albumsArray);
    } catch(error) {
        console.log(error);
    }
}

// Delete album
export const deleteCollectionAlbum = async (albumId) => {
    try {
        await deleteCollectionAlbumService(albumId)
    } catch(error) {
        console.log(error);
    }
}