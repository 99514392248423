import { Spinner } from 'reactstrap';
import './admin-list.styles.scss';
import { Plus } from 'react-feather';
import AdminCard from '../admin-card/admin-card.component';
import { useDispatch, useSelector } from 'react-redux';
import { selectAdmins, selectCurrentAdmin, selectLastAdminDoc } from '../../../store/admin/admin.selector';
import { useEffect, useState } from 'react';
import { getTotalAdmin } from '../../../services/auth-firebase.services';
import { fetchAdminsAsync, loadMoreAdminsDB, searchAdminsAsync } from '../../../store/admin/admin.action';
import InfiniteScroll from 'react-infinite-scroll-component';
import CustomSearchBar from '../../custom-search-bar/custom-search-bar.component';

const AdminList = ({ toggle, setAdminFormData, isLoading }) => {
  const [ totalAdmin, setTotalAdmin] = useState(0);

  const currentAdmin = useSelector(selectCurrentAdmin);
  const allAdmins = useSelector(selectAdmins);
  const lastAdminDoc = useSelector(selectLastAdminDoc);
  const dispatch = useDispatch();
  const [query, setQuery] = useState("")

  useEffect(() => {
    const getTotalAdminDB = async () => {
      const total = await getTotalAdmin();

      setTotalAdmin(total);
    }

    getTotalAdminDB();
  }, [])

  const loadMoreAdmin = () => {
    dispatch(loadMoreAdminsDB(lastAdminDoc, allAdmins))
  }

  const searchAdmin = () => {
    if (query != '' ) {
      dispatch(searchAdminsAsync(query))
    } else {
      dispatch(fetchAdminsAsync())
    }
  }

  return (
    <>
      <div className="package-list-view">
      <CustomSearchBar placeholder="Search your admin name here..." setQuery={setQuery} keyUpFunc={searchAdmin} />
        <div className="row mt-2">
          <div className="col-md-3">
            <span className="product-list-table-head-text">Name</span>
          </div>
          
          <div className="col-md-3">
            <span className="product-list-table-head-text">Email</span>
          </div>
          <div className={` ${(currentAdmin.Role !== 'Admin') ? 'col-md-3': 'col-md-2'}`}>
            <span className="product-list-table-head-text">Role</span>
          </div>
          <div className={` ${(currentAdmin.Role !== 'Admin') ? 'col-md-3': 'col-md-2'}`}>
            <span className="product-list-table-head-text">Status</span>
          </div>
          { (currentAdmin.Role === 'Admin') && <div className="col-md-2"> Action </div>}
        </div>
        {
        isLoading ? (
          <div className="text-center">
            <Spinner />
          </div>
        ) : 

        <InfiniteScroll
          dataLength={totalAdmin}
          next={loadMoreAdmin}
          hasMore={allAdmins.length > 0 &&  totalAdmin > allAdmins.length && query === '' }
          loader={<div className='primary-color text-center'> loading... </div>}
          scrollableTarget="scrollableDiv"
          endMessage={
            <p className='text-center primary-color'>
              <b>Yay! You have seen it all</b>
            </p>
          }
        >
          { allAdmins && allAdmins.length > 0 ? (
            allAdmins?.map((adminObj, index) => (
              <>
                <AdminCard
                key={adminObj.id + index}
                adminObj={adminObj}
                setAdminFormData={setAdminFormData}
                toggle={toggle}
              />
              </>
            ))
          ) : ''}
        </InfiniteScroll>
      
        }
        
      </div>
    </>
  );
};

export default AdminList;
