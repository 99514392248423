import { useState, useEffect } from "react";
import { Form, Button, Spinner } from "reactstrap";
import FormInput from "../../form-input/form-input.component";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import SweetAlert from "sweetalert2";
import "./admin-form.styles.scss";
import { addAdmin, deleteAdmin, updateAdmin } from "../../../store/admin/admin.action";
import { selectAdmins } from "../../../store/admin/admin.selector";

const AdminForm = ({ toggle, adminObj = {} }) => {
  const [values, setValues] = useState({
    Name: "",
    Email: "",
    Role: "",
    Status: 1,
    Created_at: new Date(),
    Updated_at: new Date(),
  });

  const [isSaving, setIsSaving] = useState(false);
  const [adminId, setAdminId] = useState(null);
  const allAdmin = useSelector(selectAdmins);

  const [focused, setFocused] = useState(false);

  useEffect(() => {
    if (Object.keys(adminObj).length > 0) {
      const { Updated_at, id, ...rest } = adminObj;
      setValues({ ...values, ...rest });
      setAdminId(id);
    }
  }, [adminObj]);

  const resetFormFields = () => {
    setValues({
      Role: "",
      Status: 1,
      Created_at: new Date(),
      Updated_at: new Date(),
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSaving(true);

    try {
      if (adminId) {
        await updateAdmin({ ...values, id: adminId }, allAdmin, adminId)
        setIsSaving(false);
        toast.success(`Admin role ${values.Role} updated successfully!`);
      } else {
        await addAdmin(values, allAdmin)
        // console.log(user);
  
        setIsSaving(false);
        resetFormFields();
        toast.success(`Admin with role ${values.Role} added successfully!`);
      }
      toggle();
    } catch (err) {
      if (err.code === "auth/email-already-in-use") {
        toast.error("Can not create user, email already in use");
      } else {
        toast.error("User creation encountered an error");
      }

      console.log("Form catched Error", err);

      setIsSaving(false);
    }
  };

  const handleFocus = (e) => {
    e.target.value === '' ? setFocused(true) : setFocused(false);
  };

  const onChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  // Handle Delete Admin
  const handleDeleteAdmin = () => {
    SweetAlert.fire({
      title: "Are you sure?",
      text: "Once deactive an admin, he/she can't loggin to system again.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Ok",
      cancelButtonText: "cancel",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        updateAdmin({ ...adminObj, Status: 0} , allAdmin, adminId).then(
          () => {
            SweetAlert.fire(
              "Deactivated!",
              "Your admin has been deactivated.",
              "success"
            );
            // const index = allAdmin.findIndex((alb) => alb.id === adminId);
            // allAdmin.splice(index, 1);
            toggle();
          },
          (err) => {
            SweetAlert.fire(
              "Admin is not deactivated",
              "Your admin has not been deactivated.",
              "error"
            );
          }
        );
      } else {
        SweetAlert.fire("Your admin is active!");
      }
    });
  };

  return (
    <Form className="form-bookmark needs-validation" onSubmit={handleSubmit}>
      <div className="form-row row mb-3">
        <div className="col-12 mb-3">
          <label htmlFor="Role">Select admin role.</label>
          <select
            className="form-select"
            name="Role"
            value={values["Role"]}
            onChange={(e) => { onChange(e); handleFocus(e); }}
            id="Role"
            required
            onBlur={(e) => handleFocus(e)}
            focused={focused.toString()}
          >
            <option value="">Select Role...</option>
            <option value="Admin">Admin</option>
            <option value="Editor">Editor</option>
            <option value="Viewer">Viewer</option>
          </select>
          <span className="form-error-span">Please select one admin role.</span>
        </div>
        <div className="col-12 mb-3">
          <label htmlFor="Role">Select admin status.</label>
          <select
            className="form-select"
            name="Status"
            value={values["Status"]}
            onChange={(e) => { onChange(e); handleFocus(e); }}
            id="Status"
            required
            onBlur={(e) => handleFocus(e)}
            focused={focused.toString()}
          >
            <option value="">Change Admin Status</option>
            <option value="1">Active</option>
            <option value="0">Deactive</option>
          </select>
          <span className="form-error-span">Please select one admin role.</span>
        </div>
      </div>
      <div className={adminId && !(!adminObj.Status || adminObj.Status != 1) ? "d-flex justify-content-between" : ""}>
        {adminId && (adminObj.Status && adminObj.Status == 1) ? (
          <Button color="secondary" onClick={handleDeleteAdmin}>
            Deactive
          </Button>
        ) : ''}
        <div
          className={
            (!adminObj.Status || adminObj.Status != 1)  ? "d-flex justify-content-between" : "d-flex gap-2"
          }
        >
          <Button color="light" onClick={toggle}>
            Cancel
          </Button>

          <Button
            color="primary"
            className="mr-1"
            disabled={isSaving ? true : false}
          >
            {isSaving ? (
              <Spinner style={{ height: "20px", width: "20px" }} />
            ) : (
              !adminObj.Status || adminObj.Status != 1 ? "Save & Active" : "Save" 
            )}{" "}
          </Button>
        </div>
      </div>
    </Form>
  );
};

export default AdminForm;
