import {
  collection,
  query,
  where,
  orderBy,
  getDocs,
  doc,
  getDoc,
  startAfter,
  limit,
  getCountFromServer,
} from "firebase/firestore";
import { db } from "../config/firebase.config";

export const getAllUsers = async (lastDoc = null, searchString = "") => {
  const ablumProductsRef = collection(db, "Users");

  if (lastDoc == null) {
    // Query the first page of docs
    const q = query(
      ablumProductsRef,
      where("name", ">=", searchString.toLocaleLowerCase()),
      where("name", "<=", searchString.toLocaleLowerCase() + "\uf8ff"),
      orderBy("name"),
      limit(20)
    );
    const querySnapshot = await getDocs(q);

    const users = querySnapshot.docs.map((docSnapshot) => ({
      id: docSnapshot.id,
      ...docSnapshot.data(),
    }));
    const lastDoc = querySnapshot.docs[querySnapshot.docs.length - 1];
    return { users, lastDoc };
  } else {
    // Get the last visible document
    const nextQuery = query(ablumProductsRef, startAfter(lastDoc), limit(8));
    const querySnapshot2 = await getDocs(nextQuery);

    const users = querySnapshot2.docs.map((docSnapshot) => ({
      id: docSnapshot.id,
      ...docSnapshot.data(),
    }));
    const lastDoc1 = querySnapshot2.docs[querySnapshot2.docs.length - 1];
    return { users, lastDoc: lastDoc1 };
  }
};

// get all Users without paginations
export const getAllUsersNoPaginate = async () => {
  const ablumProductsRef = collection(db, "Users");

  const q = query(ablumProductsRef);
  const querySnapshot = await getDocs(q);

  return querySnapshot.docs.map((docSnapshot) => ({
    id: docSnapshot.id,
    ...docSnapshot.data(),
  }));
};

export const getTotalUserCount = async () => {
  const usersRef = collection(db, "Users");
  const snapUser = await getCountFromServer(usersRef);
  return snapUser.data().count;
};

// Get All User Devices
export const getUserDevices = async (userId) => {
  const deviceRef = collection(db, "Devices");
  const q = query(
    deviceRef,
    where("UserID", "==", userId),
    orderBy("Created_at", "desc")
  );
  const querySnapshot = await getDocs(q);
  return querySnapshot.docs.map((docSnapshot) => ({
    id: docSnapshot.id,
    ...docSnapshot.data(),
  }));
};

// Get All User Donations
export const getUserDonations = async (userId) => {
  const donationRef = collection(db, "Donations");
  const q = query(
    donationRef,
    where("UserID", "==", userId),
    orderBy("Created_at", "desc")
  );
  const querySnapshot = await getDocs(q);
  return querySnapshot.docs.map((docSnapshot) => ({
    id: docSnapshot.id,
    ...docSnapshot.data(),
  }));
};

// Get All User Libraries
export const getUserLibraries = async (userId) => {
  const libraryRef = collection(db, "Libraries");
  const q = query(
    libraryRef,
    where("UserID", "==", userId),
    orderBy("Created_at", "desc")
  );
  const querySnapshot = await getDocs(q);
  return await Promise.all(
    querySnapshot.docs.map(async (docSnapshot) => {
      const product = await getProductByID(docSnapshot.data().ProductID);
      return { ...product };
    })
  );
};

// Get Product By ID
const getProductByID = async (id) => {
  const deviceRef = doc(db, "Products", id);
  const snapshot = await getDoc(deviceRef);

  return snapshot.data();
};

// Get All User Subscriptions
export const getUserSubscriptions = async (userId) => {
  const subscriptionRef = collection(db, "Subscriptions");
  const q = query(
    subscriptionRef,
    where("UserID", "==", userId),
    orderBy("Created_at", "desc")
  );
  const querySnapshot = await getDocs(q);
  return await Promise.all(
    querySnapshot.docs.map(async (docSnapshot) => {
      // Package ID
      const packageObj = await getDoc(
        doc(db, "Packages", docSnapshot.data().PackageID)
      );
      const { Payable_Amount, Duration, Title } = packageObj.data();
      return {
        id: docSnapshot.id,
        Title,
        Payable_Amount,
        Duration,
        ...docSnapshot.data(),
      };
    })
  );
};

// Get All User MostViewed
export const getUserMostViewed = async (userId) => {
  const mostViewedRef = collection(db, "MostViewed");
  const q = query(mostViewedRef, where("UserID", "==", userId));
  const querySnapshot = await getDocs(q);
  return await Promise.all(
    querySnapshot.docs.map(async (docSnapshot) => {
      const product = await getProductByID(docSnapshot.data().ProductID);
      return { ...product };
    })
  );
};

// Get All User RecentlyPlayed
export const getUserRecentlyPlayed = async (userId) => {
  const recentlyPlayedRef = collection(db, "MostViewed");
  const q = query(
    recentlyPlayedRef,
    where("UserID", "==", userId),
    orderBy("Updated_at", "desc")
  );
  const querySnapshot = await getDocs(q);
  return await Promise.all(
    querySnapshot.docs.map(async (docSnapshot) => {
      const product = await getProductByID(docSnapshot.data().ProductID);
      return { ...product };
    })
  );
};

// Get All User Playlist
export const getUserPlaylist = async (userId) => {
  const playlistRef = collection(db, "Playlists");
  const q = query(
    playlistRef,
    where("UserId", "==", userId),
    orderBy("Created_at", "desc")
  );
  const querySnapshot = await getDocs(q);
  return await Promise.all(
    querySnapshot.docs.map(async (docSnapshot) => {
      const product = await getProductByID(docSnapshot.data().ProductID);
      return { ...product };
    })
  );
};
