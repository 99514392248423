import { useState } from "react";

// import "./sign-in-form-style.scss";
import { Link, useNavigate } from "react-router-dom";
import { Spinner } from "reactstrap";
import { toast } from "react-toastify";
import FormInput from "../../form-input/form-input.component";
import { passwordReset } from "../../../services/auth-firebase.services";

const defaultFormFields = {
  email: "",
};

const ForgotPasswordForm = () => {
  const [formFields, setFormFields] = useState(defaultFormFields);
  const [savingForm, setSavingForm] = useState(false);

  const { email } = formFields;
  const navigate = useNavigate();

  const resetFormFields = () => {
    setFormFields(defaultFormFields);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setSavingForm(true);
    try {
      const res = await passwordReset(email);

      navigate("/authentication/login");
      toast.success("Password reset link sent to your email address");


      resetFormFields();
      setSavingForm(false);
    } catch (err) {
      if (err.code === "auth/user-not-found") {
        toast.error("user not found");
      } else {
        toast.error("User reset password encountered an error");
      }
      console.log("REsult", err);
      setSavingForm(false);
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormFields({
      ...formFields,
      [name]: value,
    });
  };

  return (
    <form className="theme-form" onSubmit={handleSubmit}>
      <h4>Bakhtar Dashboard</h4>
      <p>Enter your email for reset password.</p>
      <div className="form-group">
        <label className="col-form-label">Email Address</label>
        <FormInput
          type="email"
          id="email"
          onChange={handleChange}
          name="email"
          placeholder="Test@gmail.com"
          value={email}
          required
        />
      </div>
      <div className="form-group mb-0">
        <div className="text-end mt-3">
          <button className="btn btn-primary btn-block w-100" type="submit">
            {savingForm ? <Spinner /> : "Sign in"}
          </button>
        </div>
        <div className="text-end mt-3">
          <Link className="link" to={"/authentication/login"}>
            Login
          </Link>
        </div>
      </div>
    </form>
  );
};

export default ForgotPasswordForm;
