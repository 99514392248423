import { useState, useEffect } from "react";
import { Form, Button, Spinner } from "reactstrap";
import FormInput from "../../form-input/form-input.component";
import {
  addCollection,
  deleteCollection,
  updateCollection,
} from "../../../store/collections/collection.action";
import { selectCollections } from "../../../store/collections/collection.selector";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import SweetAlert from "sweetalert2";
import { selectCurrentAdmin } from "../../../store/admin/admin.selector";

const CollectionForm = ({ toggle, collectionObj }) => {
  const [values, setValues] = useState({
    Title: "",
    Description: "",
    Photo: "",
    Status: 1,
    Created_at: new Date(),
    Updated_at: new Date(),
  });
  const [isUploading, setIsUploading] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [photoUrl, setPhotoUrl] = useState(null);
  const [collectionId, setCollectionId] = useState(null);
  const allCollections = useSelector(selectCollections);
  const currentAdmin = useSelector(selectCurrentAdmin);

  useEffect(() => {
    if (Object.keys(collectionObj).length > 0) {
      const { Updated_at, id, ...rest } = collectionObj;
      setValues({ ...values, ...rest });
      setCollectionId(id);
      setPhotoUrl(collectionObj.Photo ? collectionObj.Photo : null);
    }
  }, [collectionObj]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (isUploading) return;
    setIsSaving(true);
    values.Photo = photoUrl ? photoUrl : null;

    collectionId
      ? updateCollection({ ...values, id:collectionId }, allCollections, collectionId).then(() => {
          toast.success("Your collection has been saved.");
          setIsSaving(false);
          toggle();
        })
      : addCollection(values, allCollections).then(() => {
          toast.success("Your collection has been saved.");
          setIsSaving(false);
          toggle();
        });
  };

  const onChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  // Handle Delete Collection
  const handleDeleteCollection = () => {
    SweetAlert.fire({
      title: "Are you sure?",
      text: "Once deleted, it will be hard to recover it again.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Ok",
      cancelButtonText: "cancel",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        deleteCollection(collectionId);
        SweetAlert.fire("Deleted!", "Your collection has been deleted.", "success");
        const index = allCollections.findIndex(alb => alb.id === collectionId);
        allCollections.splice(index, 1);
        toggle();
      } else {
        SweetAlert.fire("Your collection is safe!");
      }
      
    });
  };

  return (
    <Form className="form-bookmark needs-validation" onSubmit={handleSubmit}>
      <div className="form-row row mb-3">
        <div className="col-8">
          <FormInput
            name="Title"
            type="text"
            placeholder="Collection Title"
            errorMessage="Collection Title should be more than 3 characters!"
            label="Title"
            min={3}
            required
            value={values["Title"]}
            onChange={onChange}
          />

          <FormInput
            name="Description"
            type="textarea"
            placeholder="Description"
            label="Description"
            value={values["Description"]}
            onChange={onChange}
          />
        </div>
        <div className="col-4">
          <FormInput
            name="photo"
            type="file"
            placeholder="Upload a photo"
            label="Collection photo"
            uploadDirectory="collections"
            value={values["photo"]}
            setValues={setValues}
            values={values}
            setIsUploading={setIsUploading}
            setPhotoUrl={setPhotoUrl}
            photoUrl={photoUrl}
            onChange={onChange}
          />
        </div>
      </div>
      <div className={collectionId && currentAdmin.Role === 'Admin' ? "d-flex justify-content-between" : ""}>
        {(collectionId && currentAdmin.Role === 'Admin') && (
          <Button color="secondary" onClick={handleDeleteCollection}>
            Delete
          </Button>
        )}
        <div
          className={
            (!collectionId ||  currentAdmin.Role !== 'Admin') ? "d-flex justify-content-between" : "d-flex gap-2"
          }
        >
          <Button color="light" onClick={toggle}>
            Cancel
          </Button>

          <Button
            color="primary"
            className="mr-1"
            disabled={isSaving ? true : false}
          >
            {isSaving ? (
              <Spinner style={{ height: "20px", width: "20px" }} />
            ) : (
              "Save"
            )}{" "}
          </Button>
        </div>
      </div>
    </Form>
  );
};

export default CollectionForm;
