import {
  getAuth,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
  onAuthStateChanged,
  sendPasswordResetEmail,
  confirmPasswordReset,
} from "firebase/auth";

import {
  doc,
  setDoc,
  getDoc,
  getDocs,
  updateDoc,
  where,
  collection,
  query,
  orderBy,
  limit,
  startAfter,
  getCountFromServer,
  startAt,
} from "firebase/firestore";

import { db } from "../config/firebase.config";

export const auth = getAuth();
export const createUserDocumentFromAuth = async (
  userAuth,
  additionalInformation = {}
) => {
  const userDocRef = doc(db, "Admin", userAuth.uid);

  const userSnapshot = await getDoc(userDocRef);
  // if user data dose not exist
  if (!userSnapshot.exists()) {
    const { email } = userAuth;
    try {
      await setDoc(userDocRef, {
        Email: email,
        Name: additionalInformation.name.toLowerCase(),
        Created_at: new Date(),
        Status: 0
      });
    } catch (err) {
      console.log(err);
    }
  }

  // return userDocRef
  return userDocRef.id;
};

// Create user with email and password
export const createAuthUserWithEmailAndPassword = async (email, password) => {
  if (!email || !password) return;
  return await createUserWithEmailAndPassword(auth, email, password);
};

export const signInAuthUserWithEmailAndPassword = async (email, password) => {
  if (!email || !password) return;
  const res = await signInWithEmailAndPassword(auth, email, password);

  const userDocRef = doc(db, "Admin", res.user.uid);

  const userSnapshot = await getDoc(userDocRef);

  return { ...res.user, ...userSnapshot.data() };
};

export const getAdminDocById = async (adminId) => {
  const userDocRef = doc(db, "Admin", adminId);

  const userSnapshot = await getDoc(userDocRef);

  return userSnapshot.data();
};

export const signOutUser = async () => {
  const res = await signOut(auth);
};

export const onAuthStateChangedListener = (callback) => {
  onAuthStateChanged(auth, callback);
};

export const updateAdminService = async (docData, adminId) => {
  const updatedDoc = {
    Role: docData.Role,
    Status: docData.Status,
    Updated_at: new Date(),
  };
  await updateDoc(doc(db, "Admin", adminId), updatedDoc);

  return await {
    ...docData,
    id: adminId,
  };
};

export const deleteAdminService = async (adminId) => {
  await updateDoc(doc(db, "Admin", adminId), {
    Status: 0,
    Updated_at: new Date(),
  });
};
export const getAllAdmins = async (lastDoc = null, searchString="" ) => {
    const adminRef = collection(db, "Admin");
    
    if(lastDoc == null) {
        // Query the first page of docs
          let q ; 
          if ( searchString != "" ) {
            q = query(adminRef, 
              where('Name', '>=', searchString.toLocaleLowerCase()),
              where('Name', '<=', searchString.toLocaleLowerCase() + '\uf8ff'),
              orderBy("Name"),
              orderBy("Created_at", "desc")
              ,limit(10));
          } else {
            q = query(adminRef, 
              orderBy("Created_at", "desc")
              ,limit(10));
          }
          
          const querySnapshot = await getDocs(q);

          const admins = querySnapshot.docs.map((docSnapshot) => (
            {
                id: docSnapshot.id,
            ...docSnapshot.data()
            }

            ))
            const lastDoc = querySnapshot.docs[querySnapshot.docs.length - 1];
            return { admins, lastDoc }

    } else {
        // Get the last visible document
        const nextQuery = query(adminRef, 
          orderBy("Created_at", "desc"),
          limit(10),
          startAfter(lastDoc),
        );
        const querySnapshot2 = await getDocs(nextQuery);
        const admins = querySnapshot2.docs.map((docSnapshot) => (
        {
            id: docSnapshot.id,
        ...docSnapshot.data()
        }

        ))
        const lastDoc1 = querySnapshot2.docs[querySnapshot2.docs.length-1];
        return { admins, lastDoc: lastDoc1 }
    }


};

export const getTotalAdmin = async () => {
    const adminsRef =  query(collection(db, 'Admin'), orderBy("Created_at", "desc"));
    const snapAdmin = await getCountFromServer(adminsRef);
    return snapAdmin.data().count;
}


// Reset Password and Forgot Password
export const passwordReset = async (email) => {
  return await sendPasswordResetEmail(auth, email);
};

export const confirmThePasswordReset = async (oobCode, newPassword) => {
  if (!oobCode && !newPassword) return;

  return await confirmPasswordReset(auth, oobCode, newPassword);
};
