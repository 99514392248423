// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
    apiKey: "AIzaSyAKLlPyyfp0ODr_gSD56zJZaFcTENYFGhE",
    authDomain: "bakhtar-cadb0.firebaseapp.com",
    projectId: "bakhtar-cadb0",
    storageBucket: "bakhtar-cadb0.appspot.com",
    messagingSenderId: "106104167412",
    appId: "1:106104167412:web:26e19fc96630b80eb177a1",
    measurementId: "G-PE37GTGX2W"
};

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);
export const db =  getFirestore();
export const storage = getStorage();