import { useState, useEffect } from "react";
import { Form, Button, Spinner } from "reactstrap";
import FormInput from "../../form-input/form-input.component";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import SweetAlert from "sweetalert2";
import { selectPackages } from "../../../store/packages/package.selector";
import { addPackage, deletePackage, updatePackage } from "../../../store/packages/package.action";
import { selectCurrentAdmin } from "../../../store/admin/admin.selector";

const PackageForm = ({ toggle, packageObj }) => {
  const [values, setValues] = useState({
    Title: "",
    Description: "",
    Status: 1,
    Created_at: new Date(),
    Updated_at: new Date(),
    Duration: "",
	  Payable_Amount: "",
  });

  const [isSaving, setIsSaving] = useState(false);
  const [packageId, setPackageId] = useState(null);
  const allPackages = useSelector(selectPackages);
  const currentAdmin = useSelector(selectCurrentAdmin);

  useEffect(() => {
    if (Object.keys(packageObj).length > 0) {
      const { Updated_at, id, ...rest } = packageObj;
      setValues({ ...values, ...rest });
      setPackageId(id);
    }
  }, [packageObj]);

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsSaving(true);

    packageId
      ? updatePackage({ ...values, id:packageId }, allPackages, packageId).then(() => {
          toast.success("Your package has been saved.");
          setIsSaving(false);
          toggle();
        })
      : addPackage(values, allPackages).then(() => {
          toast.success("Your package has been saved.");
          setIsSaving(false);
          toggle();
        });
  };

  const onChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };


  // Handle Delete Package
  const handleDeletePackage = () => {
    SweetAlert.fire({
      title: "Are you sure?",
      text: "Once deleted, it will be hard to recover it again.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Ok",
      cancelButtonText: "cancel",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        deletePackage(packageId).then(() => {
          SweetAlert.fire("Deleted!", "Your package has been deleted.", "success");
          const index = allPackages.findIndex(alb => alb.id === packageId);
          allPackages.splice(index, 1);
          toggle();
        }, (err) => {
          SweetAlert.fire("Not deleted", "Your package has not been deleted.", "error"); 
        })
        
        
      } else {
        SweetAlert.fire("Your package is safe!");
      }
      
    });
  };

  return (
    <Form className="form-bookmark needs-validation" onSubmit={handleSubmit}>
      <div className="form-row row mb-3">
        <div className="col-6 mb-3">
          <FormInput
            name="Title"
            type="text"
            placeholder="Package Title"
            errorMessage="Package Title should be more than 3 characters!"
            label="Title"
            min={3}
            required
            value={values["Title"]}
            onChange={onChange}
          />
        </div>
        <div className="col-6 mb-3">
          <FormInput
            name="Duration"
            type="text"
            placeholder="Package Duration"
            errorMessage="Package Duration is required!"
            label="Duration"
            required
            value={values["Duration"]}
            onChange={onChange}
          />
        </div>
        <div className="col-6 mb-3">
          <FormInput
            name="Description"
            type="textarea"
            placeholder="Description"
            label="Description"
            value={values["Description"]}
            onChange={onChange}
          />
        </div>
        
        <div className="col-6 mb-3">
          <FormInput
            name="Payable_Amount"
            type="text"
            placeholder="Package Payable Amount"
            errorMessage="Package Payable Amount is required!"
            label="Payable Amount"
            required
            value={values["Payable_Amount"]}
            onChange={onChange}
          />
        </div>
      </div>
      <div className={packageId && currentAdmin.Role === 'Admin'? "d-flex justify-content-between" : ""}>
        {packageId && currentAdmin.Role === 'Admin' && (
          <Button color="secondary" onClick={handleDeletePackage}>
            Delete
          </Button>
        )}
        <div
          className={
            (!packageId ||  currentAdmin.Role !== 'Admin') ? "d-flex justify-content-between" : "d-flex gap-2"
          }
        >
          <Button color="light" onClick={toggle}>
            Cancel
          </Button>

          <Button
            color="primary"
            className="mr-1"
            disabled={isSaving ? true : false}
          >
            {isSaving ? (
              <Spinner style={{ height: "20px", width: "20px" }} />
            ) : (
              "Save"
            )}{" "}
          </Button>
        </div>
      </div>
    </Form>
  );
};

export default PackageForm;
