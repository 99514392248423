import { Home, GitPullRequest, Users, Tag, Package, UserCheck, Award, Grid } from 'react-feather'
export const MENUITEMS = [
    {
        Items: [
            { path: `dashboard`, icon: Home, title: 'Dashboard', type: 'link', active: false },

        ]
    },

    {
        Items: [
            { path: `collections`, icon: GitPullRequest, title: 'Collections', type: 'link', active: false },

        ]
    },

    {
        Items: [
            { path: `packages`, icon: Package, title: 'Packages', type: 'link', active: false },

        ]
    },
    {
        Items: [
            { path: `concerts`, icon: Package, title: 'Concerts', type: 'link', active: false },

        ]
    },

    {
        Items: [
            { path: `table-grades`, icon: Grid, title: 'Table Grade', type: 'link', active: false },

        ]
    },
    {
        Items: [
            { path: `tags`, icon: Tag, title: 'Tags', type: 'link', active: false },

        ]
    },
    {
        Items: [
            { path: `donation-purposes`, icon: Award, title: 'Donation Purposes', type: 'link', active: false },

        ]
    },
    {
        Items: [
            { path: `users`, icon: Users, title: 'Users', type: 'link', active: false },

        ]
    },

    {
        Items: [
            { path: `admins`, icon: UserCheck, title: 'Admins', type: 'link', active: false },

        ]
    },




]