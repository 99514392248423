import { TAG_ACTION_TYPES } from "./tag.types";

const TAG_INITIAL_STATE = {
  tags: []
};

export const tagReducer = (state = TAG_INITIAL_STATE, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case TAG_ACTION_TYPES.FETCH_TAGS_START:
      return { ...state, isLoading: true}
    case TAG_ACTION_TYPES.FETCH_TAGS_SUCCESS:
        return { ...state, tags: payload, isLoading: false }
    case TAG_ACTION_TYPES.FETCH_TAGS_FAILED:
        return { ...state, error: payload, isLoading: false }
    default:
        return state;
  }
};