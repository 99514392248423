import { useEffect, useState } from 'react';
import { MapContainer, TileLayer, Popup, Marker } from 'react-leaflet';
import * as L from "leaflet";
import markerIcon from '../../../assets/images/icons/marker-icon.png';
import './user-devices.styles.scss';
import icon from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';


let DefaultIcon = L.icon({
    iconUrl: icon,
    shadowUrl: iconShadow
});

L.Marker.prototype.options.icon = DefaultIcon;

const myIcon = {
 iconUrl: markerIcon,
 iconSize: [32,32]
}
const UserDevices = ({userId, user}) => {

    const [userDevices, setUserDevices] = useState([]);

    useEffect(() => {
        setUserDevices(user.fcmTokens); 
    }, []);

    return( 
        <>
            <div>
                { userDevices.length > 0 && userDevices[0] && userDevices[0].lat && <MapContainer center={[userDevices[0].lat, userDevices[0].lon]} zoom={10} scrollWheelZoom={false}>
                <link rel="stylesheet" crossOrigin="anonymous" href="https://unpkg.com/leaflet@1.0.1/dist/leaflet.css" />
                    <TileLayer
                    
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    crossOrigin="anonymous"
                    />
                    {
                        userDevices.length > 0 && userDevices[0] && userDevices[0].lat && userDevices.map((device, index) => {
                            if (device.lat && device.lon && device.lat != null && device.lon != null) {
                                return (

                                    <Marker position={[device.lat, device.lon]} crossOrigin="anonymous" key={index}>
                                    <Popup>
                                        {device.deviceName} Location.
                                    </Popup>
                                    </Marker>
                                );
                            }
                        }) 
                    }
                </MapContainer> }
            </div>
            <table className="table align-baseline mt-3 text-center">
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Device Model</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        userDevices.length > 0  && userDevices[0] && userDevices[0].lat ? userDevices.map((device, index) => (
                            <tr key={index}>
                                <td>{index + 1}</td>
                                <td>{device.deviceName}</td>
                            </tr>
                        )) : <tr className="text-center mt-2">
                                <td colSpan={2}>
                                <p className="primary">Doesn't have Devices!</p>
                                </td>
                            </tr>
                    }
                    
                </tbody>
            </table>
        </>
    )
}

export default UserDevices;