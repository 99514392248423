import { createConcertReservation, deleteConcertReservationService, getConcertReservations, updateConcertReservationService } from "../../services/reservations-firebase.services";
import { createAction } from "../../utils/reducer.utils";
import { RESERVE_CONCERT_ACTION_TYPES } from "./reserve-concert.types";



export const fetchConcertReservationsStart = () =>
    createAction(RESERVE_CONCERT_ACTION_TYPES.FETCH_RESERVE_CONCERT_START);


export const fetchConcertReservationsSuccess = (reservationsArray) => 
    createAction(RESERVE_CONCERT_ACTION_TYPES.FETCH_RESERVE_CONCERT_SUCCESS, reservationsArray);



export const fetchConcertReservationsFailed = (error) =>
    createAction(RESERVE_CONCERT_ACTION_TYPES.FETCH_RESERVE_CONCERT_FAILED, error);

export const fetchConcertReservationsAsync = (concertId, allConcertReservations, tableGrade) => async (dispatch) => {
    dispatch(fetchConcertReservationsStart());

    try {
        const reservationsArray = await getConcertReservations(concertId, tableGrade);
        console.log("Table Grade ANd Details", reservationsArray)
        const fetchedReservations =  formatFetchedReservations(reservationsArray, concertId+tableGrade);
        console.log("fetchedReservations", fetchedReservations)
        const allReservations =  allConcertReservations ? allConcertReservations : fetchedReservations;

        const desObj = { ...allReservations, ...fetchedReservations };
        console.log("Dest Object", desObj)
        dispatch(fetchConcertReservationsSuccess(desObj));
    } catch(error) {
        console.log("Error", error)
        dispatch(fetchConcertReservationsFailed(error))
    }
}


export const formatFetchedReservations = (concertReservations, concertId) => {
    return (concertReservations.length > 0) ? concertReservations.reduce((acc, reservation) => {
        acc[reservation.Concert_ID + reservation.Grade_ID] = acc[reservation.Concert_ID + reservation.Grade_ID] ?? [];
        acc[reservation.Concert_ID + reservation.Grade_ID].push(reservation);
        return acc;
      }, {}) : { [concertId]: [] }
}

export const addConcertReservation =  async (reservation, reservationsArray) => {
    try {
        const res = await createConcertReservation(reservation);
        createOrUpdateConcertReservation(res, reservationsArray)

        return res
    } catch(error) {
        console.log(error);
        return error
    }
}

const createOrUpdateConcertReservation = async (reservation, reservationsArray) => {
    const checkConcertReservationExists = (reservationsArray && reservationsArray.length) > 0 ? reservationsArray.find(alb => alb.id === reservation.id) : false;
    if(checkConcertReservationExists) {
        const index = reservationsArray.findIndex(reserved => reserved.id === reservation.id)
        reservationsArray[index] = reservation;
    } else  {
        return reservationsArray.unshift(reservation);
    }
}

// Update reservation 
export const updateConcertReservation =  async (reservation, reservationsArray, reservationId) => {
    try {
        const res = await updateConcertReservationService(reservation, reservationId);
        createOrUpdateConcertReservation(res, reservationsArray);
    } catch(error) {
        console.log(error);
    }
}

// // Delete reservation
export const deleteConcertReservation = async (reservationId, reservationObj) => {
    try {
        await deleteConcertReservationService(reservationId, reservationObj)

    } catch(error) {
        console.log(error);
    }
}