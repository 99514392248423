import { getUserDonations } from "../../../services/users-firebase.services";
import CustomDataLoader from "../../custom-data-loader/custom-data-loader.component";
import { useEffect, useState } from 'react';

const UserDonations = ({userId}) => {

    const [isDataLoading, setIsDataLoading] = useState(true);
    const [userDonations, setUserDonations] = useState([]);
    let totalDonation = 0;

    useEffect(() => {
        console.log("DATA", userId);
        const getUserDonation = async (userId) => {
            await getUserDonations(userId).then((data)=> {
                setUserDonations(data);
                setIsDataLoading(false)
            }, (err) => {
                console.log("ERROR", err);
                setIsDataLoading(false)
            })
        }

        getUserDonation(userId)
    }, []);
    return(
        isDataLoading ? <CustomDataLoader /> : <table className="table align-baseline">
            <thead>
                <tr>
                    <th>#</th>
                    <th>Note</th>
                    <th>Donation Amount</th>
                </tr>
            </thead>
            <tbody>
                {
                    userDonations.length > 0 ? (
                        userDonations.map((donation, index) => {
                            totalDonation += donation.Donation_Amount;
                            return (
                                <tr key={index}>
                                    <td>{index + 1}</td>
                                    <td>{donation.Note}</td>
                                    <td>{donation.Donation_Amount}</td>
                                </tr>
                            )
                        }) 
                        
                    ): <tr className="text-center mt-2">
                            <td colSpan={3}>
                            <p className="primary-color">Doesn't have donations yet!!</p>
                            </td>
                        </tr>
                }

                {
                   userDonations.length > 0 && (<tr >
                        <td colSpan={2}>
                        <p className="primary-color"><strong>Total Donations</strong></p>
                        </td>
                        <td>
                            <p className="primary-color">{totalDonation}</p>
                        </td>
                    </tr>
                    )
                }
                
            </tbody>
        </table>
    )
}

export default UserDonations;