import { createSelector } from "reselect";

const selectSeatReducer = (state) => {
    return state.seats;
};

export const selectSeatsMap = createSelector(
    [selectSeatReducer],
    (seatSlice) => seatSlice.seats
)

export const selectSeatIsLoading = createSelector(
    [selectSeatReducer],
    (seastsSlice) => seastsSlice.isLoading
)