import {
    collection,
    query,
    where,
    orderBy,
    getCountFromServer
} from "firebase/firestore";
import { db } from "../config/firebase.config";


export const getTotalCollectionAlbumVideoAudio = async () => {
    const collectionsRef = query(collection(db, 'Collections'), where("Status", "==", 1));
    const snapshotCollection = await getCountFromServer(collectionsRef);

    const albumRef = query(collection(db, "Albums"), where("Status", "==", 1));
    const snapshotAlbum = await getCountFromServer(albumRef);

    const productRef = collection(db, "Products");
    const videoQuery = query(productRef, where("Type", "==", "video"), where("Status", "==", 1));
    const snapshotVideo = await getCountFromServer(videoQuery);

    const audioQuery = query(productRef, where("Type", "==", "audio"), where("Status", "==", 1));
    const snapshotAudio = await getCountFromServer(audioQuery);
    
    const donationRef = collection(db, "Donations");
    const snapshotDonation = await getCountFromServer(donationRef);

    const subscriptionRef = collection(db, "Subscriptions");
    const snapshotSubscription = await getCountFromServer(subscriptionRef);
    
    return {
        totalCollection: snapshotCollection.data().count,
        totalAlbum: snapshotAlbum.data().count,
        totalVideo: snapshotVideo.data().count,
        totalAudio: snapshotAudio.data().count,
        totalDonation: snapshotDonation.data().count,
        totalSubscription: snapshotSubscription.data().count,
    }

}


export const getTotalAndReservedSeatOfTableGrade = async (concertId, tableGradeId) => {
    // const totalTableGradeSeatsRef = query(collection(db, 'Seats'), where("Status", "==", 1), where("Grade_ID", "==", tableGradeId));
    // const snapshotTotalSeat = await getCountFromServer(totalTableGradeSeatsRef);

    const reservedTableGradeSeatsRef = query(collection(db, 'Reserved'), where("Status", "==", 1), where("Grade_ID", "==", tableGradeId) , where("Concert_ID", "==", concertId));
    const snapshotReservedSeat = await getCountFromServer(reservedTableGradeSeatsRef);

    return {
        reservedSeats: snapshotReservedSeat.data().count
    }
}