import "./product-row.styles.scss";
import photoPlaceholder from "../../../assets/images/lightgallry/01.jpg";
import { FIREBASE_STORAGE_BASE_URL } from "../../../constant/constant";
import { formatBytes } from "../../../utils/helper.utils";
import { useSelector } from "react-redux";
import { selectTags } from "../../../store/tags/tag.selector";
import { useState, useEffect } from "react";
import ModalVideo from "react-modal-video";
import { PenTool, Play } from "react-feather";
import AudioPlayer from "react-h5-audio-player";
import "react-h5-audio-player/lib/styles.css";
import CustomModal from "../../custom-modal/custom-modal.component";
import { selectCurrentAdmin } from "../../../store/admin/admin.selector";
import CustomDataLoader from "../../custom-data-loader/custom-data-loader.component";
const ProductRow = (props) => {
  const { product, toggle, setProductFormData } = props;
  const [tagNames, setTagNames] = useState([]);

  const [audiomodal, setAudioModal] = useState(false);
  const toggleAudioModal = () => setAudioModal(!audiomodal);

  const currentAdmin = useSelector(selectCurrentAdmin);

  const handleOpenEditForm = () => {
    if(currentAdmin.Role === 'Editor' || currentAdmin.Role === 'Admin') {
      setProductFormData(product);
      toggle();
    }
  };
  const allTags = useSelector(selectTags);
  const [isOpen, setOpen] = useState(false);

  useEffect(() => {
    if (product.Tags && product.Tags.length > 0) {
      const selectedProductTags = [];
      allTags.map((tag) => {
        if (product.Tags.includes(tag.id)) {
          selectedProductTags.push(tag.Name);
        }
      });
      setTagNames(selectedProductTags);
    }
  }, [product, allTags]);

  return (
    <>
      <div className="product-list-item position-relative row align-items-center main-list-hover">
        <div className="col-md-3" onClick={handleOpenEditForm}>
          <div className="d-flex align-items-center gap-2 position-relative">
            <div className="product-list-img flex-shrink-0">
              <img
                src={
                  product.Thumbnail && product.Thumbnail !== ""
                    ? 
                    (
                      product.Thumbnail.includes(FIREBASE_STORAGE_BASE_URL) ? product.Thumbnail : FIREBASE_STORAGE_BASE_URL + product.Thumbnail
                    ) 
                    : photoPlaceholder
                }
                alt=""
                crossOrigin='anonymous'
              />
            </div>
            <h5 className="product-list-name m-0">{product.Title}</h5>
            <div className="product-list-color"></div>
          </div>
        </div>
        <div className="col-md-3" onClick={handleOpenEditForm}>
          <div className="product-list-address">
            {product.Description ? product.Description : ""}
          </div>
        </div>
        <div className="col-md-2" onClick={handleOpenEditForm}>
          <span className="product-list-table-head-text">
            {tagNames.join(", ")}
          </span>
        </div>
        <div className="col-md-1" onClick={handleOpenEditForm}>
          <span className="product-list-table-head-text">
            {product.Type ? product.Type : ""}
          </span>
        </div>
        <div className="col-md-1" onClick={handleOpenEditForm}>
          <span className="product-list-table-head-text">
            {product.Media_Size && product.Media_Size.length > 0  ? formatBytes(product.Media_Size[0]) : ""}
          </span>
        </div>
        <div className="col-md-2">
          <div className="d-flex gap-2">
            {product.Media_Path ? (
              <>
                {product.Type === "audio" ? (
                  <>
                    <div className="product-list-edit">
                      <button
                        onClick={(e) => {
                          e.stopPropagation();
                          toggleAudioModal();
                        }}
                        title="Play"
                      >
                        
                        <Play />
                      </button>
                    </div>
                    <CustomModal
                      toggle={toggleAudioModal}
                      modal={audiomodal}
                      modalTitle="Audio Modal"
                    >
                      <AudioPlayer
                        className=""
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                        crossOrigin='anonymous'

                        src={
                            product.Media_Path[0].includes(FIREBASE_STORAGE_BASE_URL) ? 
                            product.Media_Path[0] : 
                            FIREBASE_STORAGE_BASE_URL + product.Media_Path[0]
                          }
                      />
                    </CustomModal>
                  </>
                ) : (
                  <>
                    <ModalVideo
                      crossOrigin="anonymous"
                      isOpen={isOpen}
                      onClose={() => setOpen(false)}
                    >
                      <video crossOrigin="anonymous" style={{ width: "100%" }} controls
                        src={product.Media_Path[0].includes(FIREBASE_STORAGE_BASE_URL) ? 
                          product.Media_Path[0] : 
                          FIREBASE_STORAGE_BASE_URL + product.Media_Path[0]}>
                        Browser not supported
                    </video>
                    </ModalVideo>
                    <div className="product-list-edit">
                      <button onClick={() => setOpen(true)} title="Play">
                        
                        <Play />
                      </button>
                    </div>
                  </>
                )}
              </>
            ) : (
              ""
            )}

            <div className="product-list-edit">
              <button title="Edit" onClick={handleOpenEditForm}>
                <PenTool />
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProductRow;
