import { compose, createStore, applyMiddleware } from "redux";

import { rootReducer } from "./root-reducer";
import thunk from "redux-thunk";
import { persistStore, persistReducer } from "redux-persist";
import storage from 'redux-persist/lib/storage'

// chain middleware function
const loggerMiddleware = (store) => (next) => (action) => {
    if (!action.type) {
        return next(action);
    }
    console.log('currentState: ', store.getState());

    next(action);
}

const persistConfig = {
    key: "root",
    storage,
    blacklist: ['admin', 'collections', 'albums', "products", "concerts"]
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

const middleWares = [loggerMiddleware, thunk];

const composedEnhancers = compose(applyMiddleware(...middleWares));

export const store = createStore(rootReducer, undefined, composedEnhancers);

export const persistor = persistStore(store);