import {
    collection,
    addDoc,
    doc,
    query,
    getDocs,
    where,
    updateDoc,
    orderBy
} from "firebase/firestore";
import { db } from "../config/firebase.config";
import { removeUndefinedNull } from "../utils/helper.utils";

export const createTableGrade = async (docData) => {
    docData = removeUndefinedNull(docData);
    const res = await addDoc(collection(db, "Table_Grade"), { 
        ...docData,  
        Price: parseInt(docData.Price),
        Capacity: parseInt(docData.Capacity),
        Table_Number: parseInt(docData.Table_Number),
    });
    if (res.id) {
        return {
            id: res.id,
            ...docData
        }
    }
}

export const updateTableGradeService = async (docData, tableGradeId) => {
    docData = removeUndefinedNull(docData);
    const { id, ...rest } = docData; 
    const updatedDoc = { 
        ...rest,  
        Price: parseInt(docData.Price), 
        Capacity: parseInt(docData.Capacity),
        Table_Number: parseInt(docData.Table_Number),
        Updated_at: new Date() 
    };
    await updateDoc(doc(db, "Table_Grade", tableGradeId), updatedDoc)

    return await {
        ...updatedDoc,
        id: tableGradeId
    }
}

export const deleteTableGradeService = async (tableGradeId) => {
    await updateDoc(doc(db, "Table_Grade", tableGradeId), { Status: 0, Updated_at: new Date() });
    
}
export const getAllTableGrades = async () => {
    const tableGradesRef = collection(db, 'Table_Grade');
    const q = query(tableGradesRef, where("Status", '==', 1), orderBy("Created_at", "desc"));
    const querySnapshot = await getDocs(q);

    return querySnapshot.docs.map((docSnapshot) =>  ({
        id: docSnapshot.id,
        ...docSnapshot.data()
    }))
   
}

