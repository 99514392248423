import {
    collection,
    addDoc,
    doc,
    query,
    getDocs,
    getDoc,
    where,
    updateDoc,
    orderBy
} from "firebase/firestore";
import { db } from "../config/firebase.config";
import { removeUndefinedNull } from "../utils/helper.utils";

import axios from "axios";

import { PAYPAL_API_URL, PAYPAL_CLIENT_ID, PAYPAL_CLIENT_SECRET } from "../constant/constant";

export const authAndGetPackages = async () => {
    const getUrl = PAYPAL_API_URL+'/billing/plans?sort_by=create_time&sort_order=desc'
    const options = {
        url: PAYPAL_API_URL+'/oauth2/token',
        method: "POST",
        auth: {
            username: PAYPAL_CLIENT_ID, 
            password: PAYPAL_CLIENT_SECRET
        },
        headers: { 
            'Content-Type': 'application/x-www-form-urlencoded'
        },
        data: { 'grant_type': 'client_credentials' }
    }

    const { data }  = await axios(options);
    const { data: res } = await axios({
        url: getUrl,
        method: 'Get',
        headers: {
            'Authorization': 'Bearer '+ data.access_token,
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Prefer': 'return=representation'
        }
    });

    return res.plans;
}


// Get Susbcribed users of a plan
export const getSubscribedUsers = async (planId) => {
    const subscriptionsRef = collection(db, 'Subscriptions');
    const q = query(subscriptionsRef, where("planId", '==', planId), orderBy("createdAt", "desc"));
    const querySnapshot = await getDocs(q);
    return await Promise.all(querySnapshot.docs.map(async (docSnapshot) =>  {
        const subscriptions = await getUserByID(docSnapshot.data().userId)
        return {...subscriptions, expiredAt: docSnapshot.data().expiredAt, price: docSnapshot.data().price };
        
    }))
}

// Get User By ID
const getUserByID = async (id) => {
    const deviceRef = doc(db, 'Users', id);
    const snapshot = await getDoc(deviceRef)

    return snapshot.data();
}


export const createPackage = async (docData) => {
    docData = removeUndefinedNull(docData);
    const res = await addDoc(collection(db, "Packages"), docData);
    if (res.id) {
        return {
            id: res.id,
            ...docData
        }
    }
}

export const updatePackageService = async (docData, packageId) => {
    docData = removeUndefinedNull(docData);
    const updatedDoc = { ...docData, Updated_at: new Date() };
    await updateDoc(doc(db, "Packages", packageId), updatedDoc)

    return await {
        ...updatedDoc,
        id: packageId
    }
}

export const deletePackageService = async (packageId) => {
    await updateDoc(doc(db, "Packages", packageId), { Status: 0, Updated_at: new Date() });
    
}
export const getAllPackages = async () => {
    const packagesRef = collection(db, 'Packages');
    const q = query(packagesRef, where("Status", '==', 1), orderBy("Created_at", "desc"));
    const querySnapshot = await getDocs(q);
    return authAndGetPackages();
    // return querySnapshot.docs.map((docSnapshot) =>  ({
    //     id: docSnapshot.id,
    //     ...docSnapshot.data()
    // }))

   
   
}

